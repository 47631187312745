<template>
  <div class="button-bar">
    <el-button-group>
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor && editor.isActive('bold') }"
        ><strong>B</strong></el-button
      >
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().toggleItalic().run()"
        :plain="editor && editor.isActive('italic')"
        :class="{ 'is-active': editor && editor.isActive('italic') }"
        ><em>I</em></el-button
      >
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().toggleStrike().run()"
        :plain="editor && editor.isActive('strike')"
        :class="{ 'is-active': editor && editor.isActive('strike') }"
        ><s>S</s>
      </el-button>
    </el-button-group>
    <el-button-group>
      <el-button
        :color="buttonColor"
        @click="
          editor && editor.chain().focus().toggleHeading({ level: 1 }).run()
        "
        :class="{
          'is-active': editor && editor.isActive('heading', { level: 1 }),
        }"
      >
        H1
      </el-button>
      <el-button
        :color="buttonColor"
        @click="
          editor && editor.chain().focus().toggleHeading({ level: 2 }).run()
        "
        :class="{
          'is-active': editor && editor.isActive('heading', { level: 2 }),
        }"
      >
        H2
      </el-button>
      <el-button
        :color="buttonColor"
        @click="
          editor && editor.chain().focus().toggleHeading({ level: 3 }).run()
        "
        :class="{
          'is-active': editor && editor.isActive('heading', { level: 3 }),
        }"
      >
        H3
      </el-button>
      <el-button
        :color="buttonColor"
        @click="
          editor && editor.chain().focus().toggleHeading({ level: 4 }).run()
        "
        :class="{
          'is-active': editor && editor.isActive('heading', { level: 4 }),
        }"
      >
        H4
      </el-button>
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor && editor.isActive('paragraph') }"
      >
        p
      </el-button>
    </el-button-group>
    <el-button-group>
      <el-button
        :color="buttonColor"
        style="color: black"
        @click="editor && editor.chain().focus().undo().run()"
        :disabled="editor && !editor.can().chain().focus().undo().run()"
        :icon="Back"
      />
      <el-button
        :color="buttonColor"
        style="color: black"
        @click="editor && editor.chain().focus().redo().run()"
        :disabled="editor && !editor.can().chain().focus().redo().run()"
        :icon="Right"
      />
    </el-button-group>
    <el-button-group>
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor && editor.isActive('bulletList') }"
      >
        <BulletIcon />
      </el-button>
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor && editor.isActive('orderedList') }"
      >
        <OrderedIcon :size="24" color="black" />
      </el-button>
    </el-button-group>
    <el-button-group>
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().setTextAlign('left').run()"
        :class="{
          'is-active': editor && editor.isActive({ textAlign: 'left' }),
        }"
        class="align-button"
      >
        <svg width="24" height="24" focusable="false">
          <path
            fill="black"
            d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 1 1 0-2Zm0 4h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 1 1 0-2Zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 0 1 0-2Zm0-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 0 1 0-2Z"
          ></path>
        </svg>
      </el-button>
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().setTextAlign('center').run()"
        :class="{
          'is-active': editor && editor.isActive({ textAlign: 'center' }),
        }"
        class="align-button"
      >
        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <path
            d="M3 5a1 1 0 0 0 0 2h18a1 1 0 1 0 0-2H3ZM6 9a1 1 0 0 0 0 2h12a1 1 0 1 0 0-2H6ZM2 14a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM6 17a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H6Z"
            fill="black"
          />
        </svg>
      </el-button>
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().setTextAlign('right').run()"
        :class="{
          'is-active': editor && editor.isActive({ textAlign: 'right' }),
        }"
        class="align-button"
      >
        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <g id="Page-1" stroke="black" stroke-width="1" fill="none">
            <g id="Align-Right">
              <rect
                id="Rectangle"
                x="0"
                y="0"
                width="24"
                height="24"
                stroke="none"
              />
              <line
                x1="5"
                y1="6"
                x2="19"
                y2="6"
                id="Path"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="5"
                y1="14"
                x2="19"
                y2="14"
                id="Path"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="11"
                y1="10"
                x2="19"
                y2="10"
                id="Path"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="11"
                y1="18"
                x2="19"
                y2="18"
                id="Path"
                stroke-width="2"
                stroke-linecap="round"
              />
            </g>
          </g>
        </svg>
      </el-button>
      <el-button
        :color="buttonColor"
        @click="editor && editor.chain().focus().setTextAlign('justify').run()"
        :class="{
          'is-active': editor && editor.isActive({ textAlign: 'justify' }),
        }"
        class="align-button"
      >
        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <path
            d="M5 6H19M5 10H19M5 14H19M5 18H19"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      </el-button>
    </el-button-group>
    <el-button-group>
      <el-button :color="buttonColor" @click="drawer = true">
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
          <path
            d="M19.9061 21C21.2464 18.2888 22 15.2329 22 12C22 8.76711 21.2464 5.71116 19.9061 3M4.09393 3C2.75363 5.71116 2 8.76711 2 12C2 15.2329 2.75363 18.2888 4.09393 21M16.5486 8.625H16.459C15.8056 8.625 15.1848 8.91202 14.7596 9.41072L9.38471 15.7143C8.95948 16.213 8.33871 16.5 7.6853 16.5H7.59563M8.71483 8.625H10.1089C10.6086 8.625 11.0477 8.95797 11.185 9.44094L12.9594 15.6841C13.0967 16.167 13.5358 16.5 14.0355 16.5H15.4296"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </el-button>
    </el-button-group>
    <!-- <button
        @click="editor.chain().focus().toggleCode().run()"
        :disabled="!editor.can().chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
      >
        code
      </button>
      <button @click="editor.chain().focus().unsetAllMarks().run()">
        clear marks
      </button>
      <button @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>
      <button
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
      >
        code block
      </button>
      <button
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }"
      >
        blockquote
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button>
      <button @click="editor.chain().focus().setHardBreak().run()">
        hard break
      </button> -->
  </div>
  <editor-content :editor="editor" class="editor" />
  <el-drawer v-model="drawer" direction="rtl" size="50%">
    <template #header>
      <h4>Handle variables in text</h4>
    </template>
    <template #default>
      <div class="line">
        <ul>
          <li
            v-for="item in items"
            :key="item.value"
            :class="{ active: selectedText.value === item.value }"
            @click="selectItem(item)"
          >
            {{ item.label }}
          </li>
        </ul>
        <el-drawer
          v-model="innerDrawer"
          title="I'm inner Drawer"
          :append-to-body="true"
        >
          <template #header>
            <h4>Aide sur la gestion des variables</h4>
          </template>
          <template #default>
            La variable de type genre est différente des autres variables, elle
            va permettre de choisir un mot parmi 3 en fonction du genre
            sélectionné par l'utilisateur.
          </template>
        </el-drawer>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button type="info" @click="innerDrawer = true">Help</el-button>
        <el-button @click="drawer = false">Annuler</el-button>
        <el-button type="success" @click="addVariableInText">
          Valider
        </el-button>
      </div>
    </template>
  </el-drawer>
  <el-dialog v-model="genderpopup" title="Tips" width="500">
    <el-form :model="form" label-width="auto" style="max-width: 600px">
      <el-form-item label="Mot masculin">
        <el-input v-model="form.male" />
      </el-form-item>
      <el-form-item label="Mot féminin">
        <el-input v-model="form.female" />
      </el-form-item>
      <el-form-item label="Mot neutre">
        <el-input v-model="form.neutral" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="genderpopup = false">Cancel</el-button>
        <el-button type="primary" @click="insertGenderVar"> Confirm </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script lang="ts" setup>
import { ref, defineExpose, onMounted, reactive } from "vue";
import { Back, Right } from "@element-plus/icons-vue";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import TextAlign from "@tiptap/extension-text-align";
import StarterKit from "@tiptap/starter-kit";
import BulletIcon from "../icons/BulletIcon.vue";
import OrderedIcon from "../icons/OrderedListIcon.vue";
import { HTTP } from "../http-common";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const drawer = ref(false);
const innerDrawer = ref(false);
const genderpopup = ref(false);
const buttonColor = ref("#FFFFFF");

const form = reactive({
  male: "",
  female: "",
  neutral: "",
});

const editor = useEditor({
  content: "",
  extensions: [
    StarterKit,
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
  ],
});

interface textVariable {
  label: string; // Par exemple, l'id peut être une chaîne ou null
  value: string; // Par exemple, l'id peut être une chaîne ou null
}

const items = ref<textVariable[]>([]);
//   { label: "Prénom utilisateur", value: "opt1" },
//   { label: "Nom utilisateur", value: "opt2" },
//   { label: "Age utilisateur", value: "opt3" },
// ];
const selectItem = (item) => {
  console.log("selection", item);
  selectedText.value = item;
};

const selectedText = ref({});
const variablesDialogVisible = ref(false);

function addVariableInText() {
  //editor.value?.commands.focus; //chain().focus().
  drawer.value = false;
  if (selectedText.value.value == "Gender") {
    genderpopup.value = true;
  } else {
    editor.value
      ?.chain()
      .focus()
      .deleteSelection()
      .insertContent("{{" + selectedText.value.value + "}}")
      .run();
  }
}

function insertGenderVar() {
  editor.value
    ?.chain()
    .focus()
    .deleteSelection()
    .insertContent(
      "{{Gender:" + form.male + "|" + form.female + "|" + form.neutral + "}}"
    )
    .run();
  genderpopup.value = false;
}

function getText() {
  return editor.value?.getHTML();
}

function setHTML(initValue: string) {
  editor.value?.view.pasteHTML(initValue);
}

const openVariableDialog = () => {
  variablesDialogVisible.value = true;
};

const updateSelectedText = () => {
  const selection = editor.value?.state.selection;
  if (selection && !selection.empty) {
    const { from, to } = selection;
    const selectedNode = editor.value?.state.doc.textBetween(from, to);
    selectedText.value = selectedNode ?? "";
  } else {
    selectedText.value = "";
  }
};

defineExpose({
  getText,
  setHTML,
});

onMounted(async () => {
  // Écoutez les événements de sélection de l'éditeur
  // editor.value?.on("selectionUpdate", updateSelectedText);
  HTTP.get("variables").then((response) => {
    for (var i = 0; i < response.data.length; i++) {
      var itemValue = response.data[i];
      items.value.push({
        label: t("texteditor.variables." + itemValue.code),
        value: itemValue.code,
      });
    }
  });
});
</script>
<style lang="scss" scoped>
.align-button {
  padding: 5px;
}

.el-button:not(:disabled):hover {
  background-color: rgb(238, 238, 238) !important;
}

.el-button:disabled {
  color: lightgrey !important;
}

.button-bar {
  text-align: left;
}
.editor {
  background-color: white;
  text-align: initial !important;
}
ul {
  list-style-type: none; /* Supprime les puces */
  padding: 0; /* Supprime le retrait par défaut */
  margin: 0; /* Supprime la marge par défaut */
}
li {
  padding: 10px; /* Espacement interne */
  margin-bottom: 8px; /* Espacement externe entre les éléments */
  background-color: #f9f9f9; /* Couleur de fond pour visualiser les éléments */
  border-radius: 4px; /* Coins arrondis */
  cursor: pointer; /* Curseur en forme de main */
  transition: background-color 0.3s; /* Animation douce au survol */
}

li:hover {
  background-color: #e0e0e0; /* Couleur de fond au survol */
}

.active {
  background-color: #d0d0d0; /* Surlignage de l'élément actif */
  font-weight: bold; /* Texte en gras pour l'élément actif */
}
</style>
