<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    :class="{
      transparent: isTopOfPage && $route.name === 'home',
      'white-color': isTopOfPage,
    }"
    mode="horizontal"
    :ellipsis="false"
  >
    <el-menu-item
      index="0"
      :class="{
        'white-color': isTopOfPage && $route.name === 'home',
        hide: isTopOfPage && $route.name === 'home',
      }"
    >
      <router-link
        :to="{ name: 'home' }"
        :class="{
          'white-color': isTopOfPage && $route.name === 'home',
          hide: isTopOfPage && $route.name === 'home',
        }"
      >
        <span class="text-logo">LIVRE ARBITRE</span>
      </router-link>
    </el-menu-item>
    <div class="flex-grow" />
    <el-sub-menu index="1" v-if="user">
      <template #title>
        <span
          :class="{
            'white-color': isTopOfPage && $route.name === 'home',
          }"
        >
          <el-badge
            :hidden="!globalNotif()"
            is-dot
            class="dot-item"
            :offset="[5, 15]"
          >
            {{ $t("mainmenu.entry.reader") }}
          </el-badge>
        </span>
      </template>
      <router-link :to="{ name: 'catalog' }">
        <el-menu-item index="2-1">
          {{ $t("mainmenu.entry.subRead.catalog") }}
        </el-menu-item>
      </router-link>
      <router-link :to="{ name: 'readings' }">
        <el-menu-item index="2-2">
          {{ $t("mainmenu.entry.subRead.reads") }}
        </el-menu-item>
      </router-link>
      <router-link :to="{ name: 'shared-stories' }">
        <el-menu-item index="2-3">
          <el-badge
            is-dot
            class="dot-item"
            :offset="[5, 10]"
            :hidden="!hasSharedNotif"
            >{{ $t("mainmenu.entry.subRead.shared") }}</el-badge
          >
        </el-menu-item>
      </router-link>
    </el-sub-menu>
    <el-sub-menu index="2" v-if="user && isAuthor">
      <template #title
        ><span
          :class="{
            'white-color': isTopOfPage && $route.name === 'home',
          }"
          >Auteur</span
        ></template
      >
      <el-menu-item index="2-2">
        <router-link :to="{ name: 'authorStories' }">
          Vos histoires
        </router-link>
      </el-menu-item>
      <el-menu-item index="2-1"
        ><router-link :to="{ name: 'create' }"
          >Ecrire une histoire</router-link
        ></el-menu-item
      >
      <!-- <el-menu-item index="2-3">item three</el-menu-item>
      <el-sub-menu index="2-4">
          <template #title>item four</template>
          <el-menu-item index="2-4-1">item one</el-menu-item>
          <el-menu-item index="2-4-2">item two</el-menu-item>
          <el-menu-item index="2-4-3">item three</el-menu-item>
      </el-sub-menu> -->
    </el-sub-menu>
    <el-menu-item
      index="3"
      :class="{ 'white-color': isTopOfPage && $route.name === 'home' }"
      @click="dialogVisible = true"
      v-if="!user"
      >{{ $t("mainmenu.button.connect") }}
    </el-menu-item>
    <!-- <el-menu-item
      index="3"
      :class="{ 'white-color': isTopOfPage && $route.name === 'home' }"
      @click="dialogLogoutVisible = true"
      v-else
      >{{ user.userFirstName }}</el-menu-item
    > -->
    <el-sub-menu
      index="3"
      v-else
      :class="{ 'white-color': isTopOfPage && $route.name === 'home' }"
    >
      <template #title
        ><span
          :class="{
            'white-color': isTopOfPage && $route.name === 'home',
          }"
          >{{ user.userFirstName }}</span
        ></template
      >
      <router-link :to="{ name: 'profile' }">
        <el-menu-item index="3-1">Profile</el-menu-item>
      </router-link>
      <el-menu-item index="3-2" @click="dialogLogoutVisible = true">
        Deconnection
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
  <preregister-dialog
    :dialogVisible="dialogVisible"
    :dialogSize="dialogSize"
    :loginLoading="loginLoading"
    :preregister="preregister"
    :labelPosition="labelPosition"
    @close="dialogVisible = false"
    @submit="onPreregister"
  />
  <AuthDialog
    :dialog-visible="dialogVisible"
    :dialog-size="dialogSize"
    :register-open="registerOpen"
    @update:dialogVisible="dialogVisible = $event"
    @login="onLogin"
    @register="onRegister"
  />
  <el-dialog v-model="dialogLogoutVisible" title="Logout" width="30%">
    <span>Voulez vous vous déconnecter ?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogLogoutVisible = false">Annuler</el-button>
        <el-button type="danger" @click="onLogout">Valider</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted, onBeforeMount } from "vue";
import PreregisterDialog from "@/components/dialog/PreregisterDialog.vue";
import AuthDialog from "@/components/dialog/AuthDialog.vue"; // Assurez-vous du bon chemin

import { reactive } from "vue";
import { ElNotification } from "element-plus";
import { HTTP } from "@/http-common";
import { useUserStore } from "@/store/user";
import debounce from "lodash.debounce";

const userStore = useUserStore();
const user = computed(() => userStore.user);
const isTopOfPage = ref(true);
const loginLoading = ref(false);
const activeIndex = ref("1");
let labelPosition = "left";
let dialogSize = "40%";

const hasSharedNotif = ref(false);

const isAuthor = computed(() => user.value?.roles?.includes("Write"));
const dialogVisible = ref(false);
const registerOpen = ref(true);
const dialogLogoutVisible = ref(false);

const handleScroll = () => {
  // Modifie le statut basé sur la position de défilement.
  isTopOfPage.value = window.scrollY == 0;
};

function globalNotif() {
  return hasSharedNotif.value;
}

const handleResize = debounce(() => {
  labelPosition = window.innerWidth < 768 ? "top" : "left";
  dialogSize = window.innerWidth < 768 ? "70%" : "40%";
}, 100);

onBeforeMount(() => {
  userStore.onInit();
});
const preregister = reactive({
  Login: "",
});

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);
  handleResize();
  HTTP.get("/shared-stories").then((response) => {
    if (response.data.length > 0) {
      hasSharedNotif.value = true;
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  window.removeEventListener("resize", handleResize);
});

const onPreregister = async () => {
  loginLoading.value = true;
  var request = {
    email: preregister.Login,
  };
  HTTP.post("/subscribe", request)
    .then(() => {
      ElNotification({
        title: "Merci de vous etre inscrit",
        message:
          "Un email vous sera envoyé lorsque les inscriptions seront officiellement ouvertes",
        type: "success",
        showClose: false,
      });
    })
    .catch(function (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data == "Input is not an email"
      ) {
        ElNotification({
          title: "La valeur saisie est mal formatée",
          message:
            "Il semble que vous n'ayez pas saisi un email valide. Merci de vérifier vos données puis d'essayer de nouveau.",
          type: "warning",
          showClose: true,
        });
      } else {
        ElNotification({
          title: "Une erreur s'est produite",
          message:
            "Vérifier vos données en entrée puis réessayer. Si l'erreur persiste, merci de renouveller votre demande plus tard.",
          type: "error",
          showClose: true,
        });
      }
    });
  loginLoading.value = false;
  dialogVisible.value = false;
  preregister.Login = "";
};

const onLogin = async () => {
  // loginLoading.value = true;
  // const success = await userStore.login(logtosite.login, logtosite.password);
  // loginLoading.value = false;
  // if (!success) {
  //   // Gérer l'erreur de connexion
  //   loginError();
  // } else {
  //   dialogVisible.value = false;
  // }
};

const onRegister = async () => {
  // if (!register.Password || register.Password.trim() === "") {
  //   ElNotification({
  //     title: "Mot de passe vide",
  //     message: "Merci de remplir le mot de passe",
  //     type: "warning",
  //     showClose: false,
  //   });
  // } else if (!register.Password2 || register.Password2.trim() === "") {
  //   ElNotification({
  //     title: "Mot de passe vide",
  //     message: "Merci de saisir le second champ mot de passe",
  //     type: "warning",
  //     showClose: false,
  //   });
  // } else if (register.Password2 != register.Password) {
  //   ElNotification({
  //     title: "Mots de passe différents",
  //     message:
  //       "Assurez vous que les deux mots de passe saisis soient identiques",
  //     type: "error",
  //     showClose: false,
  //   });
  // } else if (
  //   !register.Password ||
  //   !register.Password2 ||
  //   !register.Lastname ||
  //   !register.Firstname ||
  //   !register.Login ||
  //   !register.Birthdate
  // ) {
  //   ElNotification({
  //     title: "Merci de renseigner tous les champs",
  //     message:
  //       "Un ou plusieurs champs du formulaire n'ont pas été remplis. Assurez vosu de renseigner tous les champs",
  //     type: "error",
  //     showClose: false,
  //   });
  // } else {
  //   var request = {
  //     login: register.Login,
  //     password: register.Password,
  //     birthdate: register.Birthdate,
  //     firstname: register.Firstname,
  //     lastname: register.Lastname,
  //   };
  //   HTTP.post("/users", request).then(() => {
  //     ElNotification({
  //       title: "Merci de vous etre inscrit",
  //       message:
  //         "Un email vient de vous etre envoyé à l'adresse renseignée, vous y trouverez le lien pour activer votre compte",
  //       type: "info",
  //       showClose: false,
  //     });
  //   });
  // }
};

const onLogout = () => {
  userStore.logout();
  dialogLogoutVisible.value = false;
};
</script>
<style>
/* .is-dot {
  margin-top: 10px;
  margin-right: -5px;
} */
</style>
