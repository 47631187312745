import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout first-slide" }

import SideMenu from "../components/writer/SideMenu.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'AuthorSubLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, { width: "200px" }, {
          default: _withCtx(() => [
            _createVNode(SideMenu)
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})