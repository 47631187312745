import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "first-slide" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "text-justify" }
const _hoisted_4 = { class: "card-footer text-right" }

import { onMounted, ref } from "vue";
import { HTTP } from "@/http-common";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogView',
  setup(__props) {

const stories = ref<any>();
const loading = ref(false);

const startReading = (id: number) => {
  HTTP.post("readings", {
    userId: 0,
    storyId: id,
  }).then(() => {
    HTTP.post("read", {
      storyId: id,
    }).then(() => {
      router.push({ name: "reader", params: { storyId: id } });
    });
  });
};

onMounted(async () => {
  loading.value = true;
  const response = await HTTP.get("stories");
  if (response.data.length > 0) {
    stories.value = response.data
      .map((item: { id: any; languages: any[] }) => ({
        id: item.id,
        languages: item.languages.filter((lang) => lang.langCode === "fr"),
      }))
      .filter(
        (item: { languages: string | any[] }) => item.languages.length > 0
      );
  }
  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t("catalog.title.main")), 1),
    _withDirectives((_openBlock(), _createBlock(_component_el_row, {
      gutter: 30,
      class: "story-list"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stories.value, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            span: 8,
            key: index
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "full-height" }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", null, _toDisplayString(item.languages[0].title), 1)
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_popover, {
                    placement: "top-start",
                    width: 400,
                    trigger: "hover",
                    content: item.languages[0].description
                  }, {
                    reference: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$truncateText(item.languages[0].description, 200)), 1)
                    ]),
                    _: 2
                  }, 1032, ["content"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_button, {
                      onClick: ($event: any) => (startReading(item.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("catalog.button.startReading")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })), [
      [_directive_loading, loading.value]
    ])
  ]))
}
}

})