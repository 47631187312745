<template>
  <h1>Liste des variables créés</h1>
  <div v-loading="loading">
    <el-empty :description="$t('variables.empty')" v-if="data.length == 0">
      <router-link :to="{ name: 'event', params: { storyId: props.storyId } }">
        <el-button type="primary">Ajouter votre première variable</el-button>
      </router-link>
    </el-empty>
    <div v-else>
      <el-table :data="data" style="width: 1400px">
        <el-table-column
          fixed
          prop="description"
          label="Description"
          width="150"
        />
        <el-table-column label="Content" width="920">
          <template #default="scope">
            <div
              v-html="
                scope.row.content.length > 200
                  ? scope.row.content.slice(0, 200) + '...'
                  : scope.row.content
              "
            ></div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Operations" width="210">
          <template #default="scope">
            <router-link
              :to="{
                name: 'event',
                params: { storyId: props.storyId, eventId: scope.row.id },
              }"
            >
              <el-button type="primary" size="small">Edit</el-button>
            </router-link>
            <el-button
              type="danger"
              size="small"
              @click="handleDelete(scope.$index, scope.row.id)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { HTTP } from "../http-common";
import { ref, onMounted, defineProps } from "vue";

interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}

const props = defineProps<StoryProps>();

interface Event {
  id: number;
  description: string;
  storyId: number;
  content: string;
}

const data = ref<Event[]>([]);
const loading = ref(true);

const handleDelete = (index: number, eventId: number) => {
  HTTP.delete("/events/" + eventId).then(() => {
    loading.value = true;
    HTTP.get("events/story/" + props.storyId).then((response) => {
      data.value = response.data;
      loading.value = false;
    });
  });
};

onMounted(async () => {
  if (props.storyId) {
    try {
      loading.value = true;
      const response = await HTTP.get("triggers/story/" + props.storyId);
      data.value = response.data;
      loading.value = false;
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  } else {
    console.log("No param in url");
  }
});
</script>
