import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


import StoryComponent from "../components/writer/StoryComponent.vue";
interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}

export default /*@__PURE__*/_defineComponent({
  __name: 'StoryView',
  props: {
    storyId: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t("story.title.update")), 1),
    _createVNode(StoryComponent, { storyId: _ctx.storyId }, null, 8, ["storyId"])
  ], 64))
}
}

})