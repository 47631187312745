<template>
  <div class="first-slide">
    <h1>{{ $t("profile.title.main") }}</h1>
    <div>
      <el-avatar :icon="UserFilled" />
      <div class="userData" v-loading="loading">
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item :label="$t('profile.title.login')">
            <el-input v-model="form.email" disabled />
          </el-form-item>
          <el-form-item :label="$t('profile.title.firstname')">
            <el-input v-model="form.firstname" />
          </el-form-item>
          <el-form-item :label="$t('profile.title.lastname')">
            <el-input v-model="form.lastname" />
          </el-form-item>
          <el-form-item :label="$t('profile.title.birthdate')">
            <el-col :span="11">
              <el-date-picker
                v-model="form.birthdate"
                type="date"
                style="width: 100%"
                disabled
              />
            </el-col>
          </el-form-item>
          <el-form-item :label="$t('profile.title.gender')">
            <el-select v-model="form.gender">
              <el-option :label="$t('profile.gender.male')" value="0" />
              <el-option :label="$t('profile.gender.female')" value="1" />
              <el-option :label="$t('profile.gender.neutral')" value="2" />
            </el-select>
          </el-form-item>
          <div class="text-right">
            <el-button type="primary" @click="onSubmit">
              {{ $t("profile.button.update") }}
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, onMounted, computed, reactive, ref } from "vue";
import { HTTP } from "@/http-common";
import { useUserStore } from "@/store/user";
import { UserFilled } from "@element-plus/icons-vue";

const loading = ref(true);
const userStore = useUserStore();
const user = computed(() => userStore.user);

const form = reactive({
  id: 0,
  email: "",
  firstname: "",
  lastname: "",
  birthdate: "",
  gender: "0",
});

const onSubmit = () => {
  loading.value = true;
  HTTP.put("own", {
    id: form.id,
    login: form.email,
    birthdate: form.birthdate,
    firstname: form.firstname,
    lastname: form.lastname,
    gender: +form.gender,
  }).then(() => {
    window.location.reload();
  });
};

// HTTP.get("users/"+user.)

onMounted(async () => {
  // Écoutez les événements de sélection de l'éditeur
  HTTP.get("own").then((response) => {
    form.id = response.data.id;
    form.firstname = response.data.firstname;
    form.lastname = response.data.lastname;
    form.birthdate = response.data.birthdate;
    form.gender = response.data.gender + "";
    form.email = response.data.login;
    loading.value = false;
  });
});
</script>
<style lang="css" scoped>
.userData {
  width: 600px;
  margin: auto;
  padding: 30px 20px 30px 20px;
  background-color: white;
  min-height: 100%;
  border: solid 1px black;
  border-radius: 5px;
}
</style>
