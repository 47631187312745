import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_container, { style: {"margin-top":"50px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_container, { class: "container" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("div", { id: "drawflow" }, null, -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.dialogVisible) = $event)),
      title: "Export",
      width: "50%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.dialogVisible = false))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Cancel")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.dialogVisible = false))
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Confirm")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("span", null, "Data:", -1)),
        _createElementVNode("pre", null, [
          _createElementVNode("code", null, _toDisplayString($setup.dialogData), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}