import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "readerBox" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]

import { onMounted, ref } from "vue";
import { HTTP } from "../http-common";

interface StoryProps {
  storyId: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ReadView',
  props: {
    storyId: {}
  },
  setup(__props: any) {

const props = __props;

const content = ref("");
const links = ref<Array>([]);
const loading = ref(false);

const nextStep = (nextId: number) => {
  HTTP.put("read", {
    storyId: props.storyId,
    stepId: nextId,
  }).then(() => {
    window.location.reload();
  });
};

onMounted(async () => {
  loading.value = true;
  const response = await HTTP.get("read?storyId=" + props.storyId);
  content.value = response.data.content;
  for (var i = 0; i < response.data.nextLinks.length; i++) {
    links.value.push({
      nextLinkId: response.data.nextLinks[i].nextLinkId,
      displayText: response.data.nextLinks[i].displayText,
    });
  }
  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_container, { class: "first-slide" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_main, { class: "reader" }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "readheader text-left" }, "Nom de l'histoire", -1)),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", { innerHTML: content.value }, null, 8, _hoisted_2),
            (links.value.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[0] || (_cache[0] = _createTextVNode(" Links : ")),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.nextLinkId
                    }, [
                      _createElementVNode("span", {
                        onClick: ($event: any) => (nextStep(item.nextLinkId)),
                        class: "clickable"
                      }, _toDisplayString(item.displayText) + " - Lien : " + _toDisplayString(item.nextLinkId), 9, _hoisted_4)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  })), [
    [_directive_loading, loading.value]
  ])
}
}

})