import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "home" }
const _hoisted_2 = { class: "second-slide slide" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "thrid-slide slide" }
const _hoisted_5 = { class: "text-wrapper white-color" }
const _hoisted_6 = { class: "fourth-slide slide" }
const _hoisted_7 = { class: "slide4-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "first-slide white-color" }, [
      _createElementVNode("div", { class: "text-wrapper text-uppercase" }, [
        _createElementVNode("h2", null, "Livre arbitre")
      ]),
      _createElementVNode("div", { class: "text-wrapper centered" }, [
        _createElementVNode("h1", null, "Soyez le héros de vos histoires")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-wrapper text-uppercase" }, "Les histoires", -1)),
        _createVNode(_component_el_row, {
          gutter: 30,
          justify: "center"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide2items, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_col, {
                xs: 24,
                sm: 12,
                md: 12,
                lg: 8,
                xl: 8,
                key: index
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_card, { class: "full-height" }, {
                    header: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("span", null, _toDisplayString(item.title), 1)
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_el_row, { gutter: 20 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, { span: 8 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_avatar, {
                                  shape: "square",
                                  size: 80,
                                  src: item.image
                                }, null, 8, ["src"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_el_col, {
                              span: 16,
                              class: "text-justify"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.content), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "black-shadow text-uppercase" }, "Les outils", -1)),
        _createVNode(_component_el_row, {
          gutter: 20,
          justify: "center",
          style: {"padding-right":"10px","padding-left":"10px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide3items, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_col, {
                xs: 24,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6,
                key: index
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_card, { class: "full-height tools-card" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_el_row, { gutter: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_avatar, {
                                  shape: "circle",
                                  size: 90,
                                  src: item.image,
                                  class: "avatar"
                                }, null, 8, ["src"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_el_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_el_row, { gutter: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, { class: "text-justify" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.content), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_row, { gutter: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              class: "event_photo",
              xs: 0,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            }),
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
              class: "event_desc"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("h2", { class: "text-uppercase" }, "Les evenements", -1),
                _createElementVNode("div", { class: "text-justify" }, [
                  _createElementVNode("p", null, " Livre Arbitre est une solution que vous pouvez aussi utiliser à des fins évènementielles. "),
                  _createElementVNode("p", null, " Vous souhaitez organiser un évènement dans votre ville, faire découvrir un lieu ou organiser un aventure immersive pour la sortie d'un livre, d'un film ou d'autre chose ? "),
                  _createElementVNode("p", null, " Vous souhaitez mettre en place un ice breaker pour un mariage, une conférence, ou n'importe quel évenement familial ou professionnel ? "),
                  _createElementVNode("p", null, " En utilisant Livre Arbitre vous pourrez proposer à toutes ces situations une expérience immersive, originale et ludique, qui sera avant tout personnalisée et répondant à vos besoins. ")
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}