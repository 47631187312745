<template>
  <div class="editor-class" v-loading="loading">
    <h1>{{ $t("steps.title.create") }}</h1>
    <h3>{{ $t("steps.subtitle.description") }}</h3>
    <el-form-item>
      <el-input
        v-model="description"
        :rows="2"
        type="textarea"
        :placeholder="$t('steps.placeholder.description')"
      />
    </el-form-item>
    <el-collapse>
      <el-collapse-item name="1" style="background-color: red !important">
        <template #title>
          <h3>{{ $t("steps.subtitle.settings") }}</h3>
        </template>
        <el-row>
          <el-col :span="12">
            <h3>{{ $t("steps.subtitle.parents") }}</h3>
            <el-select
              v-model="parentValue"
              value-key="id"
              placeholder="Select"
              style="width: 240px"
              @change="addParent()"
              :disabled="isHead"
            >
              <el-option
                v-for="item in parentOptions"
                :key="item.id"
                :label="item.label"
                :value="item"
              />
            </el-select>
            <el-row>
              <el-col
                :span="6"
                v-for="item in parentNodes"
                :key="item.id"
                class="card"
              >
                <div class="parent-body">
                  <h5>
                    <el-tooltip
                      :content="item.desc"
                      placement="bottom"
                      effect="light"
                    >
                      {{
                        item.desc.length > 14
                          ? item.desc.slice(0, 14) + "..."
                          : item.desc
                      }}
                    </el-tooltip>
                  </h5>
                  <div>
                    <el-input
                      v-model="item.link"
                      :placeholder="$t('steps.parent.link')"
                    />
                  </div>
                  <el-button
                    :icon="Close"
                    size="small"
                    circle
                    @click="deleteParent(item.id)"
                  />
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <h4>
              {{ $t("steps.subtitle.events") }}
              <el-tooltip placement="top" effect="light">
                <template #content>
                  <span v-html="$t('steps.help.events')"></span>
                </template>
                <el-icon><QuestionFilled /></el-icon>
              </el-tooltip>
            </h4>
            <el-select
              v-model="eventValue"
              value-key="id"
              placeholder="Select"
              style="width: 240px"
              @change="addEvent()"
            >
              <el-option
                v-for="item in eventOptions"
                :key="item.id"
                :label="item.label"
                :value="item"
              />
            </el-select>
            <el-row>
              <el-col
                :span="6"
                v-for="item in eventNodes"
                :key="item.id"
                class="card"
              >
                <div class="event-body">
                  <h5>{{ item.description }}</h5>
                  <div>
                    <el-input placeholder="Futur trigger" />
                  </div>
                  <el-button
                    :icon="Close"
                    size="small"
                    circle
                    @click="deleteEvent(item.id)"
                  />
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <h4>
          Est-ce l'élément de départ de votre histoire ?
          <el-switch
            v-model="isHead"
            class="mt-2"
            style="margin-left: 24px"
            inline-prompt
            :active-icon="Check"
            :inactive-icon="Close"
          />
        </h4>
      </el-collapse-item>
    </el-collapse>
    <h3>{{ $t("steps.subtitle.texteditor") }}</h3>
    <TextEditor ref="editor" />
    <div style="text-align: right" class="button-bar">
      <el-button type="cancel">Annuler</el-button>
      <el-tooltip
        content="Will allow you to see result as reader"
        placement="bottom"
        effect="light"
      >
        <el-button color="#8a2be2" @click="previewText"
          >Previsualiser</el-button
        >
      </el-tooltip>
      <el-button type="success" @click="validate">{{
        props.stepId ? "Mettre à jour" : "Créer"
      }}</el-button>
    </div>
    <el-dialog v-model="previewDialog" width="70%" align-center>
      <template #header="{ titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass">
            {{ $t("steps.title.preview") }}
          </h4>
        </div>
      </template>
      <div v-html="preview"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="previewDialog = false">Femer</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, defineProps } from "vue";
import TextEditor from "../components/TextEditor.vue";
import { HTTP } from "../http-common";
import router from "../router";
import { ElNotification } from "element-plus";
import { onBeforeRouteUpdate } from "vue-router";
import { QuestionFilled, Check, Close } from "@element-plus/icons-vue";

interface StepProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
  stepId: string | null;
}

type Option = {
  id: number;
  label: string;
};

interface parentNode {
  id: number;
  link: string;
  desc: string;
}

interface eventNode {
  id: number;
  description: string;
}

const props = defineProps<StepProps>();
const previewDialog = ref(false);
const isHead = ref(false);
const description = ref("");
const preview = ref("");

const loading = ref(false);
const parentValue = ref<Option>();
const parentOptions = ref<Option[]>([]);
const parentNodes = ref<parentNode[]>([]);
const editor = ref<typeof TextEditor>();

const eventValue = ref<Option>();
const eventOptions = ref<Option[]>([]);
const eventNodes = ref<eventNode[]>([]);

function deleteParent(linkId: number) {
  const indexToRemove = parentNodes.value.findIndex(
    (element) => element.id === linkId
  );
  if (indexToRemove !== -1) {
    parentNodes.value.splice(indexToRemove, 1);
  }
}

function deleteEvent(linkId: number) {
  const indexToRemove = eventNodes.value.findIndex(
    (element) => element.id === linkId
  );
  if (indexToRemove !== -1) {
    eventNodes.value.splice(indexToRemove, 1);
  }
}

function validate() {
  var request = {
    id: 0,
    storyId: props.storyId,
    content: editor.value?.getText(),
    isHead: isHead.value,
    description: description.value,
    forceHeadValue: true,
    parentLinks: parentNodes.value.map((node) => {
      return {
        previousStepId: node.id,
        description: node.link,
      };
    }),
    linkEvents: eventNodes.value.map((node) => {
      return {
        eventId: node.id,
      };
    }),
  };
  if (props.stepId) {
    request.id = +props.stepId;
  }
  if (props.stepId) {
    HTTP.put("stories/" + props.storyId + "/steps", request)
      .then((response) => {
        router.push({ name: "steps", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          ElNotification({
            title: "Echec de la mise à jour de l'étape",
            message:
              "Une erreur est survenue lors de la mise à jour de votre étape",
            type: "error",
            showClose: false,
          });
        }
      });
  } else {
    HTTP.post("stories/" + props.storyId + "/steps", request)
      .then((response) => {
        router.push({ name: "steps", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          ElNotification({
            title: "Echec de la création de l'étape",
            message:
              "Une erreur est survenue lors de la création de votre étape",
            type: "error",
            showClose: false,
          });
        }
      });
  }
}

const previewText = () => {
  var request = {
    textToPreview: editor.value?.getText(),
  };
  HTTP.post("preview/step?storyId=" + props.storyId, request).then(
    (response) => {
      preview.value = response.data.renderedText;
    }
  );
  //preview.value = editor.value?.getText();
  //preview.value = preview.value.replace("{{opt1}}", "Paul");
  previewDialog.value = true;
};

const addParent = () => {
  const containsId5 = parentNodes.value.some(
    (node) => node.id === parentValue.value?.id
  );
  if (!containsId5) {
    parentNodes.value.push({
      id: parentValue.value?.id ?? 0,
      link: "",
      desc: parentValue.value?.label ?? "",
    });
  }
  parentValue.value = undefined;
};

const addEvent = () => {
  const containsId5 = eventNodes.value.some(
    (node) => node.id === eventValue.value?.id
  );
  if (!containsId5) {
    eventNodes.value.push({
      id: eventValue.value?.id ?? 0,
      description: eventValue.value?.label ?? "",
    });
  }
  eventValue.value = undefined;
};

onMounted(async () => {
  // Écoutez les événements de sélection de l'éditeur
  HTTP.get("stories/" + props.storyId + "/steps").then((response) => {
    for (var i = 0; i < response.data.length; i++) {
      var item = response.data[i];
      parentOptions.value.push({
        id: item.id,
        label: item.description,
      });
    }
  });
  HTTP.get("events/story/" + props.storyId).then((response) => {
    for (var i = 0; i < response.data.length; i++) {
      var item = response.data[i];
      eventOptions.value.push({
        id: item.id,
        label: item.description,
      });
    }
  });
  if (props.stepId) {
    loading.value = true;
    const response = await HTTP.get(
      "stories/" + props.storyId + "/steps/" + props.stepId
    );
    isHead.value = response.data.isHead;
    description.value = response.data.description;
    if (response.data.parentLinks) {
      for (var i = 0; i < response.data.parentLinks.length; i++) {
        parentNodes.value.push({
          id: response.data.parentLinks[i].previousStepId,
          link: response.data.parentLinks[i].description,
          desc: "",
        });
      }
      for (var j = 0; j < response.data.linkEvents.length; j++) {
        const indexToRead = eventOptions.value.findIndex(
          (element) => element.id === response.data.linkEvents[j].eventId
        );
        var element = eventOptions.value[indexToRead];
        eventNodes.value.push({
          id: response.data.linkEvents[j].eventId,
          description: element.label,
        });
      }
    }

    editor.value?.setHTML(response.data.content);
    loading.value = false;
  }
});

onBeforeRouteUpdate((to, from) => {
  // Vérifiez si le chemin de l'URL a changé pour /steps
  // console.log("Page change", to);
  // if (to.name === from.name) {
  //   window.location.reload(); // Actualisez la page
  // }
});
</script>
<style>
.ProseMirror:focus {
  outline: none;
}
.tiptap {
  border: 1px solid gray;
}
.el-collapse-item__header,
.el-collapse-item__content {
  background-color: var(--background-color) !important;
}
.el-collapse-item__content {
  padding-bottom: 15px;
}
</style>
<style lang="scss" scoped>
h1,
h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); /* Légère ligne sous le titre pour le séparer */
}

h3,
h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 15px 0;
  // padding: 10px 0;
}

h5 {
  margin: 5px;
}

.button-bar {
  margin-top: 25px;
}

.card {
  position: relative;
  // width: 200px;
  // height: 150px;
  // background-color: #f0f0f0;
  // padding: 20px;
  padding: 5px;
  // border-radius: 10px;
  // transition: transform 0.3s ease, height 0.3s ease;
  // overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.parent-body h4,
.event-body h4 {
  font-size: 1rem !important;
  margin: 0 !important;
}

.parent-body {
  padding: 5px;
  background-color: #8a2be2;
  border-radius: 10px;
  transition: transform 0.3s ease, height 0.3s ease;
  // overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-body {
  padding: 5px;
  background-color: #6ca6cd;
  border-radius: 10px;
  transition: transform 0.3s ease, height 0.3s ease;
  // overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-expanded {
  // position: absolute;
  z-index: 1; /* Assure que la carte agrandie est au-dessus des autres éléments */
  // width: 400px; /* Change la largeur de la carte une fois étendue */
  // height: 300px; /* Change la hauteur */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.card-details {
  display: none;
  padding: 10px;
}

.card-expanded .card-details {
  display: block; /* Affiche les détails lorsque la carte est étendue */
}

.card button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.editor-class {
  text-align: left;
}

.collapse-header {
  background-color: red !important;
}
.el-collapse-item {
  background-color: red !important;
}

.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
