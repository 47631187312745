import { AppContext } from "vue";
import { Options } from "./options";

export interface INode {
  name: string;
  color: string;
  item: string;
  input: Array<{ label: string }>;
  output: Array<{ label: string }>;
}

export class Freeflow {
  private events: Record<string, any> = {};
  private container: HTMLElement | null;
  private backCanvas: HTMLDivElement | null = null; // preCanvas
  private nodeId = 1;
  private ele_selected: HTMLDivElement | null = null;
  private connection = false;
  private connection_ele: SVGSVGElement | null = null;
  private canvas_x = 0;
  private canvas_y = 0;
  private pos_x = 0;
  private pos_x_start = 0;
  private pos_y = 0;
  private pos_y_start = 0;
  private parent: any;

  private noderegister = {};
  private render: any;

  private nodes: INode[];
  private zoomConf: Options;

  constructor(
    container: HTMLElement | null,
    nodes: INode[],
    render: any,
    parent: AppContext
  ) {
    this.container = container;
    this.render = render;
    this.parent = parent;
    this.nodes = nodes;

    this.zoomConf = new Options();

    if (this.container) {
      console.info("Start freeflow !!");

      // declare components
      this.container.classList.add("root-freeflow"); // old one parent-drawflow
      this.container.tabIndex = 0;

      // Create menu to drag and drop components
      const menu = document.createElement("div");
      menu.classList.add("freeflow-menu");
      const ul = document.createElement("ul");
      menu.appendChild(ul);
      nodes.forEach((element) => {
        const li = document.createElement("li");
        li.draggable = true;
        const node = document.createElement("div");
        node.classList.add("menu-item");
        node.innerText = element.name;
        node.style.borderColor = element.color;
        li.dataset.node = element.item;
        li.appendChild(node);
        ul.appendChild(li);
      });

      this.container.appendChild(menu);
      // Create the back canvas to place item on it
      this.backCanvas = document.createElement("div");
      this.backCanvas.classList.add("ff-canvas"); // old one drawflow
      this.container.appendChild(this.backCanvas);

      // Add listener events
      // drop events
      this.container.addEventListener("dragstart", this.drag.bind(this));
      this.container.addEventListener("dragover", this.allowDrop.bind(this));
      this.container.addEventListener("drop", this.drop.bind(this));
      this.container.addEventListener("mousedown", this.click.bind(this));
      // TODO add this event only when something is cliked
      this.container.addEventListener("mousemove", this.moveElement.bind(this));
      this.container.addEventListener("mouseup", this.dragEnd.bind(this));
      /* Zoom events */
      this.container.addEventListener("wheel", this.zoom_enter.bind(this));
    } else {
      return;
    }
  }

  // protected methods
  protected drop(ev: any) {
    ev.preventDefault();
    if (ev.target.classList.contains("drawflow_content_node")) {
      ev.target.classList.add("filled");
      return;
    }
    const data = ev.dataTransfer.getData("node");
    this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
  }

  // Private methods
  private allowDrop(ev: { preventDefault: () => void }) {
    ev.preventDefault();
  }

  private isEditorSelected() {
    if (
      this.ele_selected != null &&
      (this.ele_selected.classList.contains("root-freeflow") ||
        this.ele_selected.classList.contains("ff-canvas"))
    ) {
      return true;
    }
    return false;
  }

  private isNodeSelected() {
    if (
      this.ele_selected != null &&
      this.ele_selected.classList.contains("drawflow-node")
    ) {
      return true;
    }
    return false;
  }

  private isConnectionSelected() {
    if (
      this.ele_selected != null &&
      (this.ele_selected.classList.contains("connection") ||
        this.ele_selected.classList.contains("main-path"))
    ) {
      return true;
    }
    return false;
  }

  private isInputSelected() {
    if (
      this.ele_selected != null &&
      this.ele_selected.classList.contains("input")
    ) {
      return true;
    }
    return false;
  }

  private isOutputSelected() {
    if (
      this.ele_selected != null &&
      this.ele_selected.classList.contains("output")
    ) {
      return true;
    }
    return false;
  }

  private click(e: any) {
    this.dispatch("click", e);
    // remove selected from all elments
    const selectedElements = document.querySelectorAll(".selected");
    // Itérer sur chaque élément et supprimer la classe 'selected'
    selectedElements.forEach((element) => {
      element.classList.remove("selected");
    });
    // TODO if mode is to view and not modify we should change things here
    this.ele_selected = e.target;
    // right click in order to handle menu
    // if (e.button === 0) {
    //   this.contextmenuDel();
    // }

    if (e.target.closest(".drawflow_content_node") != null) {
      this.ele_selected = e.target.closest(
        ".drawflow_content_node"
      ).parentElement;
    }
    if (this.ele_selected == null) {
      throw new Error();
    }
    if (this.isOutputSelected()) {
      this.drawConnection(e.target);
    }
    if (this.isInputSelected()) {
      this.drawConnectionFromEnd(e.target);
    }

    if (this.isConnectionSelected()) {
      this.ele_selected.classList.add("selected");
      this.dispatch("connectionSelected", this.ele_selected);
    }

    if (this.isNodeSelected()) {
      this.ele_selected.classList.add("selected");
      this.dispatch("nodeSelected", this.ele_selected.dataset.id);
    }
    // TODO handle single action like selection
    // switch (this.ele_selected.classList[0]) {
    //   case "drawflow-node":
    //     if (this.node_selected != null) {
    //       this.node_selected.classList.remove("selected");
    //       if (this.node_selected != this.ele_selected) {
    //         this.dispatch("nodeUnselected", true);
    //       }
    //     }
    //     if (this.connection_selected != null) {
    //       this.connection_selected.classList.remove("selected");
    //       this.removeReouteConnectionSelected();
    //       this.connection_selected = null;
    //     }
    //     if (this.node_selected != this.ele_selected) {
    //       this.dispatch("nodeSelected", this.ele_selected.id.slice(5));
    //     }
    //     this.node_selected = this.ele_selected;
    //     this.node_selected.classList.add("selected");
    //     if (!this.draggable_inputs) {
    //       if (
    //         e.target.tagName !== "INPUT" &&
    //         e.target.tagName !== "TEXTAREA" &&
    //         e.target.tagName !== "SELECT" &&
    //         e.target.hasAttribute("contenteditable") !== true
    //       ) {
    //         this.drag = true;
    //       }
    //     } else {
    //       if (e.target.tagName !== "SELECT") {
    //         this.drag = true;
    //       }
    //     }
    //     break;
    //   case "output":
    //     this.connection = true;
    //     if (this.node_selected != null) {
    //       this.node_selected.classList.remove("selected");
    //       this.node_selected = null;
    //       this.dispatch("nodeUnselected", true);
    //     }
    //     if (this.connection_selected != null) {
    //       this.connection_selected.classList.remove("selected");
    //       this.removeReouteConnectionSelected();
    //       this.connection_selected = null;
    //     }
    //     this.drawConnection(e.target);
    //     break;
    //   case "parent-drawflow":
    //     if (this.node_selected != null) {
    //       this.node_selected.classList.remove("selected");
    //       this.node_selected = null;
    //       this.dispatch("nodeUnselected", true);
    //     }
    //     if (this.connection_selected != null) {
    //       this.connection_selected.classList.remove("selected");
    //       this.removeReouteConnectionSelected();
    //       this.connection_selected = null;
    //     }
    //     this.editor_selected = true;
    //     break;
    //   case "drawflow":
    //     if (this.node_selected != null) {
    //       this.node_selected.classList.remove("selected");
    //       this.node_selected = null;
    //       this.dispatch("nodeUnselected", true);
    //     }
    //     if (this.connection_selected != null) {
    //       this.connection_selected.classList.remove("selected");
    //       this.removeReouteConnectionSelected();
    //       this.connection_selected = null;
    //     }
    //     this.editor_selected = true;
    //     break;
    //   case "main-path":
    //     if (this.node_selected != null) {
    //       this.node_selected.classList.remove("selected");
    //       this.node_selected = null;
    //       this.dispatch("nodeUnselected", true);
    //     }
    //     if (this.connection_selected != null) {
    //       this.connection_selected.classList.remove("selected");
    //       this.removeReouteConnectionSelected();
    //       this.connection_selected = null;
    //     }
    //     this.connection_selected = this.ele_selected;
    //     this.connection_selected.classList.add("selected");
    //     // eslint-disable-next-line no-case-declarations
    //     const listclassConnection =
    //       this.connection_selected.parentElement.classList;
    //     if (listclassConnection.length > 1) {
    //       this.dispatch("connectionSelected", {
    //         output_id: listclassConnection[2].slice(14),
    //         input_id: listclassConnection[1].slice(13),
    //         output_class: listclassConnection[3],
    //         input_class: listclassConnection[4],
    //       });
    //       if (this.reroute_fix_curvature) {
    //         this.connection_selected.parentElement
    //           .querySelectorAll(".main-path")
    //           .forEach((item, i) => {
    //             item.classList.add("selected");
    //           });
    //       }
    //     }
    //     break;
    //   case "point":
    //     this.drag_point = true;
    //     this.ele_selected.classList.add("selected");
    //     break;
    //   case "drawflow-delete":
    //     if (this.node_selected) {
    //       this.removeNodeId(this.node_selected.id);
    //     }

    //     if (this.connection_selected) {
    //       this.removeConnection();
    //     }

    //     if (this.node_selected != null) {
    //       this.node_selected.classList.remove("selected");
    //       this.node_selected = null;
    //       this.dispatch("nodeUnselected", true);
    //     }
    //     if (this.connection_selected != null) {
    //       this.connection_selected.classList.remove("selected");
    //       this.removeReouteConnectionSelected();
    //       this.connection_selected = null;
    //     }

    //     break;
    //   default:
    // }
    this.pos_x = e.clientX;
    this.pos_x_start = e.clientX;
    this.pos_y = e.clientY;
    this.pos_y_start = e.clientY;

    this.dispatch("clickEnd", e);
  }
  // Finished moving with an elkment selected
  private dragEnd(e: any) {
    const e_pos_x = e.clientX;
    const e_pos_y = e.clientY;
    const ele_last = e.target;

    if (this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
      //this.dispatch("nodeMoved", this.ele_selected.id.slice(5));
    }
    if (this.isEditorSelected()) {
      this.canvas_x = this.canvas_x + -(this.pos_x - e_pos_x);
      this.canvas_y = this.canvas_y + -(this.pos_y - e_pos_y);
      this.ele_selected = null;
    }
    if (
      (this.isOutputSelected() || this.isInputSelected()) &&
      this.connection_ele != null &&
      this.ele_selected != null
    ) {
      if (ele_last.classList.contains("input") && this.isOutputSelected()) {
        // todo des choses à faire
        const input_id = ele_last.dataset.node;
        const output_id = this.ele_selected.dataset.node;
        // TODO add check if connection already exist (look at the updatedConnection code to find existing connections)
        if (
          input_id == output_id ||
          ele_last.dataset.type != this.ele_selected.dataset.type
        ) {
          this.connection_ele.remove();
        } else {
          this.connection_ele.classList.add("node_in_" + input_id);
          this.connection_ele.classList.add("node_out_" + output_id);

          this.connection_ele.dataset.target = input_id;
          this.connection_ele.dataset.source = output_id;
          this.connection_ele.dataset.type = ele_last.dataset.type;

          this.connection_ele.dataset.input = ele_last.dataset.input;
          this.connection_ele.dataset.output = this.ele_selected.dataset.output;

          ele_last.dataset.source = output_id;
          this.ele_selected.dataset.target = input_id;
        }
      } else if (
        // we start from 'end'
        ele_last.classList.contains("output") &&
        this.isInputSelected()
      ) {
        // todo des choses à faire
        const input_id = this.ele_selected.dataset.node;
        const output_id = ele_last.dataset.node;
        // TODO add check if connection already exist (look at the updatedConnection code to find existing connections)
        if (
          input_id == output_id ||
          ele_last.dataset.type != this.ele_selected.dataset.type
        ) {
          this.connection_ele.remove();
        } else {
          this.connection_ele.classList.add("node_in_" + input_id);
          this.connection_ele.classList.add("node_out_" + output_id);

          this.connection_ele.dataset.target = input_id;
          this.connection_ele.dataset.source = output_id;
          this.connection_ele.dataset.type = ele_last.dataset.type;

          this.connection_ele.dataset.input = this.ele_selected.dataset.input;
          this.connection_ele.dataset.output = ele_last.dataset.output;

          this.ele_selected.dataset.source = output_id;
          ele_last.dataset.target = input_id;
        }
      } else {
        if (this.connection_ele != null) {
          this.connection_ele.remove();
        }
      }
    }

    this.ele_selected = null;
    this.connection_ele = null;
    this.dispatch("mouseUp", e);
  }
  private addNodeToDrawFlow(name: string, pos_x: number, pos_y: number) {
    if (this.backCanvas == null) {
      throw new Error();
    }
    pos_x =
      pos_x *
        (this.backCanvas.clientWidth /
          (this.backCanvas.clientWidth * this.zoomConf.zoom)) -
      this.backCanvas.getBoundingClientRect().x *
        (this.backCanvas.clientWidth /
          (this.backCanvas.clientWidth * this.zoomConf.zoom));
    pos_y =
      pos_y *
        (this.backCanvas.clientHeight /
          (this.backCanvas.clientHeight * this.zoomConf.zoom)) -
      this.backCanvas.getBoundingClientRect().y *
        (this.backCanvas.clientHeight /
          (this.backCanvas.clientHeight * this.zoomConf.zoom));
    const nodeSelected = this.nodes.find((ele) => ele.item == name);
    if (nodeSelected == null) {
      return new Error("Node not found");
    }
    this.addNode(
      name,
      nodeSelected.input,
      nodeSelected.output,
      pos_x,
      pos_y,
      name,
      { title: true },
      name,
      "vue"
    );
  }

  private addNode(
    name: string,
    number_input: string | any[],
    number_output: string | any[],
    ele_pos_x: string | number,
    ele_pos_y: string | number,
    classoverride: string,
    data: any,
    html: string,
    typenode: string
  ) {
    const newNodeId = this.nodeId;
    const parent = document.createElement("div");
    parent.classList.add("parent-node");

    const node = document.createElement("div");
    node.innerHTML = "";
    node.dataset.id = newNodeId + "";
    node.setAttribute("id", "node-" + newNodeId);
    node.classList.add("drawflow-node");
    if (classoverride != "") {
      node.classList.add(...classoverride.split(" "));
    }
    // we should have specific input/output regarding base object
    const inputs = document.createElement("div");
    inputs.classList.add("inputs");

    const outputs = document.createElement("div");
    outputs.classList.add("outputs");

    //const json_inputs: never[] = [];
    for (let x = 0; x < number_input.length; x++) {
      const input = document.createElement("div");
      input.classList.add("input");
      input.classList.add("input_" + (x + 1));
      //json_inputs["input_" + (x + 1)] = { connections: [] };
      input.dataset.input = x + 1 + "";
      input.dataset.node = newNodeId + "";
      input.dataset.type = number_input[x].label;
      inputs.appendChild(input);
    }

    // const json_outputs = {};
    for (let x = 0; x < number_output.length; x++) {
      const output = document.createElement("div");
      output.classList.add("output");
      output.classList.add("output_" + (x + 1));
      // json_outputs["output_" + (x + 1)] = { connections: [] };
      output.dataset.output = x + 1 + "";
      output.dataset.node = newNodeId + "";
      output.dataset.type = number_output[x].label;
      outputs.appendChild(output);
    }

    const contentWrapper = document.createElement("div");
    contentWrapper.dataset.id = newNodeId + "";
    contentWrapper.setAttribute("id", "node-" + newNodeId);
    contentWrapper.classList.add("drawflow_content_wrapper");

    const content = document.createElement("div");
    content.classList.add("drawflow_content_node");

    //Vue 3
    // Add node title
    if (data.title) {
      const inner = document.createElement("div");
      inner.classList.add("node_title");
      inner.innerText = name;
      content.appendChild(inner);
    }

    const nodedata = document.createElement("div");
    nodedata.classList.add("node_data");
    content.appendChild(nodedata);

    // Add dynamic vue content
    // TODO get value from objet
    // const wrapper = this.render.h(
    //   this.noderegister[html].html,
    //   this.noderegister[html].props,
    //   this.noderegister[html].options
    // );
    // wrapper.appContext = this.parent;
    // this.render.render(wrapper, content);

    // Exemple de cast
    // if (elem instanceof HTMLElement && elem.isContentEditable) {
    //   elem.innerText = key[1]; // Pour les éléments éditables
    // }

    // Object.entries(data).forEach(function (key, value) {
    //   if (typeof key[1] === "object") {
    //     insertObjectkeys(null, key[0], key[0]);
    //   } else {
    //     const elems = content.querySelectorAll("[df-" + key[0] + "]");
    //     for (let i = 0; i < elems.length; i++) {
    //       const elem = elems[i];
    //       elem.value = key[1];
    //       if (elem.isContentEditable) {
    //         elem.innerText = key[1];
    //       }
    //     }
    //   }
    // });

    // function insertObjectkeys(object, name, completname) {
    //   if (object === null) {
    //     var object = data[name];
    //   } else {
    //     var object = object[name];
    //   }
    //   if (object !== null) {
    //     Object.entries(object).forEach(function (key, value) {
    //       if (typeof key[1] === "object") {
    //         insertObjectkeys(object, key[0], completname + "-" + key[0]);
    //       } else {
    //         var elems = content.querySelectorAll(
    //           "[df-" + completname + "-" + key[0] + "]"
    //         );
    //         for (var i = 0; i < elems.length; i++) {
    //           elems[i].value = key[1];
    //           if (elems[i].isContentEditable) {
    //             elems[i].innerText = key[1];
    //           }
    //         }
    //       }
    //     });
    //   }
    // }

    // contentWrapper.appendChild(content);

    node.appendChild(inputs);
    // node.appendChild(contentWrapper);
    node.appendChild(content);

    node.appendChild(outputs);
    node.style.top = ele_pos_y + "px";
    node.style.left = ele_pos_x + "px";
    parent.appendChild(node);
    if (this.backCanvas == null) {
      return new Error();
    }

    this.backCanvas.appendChild(parent);
    this.dispatch("nodeCreated", newNodeId);
    this.nodeId++;
    return newNodeId;
  }

  private drag(ev: any) {
    if (ev.type === "touchstart") {
      // mobile_item_selec = ev.target
      //   .closest(".drag-drawflow")
      //   .getAttribute("data-node");
    } else {
      ev.dataTransfer.setData("node", ev.target.getAttribute("data-node"));
    }
  }

  private moveBackground(e_pos_x: number, e_pos_y: number) {
    const x = this.canvas_x + -(this.pos_x - e_pos_x);
    const y = this.canvas_y + -(this.pos_y - e_pos_y);
    this.dispatch("translate", { x: x, y: y });

    if (this.backCanvas == null) {
      throw new Error();
    }
    this.backCanvas.style.transform =
      "translate(" + x + "px, " + y + "px) scale(" + this.zoomConf.zoom + ")";
  }

  private moveElement(e: any) {
    let e_pos_x;
    let e_pos_y;
    if (this.connection) {
      e_pos_x = e.clientX;
      e_pos_y = e.clientY;
    } else {
      e_pos_x = e.clientX; // - (e.clientX % 25);
      e_pos_y = e.clientY; // - (e.clientY % 25);
    }
    if (this.isEditorSelected()) {
      this.moveBackground(e_pos_x, e_pos_y);
    }

    if (this.isNodeSelected()) {
      e.preventDefault();
      if (this.backCanvas == null || this.ele_selected == null) {
        return new Error();
      }
      const x =
        ((this.pos_x - e_pos_x) * this.backCanvas.clientWidth) /
        (this.backCanvas.clientWidth * this.zoomConf.zoom);
      const y =
        ((this.pos_y - e_pos_y) * this.backCanvas.clientHeight) /
        (this.backCanvas.clientHeight * this.zoomConf.zoom);

      if (this.isEditorSelected() == false) {
        this.pos_x = e_pos_x;
        this.pos_y = e_pos_y;

        this.ele_selected.style.top = this.ele_selected.offsetTop - y + "px";
        this.ele_selected.style.left = this.ele_selected.offsetLeft - x + "px";
      }
      this.updateConnectionNodes(this.ele_selected.dataset.id);
    }
    if (this.isOutputSelected()) {
      this.updateConnection(e_pos_x, e_pos_y);
    }
    if (this.isInputSelected()) {
      this.updateConnection(e_pos_x, e_pos_y);
    }
    this.dispatch("mouseMove", { x: e_pos_x, y: e_pos_y });
  }

  private updateConnection(eX: number, eY: number) {
    const precanvas = this.backCanvas;
    if (
      precanvas == null ||
      this.ele_selected == null ||
      this.backCanvas == null ||
      this.connection_ele == null
    ) {
      throw new Error();
    }
    const zoom = this.zoomConf.zoom;
    let precanvasWitdhZoom =
      precanvas.clientWidth / (precanvas.clientWidth * zoom);
    precanvasWitdhZoom = precanvasWitdhZoom || 0;
    let precanvasHeightZoom =
      precanvas.clientHeight / (precanvas.clientHeight * zoom);
    precanvasHeightZoom = precanvasHeightZoom || 0;
    const path = this.connection_ele.children[0];

    const line_x =
      this.ele_selected.offsetWidth / 2 +
      (this.ele_selected.getBoundingClientRect().x -
        precanvas.getBoundingClientRect().x) *
        precanvasWitdhZoom;
    const line_y =
      this.ele_selected.offsetHeight / 2 +
      (this.ele_selected.getBoundingClientRect().y -
        precanvas.getBoundingClientRect().y) *
        precanvasHeightZoom;

    const x =
      eX *
        (this.backCanvas.clientWidth / (this.backCanvas.clientWidth * zoom)) -
      this.backCanvas.getBoundingClientRect().x *
        (this.backCanvas.clientWidth / (this.backCanvas.clientWidth * zoom));
    const y =
      eY *
        (this.backCanvas.clientHeight / (this.backCanvas.clientHeight * zoom)) -
      this.backCanvas.getBoundingClientRect().y *
        (this.backCanvas.clientHeight / (this.backCanvas.clientHeight * zoom));

    let lineCurve;
    if (this.isOutputSelected()) {
      lineCurve = this.createCurvature(line_x, line_y, x, y, "openclose");
    } else {
      lineCurve = this.createCurvature(x, y, line_x, line_y, "openclose");
    }
    path.setAttributeNS(null, "d", lineCurve);
  }

  private zoom_enter(event: any) {
    if (event.ctrlKey) {
      event.preventDefault();
      if (event.deltaY > 0) {
        // Zoom Out
        this.zoomConf.zoom_out();
        this.refreshCanvas();
      } else {
        // Zoom In
        this.zoomConf.zoom_in();
        this.refreshCanvas();
      }
      // console.log("Zoom value: ", this.zoomConf.zoom);
    }
  }

  private refreshCanvas() {
    // this.dispatch("zoom", this.zoom);
    if (this.backCanvas == null) {
      throw new Error("Canvas not initialized");
    }
    this.canvas_x =
      (this.canvas_x / this.zoomConf.zoom_last_value) * this.zoomConf.zoom;
    this.canvas_y =
      (this.canvas_y / this.zoomConf.zoom_last_value) * this.zoomConf.zoom;
    this.zoomConf.zoom_last_value = this.zoomConf.zoom;
    this.backCanvas.style.transform =
      "translate(" +
      this.canvas_x +
      "px, " +
      this.canvas_y +
      "px) scale(" +
      this.zoomConf.zoom +
      ")";
    if (this.container == null) {
      throw new Error();
    }
    // this.container.style.backgroundSize =
    //   this.zoomConf.zoom * 20 + "px " + this.zoomConf.zoom * 20 + "px";
  }

  /** connection related methods */
  private drawConnectionFromEnd(sourceElement: {
    dataset: { node: any };
    classList: any[];
  }) {
    console.log("Start connection from 'end'");
    if (this.backCanvas == null) {
      throw new Error();
    }
    const connection = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    this.connection_ele = connection;
    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.classList.add("main-path");
    path.setAttributeNS(null, "d", "");
    connection.classList.add("connection");
    connection.appendChild(path);
    this.backCanvas.appendChild(connection);
    const id_input = sourceElement.dataset.node;
    const input_class = sourceElement.classList[1];
    this.dispatch("connectionStart", {
      input_id: id_input,
      input_class: input_class,
    });
  }

  private drawConnection(sourceElement: {
    dataset: { node: any };
    classList: any[];
  }) {
    if (this.backCanvas == null) {
      throw new Error();
    }
    const connection = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    this.connection_ele = connection;
    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.classList.add("main-path");
    path.setAttributeNS(null, "d", "");
    connection.classList.add("connection");
    connection.appendChild(path);
    this.backCanvas.appendChild(connection);
    const id_output = sourceElement.dataset.node;
    const output_class = sourceElement.classList[1];
    this.dispatch("connectionStart", {
      output_id: id_output,
      output_class: output_class,
    });
  }

  private createCurvature(
    start_pos_x: number,
    start_pos_y: number,
    end_pos_x: number,
    end_pos_y: number,
    type: string
  ) {
    const line_x = start_pos_x;
    const line_y = start_pos_y;
    const x = end_pos_x;
    const y = end_pos_y;
    const curvature = 0.5;
    let hx1;
    let hx2;
    //type openclose open close other
    switch (type) {
      case "open":
        if (start_pos_x >= end_pos_x) {
          hx1 = line_x + Math.abs(x - line_x) * curvature;
          hx2 = x - Math.abs(x - line_x) * (curvature * -1);
        } else {
          hx1 = line_x + Math.abs(x - line_x) * curvature;
          hx2 = x - Math.abs(x - line_x) * curvature;
        }
        return (
          " M " +
          line_x +
          " " +
          line_y +
          " C " +
          hx1 +
          " " +
          line_y +
          " " +
          hx2 +
          " " +
          y +
          " " +
          x +
          "  " +
          y
        );

        break;
      case "close":
        if (start_pos_x >= end_pos_x) {
          hx1 = line_x + Math.abs(x - line_x) * (curvature * -1);
          hx2 = x - Math.abs(x - line_x) * curvature;
        } else {
          hx1 = line_x + Math.abs(x - line_x) * curvature;
          hx2 = x - Math.abs(x - line_x) * curvature;
        }
        return (
          " M " +
          line_x +
          " " +
          line_y +
          " C " +
          hx1 +
          " " +
          line_y +
          " " +
          hx2 +
          " " +
          y +
          " " +
          x +
          "  " +
          y
        );
        break;
      case "other":
        if (start_pos_x >= end_pos_x) {
          hx1 = line_x + Math.abs(x - line_x) * (curvature * -1);
          hx2 = x - Math.abs(x - line_x) * (curvature * -1);
        } else {
          hx1 = line_x + Math.abs(x - line_x) * curvature;
          hx2 = x - Math.abs(x - line_x) * curvature;
        }
        return (
          " M " +
          line_x +
          " " +
          line_y +
          " C " +
          hx1 +
          " " +
          line_y +
          " " +
          hx2 +
          " " +
          y +
          " " +
          x +
          "  " +
          y
        );
        break;
      default:
        hx1 = line_x + Math.abs(x - line_x) * curvature;
        hx2 = x - Math.abs(x - line_x) * curvature;

        return (
          " M " +
          line_x +
          " " +
          line_y +
          " C " +
          hx1 +
          " " +
          line_y +
          " " +
          hx2 +
          " " +
          y +
          " " +
          x +
          "  " +
          y
        );
    }
  }

  updateConnectionNodes(id: string | undefined) {
    const container = this.container;
    const precanvas = this.backCanvas;
    const createCurvature = this.createCurvature;
    const zoom = this.zoomConf.zoom;
    if (precanvas == null || container == null) {
      throw new Error();
    }
    let precanvasWitdhZoom =
      precanvas.clientWidth / (precanvas.clientWidth * zoom);
    precanvasWitdhZoom = precanvasWitdhZoom || 0;
    let precanvasHeightZoom =
      precanvas.clientHeight / (precanvas.clientHeight * zoom);
    precanvasHeightZoom = precanvasHeightZoom || 0;

    // search svg connection
    const elemsOut = document.querySelectorAll('[data-source="' + id + '"]');

    elemsOut.forEach(function (item, index) {
      if (item instanceof SVGElement) {
        // node with out connection
        const elemtsearchId_out = container.querySelector(`#node-${id}`);
        // node with in connection
        const elemtsearchId = container.querySelector(
          `#node-${item.dataset.target}`
        );
        if (elemtsearchId == null || elemtsearchId_out == null) {
          throw new Error();
        }

        const elemtsearch = elemtsearchId.querySelectorAll(
          '[data-input="' + item.dataset.input + '"]'
        )[0] as HTMLElement;
        console.log("Debug elemtsearch:", elemtsearch);
        const eX =
          elemtsearch.offsetWidth / 2 +
          (elemtsearch.getBoundingClientRect().x -
            precanvas.getBoundingClientRect().x) *
            precanvasWitdhZoom;
        const eY =
          elemtsearch.offsetHeight / 2 +
          (elemtsearch.getBoundingClientRect().y -
            precanvas.getBoundingClientRect().y) *
            precanvasHeightZoom;

        const elemtsearchOut = elemtsearchId_out.querySelectorAll(
          '[data-output="' + item.dataset.output + '"]'
        )[0] as HTMLElement;

        const line_x =
          elemtsearchOut.offsetWidth / 2 +
          (elemtsearchOut.getBoundingClientRect().x -
            precanvas.getBoundingClientRect().x) *
            precanvasWitdhZoom;
        const line_y =
          elemtsearchOut.offsetHeight / 2 +
          (elemtsearchOut.getBoundingClientRect().y -
            precanvas.getBoundingClientRect().y) *
            precanvasHeightZoom;

        const x = eX;
        const y = eY;

        const lineCurve = createCurvature(line_x, line_y, x, y, "openclose");
        item.children[0].setAttributeNS(null, "d", lineCurve);
      }
    });

    const elemsIn = document.querySelectorAll('[data-target="' + id + '"]');
    // target is current, source is the search node
    elemsIn.forEach(function (item, index) {
      if (item instanceof SVGElement) {
        // node with in connection
        let elemtsearchId_in = container.querySelector(
          `#node-${id}`
        ) as HTMLElement;
        // node with in out
        const elemtsearchId = container.querySelector(
          `#node-${item.dataset.source}`
        ) as HTMLElement;

        if (elemtsearchId == null || elemtsearchId_in == null) {
          throw new Error();
        }
        const elemtsearch = elemtsearchId.querySelectorAll(
          '[data-output="' + item.dataset.output + '"]'
        )[0] as HTMLElement;

        const line_x =
          elemtsearch.offsetWidth / 2 +
          (elemtsearch.getBoundingClientRect().x -
            precanvas.getBoundingClientRect().x) *
            precanvasWitdhZoom;
        const line_y =
          elemtsearch.offsetHeight / 2 +
          (elemtsearch.getBoundingClientRect().y -
            precanvas.getBoundingClientRect().y) *
            precanvasHeightZoom;

        elemtsearchId_in = elemtsearchId_in.querySelectorAll(
          '[data-input="' + item.dataset.input + '"]'
        )[0] as HTMLElement;

        const x =
          elemtsearchId_in.offsetWidth / 2 +
          (elemtsearchId_in.getBoundingClientRect().x -
            precanvas.getBoundingClientRect().x) *
            precanvasWitdhZoom;
        const y =
          elemtsearchId_in.offsetHeight / 2 +
          (elemtsearchId_in.getBoundingClientRect().y -
            precanvas.getBoundingClientRect().y) *
            precanvasHeightZoom;
        const lineCurve = createCurvature(line_x, line_y, x, y, "openclose");
        item.children[0].setAttributeNS(null, "d", lineCurve);
      }
    });
  }

  /* Events */
  public on(event: string | number, callback: any) {
    // Check if the callback is not a function
    if (typeof callback !== "function") {
      console.error(
        `The listener callback must be a function, the given type is ${typeof callback}`
      );
      return false;
    }
    // Check if the event is not a string
    if (typeof event !== "string") {
      console.error(
        `The event name must be a string, the given type is ${typeof event}`
      );
      return false;
    }
    // Check if this event not exists
    if (this.events[event] === undefined) {
      this.events[event] = {
        listeners: [],
      };
    }
    this.events[event].listeners.push(callback);
  }

  public removeListener(event: string | number, callback: any) {
    // Check if this event not exists

    if (!this.events[event]) return false;

    const listeners = this.events[event].listeners;
    const listenerIndex = listeners.indexOf(callback);
    const hasListener = listenerIndex > -1;
    if (hasListener) listeners.splice(listenerIndex, 1);
  }

  private dispatch(event: string, details: any) {
    // Check if this event not exists
    if (this.events[event] === undefined) {
      // console.error(`This event: ${event} does not exist`);
      return false;
    }
    this.events[event].listeners.forEach((listener: (arg0: any) => void) => {
      listener(details);
    });
  }

  // Méthode publique
  public greet(): string {
    return `Hello, my name`;
  }
}
