import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["innerHTML"]

import { HTTP } from "../http-common";
import { ref, onMounted } from "vue";

interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}

interface Event {
  id: number;
  description: string;
  storyId: number;
  content: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsView',
  props: {
    storyId: {}
  },
  setup(__props: any) {

const props = __props;

const data = ref<Event[]>([]);
const loading = ref(true);

const handleDelete = (index: number, eventId: number) => {
  HTTP.delete("/events/" + eventId).then(() => {
    loading.value = true;
    HTTP.get("events/story/" + props.storyId).then((response) => {
      data.value = response.data;
      loading.value = false;
    });
  });
};

onMounted(async () => {
  if (props.storyId) {
    try {
      loading.value = true;
      const response = await HTTP.get("events/story/" + props.storyId);
      data.value = response.data;
      loading.value = false;
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  } else {
    console.log("No param in url");
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Liste des évènements créés", -1)),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      (data.value.length == 0)
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 0,
            description: _ctx.$t('steps.empty')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'event', params: { storyId: props.storyId } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, { type: "primary" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Ajouter votre premier évènement")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }, 8, ["description"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_table, {
              data: data.value,
              style: {"width":"1400px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  fixed: "",
                  prop: "description",
                  label: "Description",
                  width: "150"
                }),
                _createVNode(_component_el_table_column, {
                  label: "Content",
                  width: "920"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", {
                      innerHTML: 
                scope.row.content.length > 200
                  ? scope.row.content.slice(0, 200) + '...'
                  : scope.row.content
              
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: "Operations",
                  width: "210"
                }, {
                  default: _withCtx((scope) => [
                    _createVNode(_component_router_link, {
                      to: {
                name: 'event',
                params: { storyId: props.storyId, eventId: scope.row.id },
              }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          size: "small"
                        }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("Edit")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (handleDelete(scope.$index, scope.row.id))
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Delete")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"])
          ]))
    ])), [
      [_directive_loading, loading.value]
    ])
  ], 64))
}
}

})