<template>
  <el-dialog
    v-if="registerOpen"
    :model-value="dialogVisible"
    title="Connexion / Enregistrement"
    :width="dialogSize"
    @close="closeDialog"
  >
    <span>{{ $t("mainmenu.popup.title.register") }}</span>
    <el-tabs v-model="activeName" class="demo-tabs" v-loading="loginLoading">
      <el-tab-pane label="Connexion" name="connect">
        <el-form
          :model="logtosite"
          label-width="120px"
          :label-position="activeName"
        >
          <el-form-item label="Login">
            <el-input v-model="logtosite.login" />
          </el-form-item>
          <el-form-item label="Mot de passe">
            <el-input
              v-model="logtosite.password"
              type="password"
              placeholder="Veuillez entrer le mot de passe"
              show-password
            />
          </el-form-item>
          <div>
            <el-button @click="closeDialog">Annuler</el-button>
            <el-button type="primary" @click="onLogin">Connexion</el-button>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="S'enregistrer" name="register">
        <el-form :model="register" label-width="120px">
          <el-form-item label="Login">
            <el-input v-model="register.Login" />
          </el-form-item>
          <el-form-item label="Mot de passe">
            <el-input
              v-model="register.Password"
              type="password"
              placeholder="Veuillez entrer le mot de passe"
              show-password
            />
          </el-form-item>
          <el-form-item label="Encore">
            <el-input
              v-model="register.Password2"
              type="password"
              placeholder="Veuillez répéter le mot de passe"
              show-password
            />
          </el-form-item>
          <el-form-item label="Prénom">
            <el-input v-model="register.Firstname" />
          </el-form-item>
          <el-form-item label="Nom">
            <el-input v-model="register.Lastname" />
          </el-form-item>
          <el-form-item label="Date de naissance">
            <el-date-picker
              v-model="register.Birthdate"
              type="date"
              placeholder="Choisissez une date"
              size="default"
              format="DD/MM/YYYY"
            />
          </el-form-item>
          <el-form-item label="Genre">
            <el-select v-model="register.Gender">
              <el-option label="Homme" value="0" default />
              <el-option label="Femme" value="1" />
              <el-option label="Autre" value="2" />
            </el-select>
          </el-form-item>
          <div>
            <el-button @click="closeDialog">Annuler</el-button>
            <el-button type="primary" @click="onRegister"
              >S'enregistrer</el-button
            >
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, computed, reactive, defineProps, defineEmits } from "vue";
import { HTTP } from "@/http-common";
import { useUserStore } from "@/store/user";
import { ElNotification } from "element-plus";

const userStore = useUserStore();
const user = computed(() => userStore.user);

const activeName = ref("connect");

// Props qui viennent du composant parent
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    required: true,
  },
  dialogSize: {
    type: String,
    default: "50%",
  },
  registerOpen: {
    type: Boolean,
    default: false,
  },
});
const loginLoading = ref(false);

// Événements à émettre vers le composant parent
const emit = defineEmits(["update:dialogVisible", "login", "register"]);

// Modèles locaux pour gérer l'état du formulaire
const logtosite = reactive({
  login: "",
  password: "",
});

const register = reactive({
  Login: "",
  Password: "",
  Password2: "",
  Firstname: "",
  Lastname: "",
  Birthdate: "",
  Gender: "0",
});

// Méthodes pour fermer la boîte de dialogue
const closeDialog = () => {
  emit("update:dialogVisible", false);
};

const onLogin = async () => {
  loginLoading.value = true;
  const success = await userStore.login(logtosite.login, logtosite.password);
  loginLoading.value = false;
  if (!success) {
    // Gérer l'erreur de connexion
    loginError();
  } else {
    emit("update:dialogVisible", false);
  }
  emit("login", logtosite);
};

const onRegister = async () => {
  if (!register.Password || register.Password.trim() === "") {
    ElNotification({
      title: "Mot de passe vide",
      message: "Merci de remplir le mot de passe",
      type: "warning",
      showClose: false,
    });
  } else if (!register.Password2 || register.Password2.trim() === "") {
    ElNotification({
      title: "Mot de passe vide",
      message: "Merci de saisir le second champ mot de passe",
      type: "warning",
      showClose: false,
    });
  } else if (register.Password2 != register.Password) {
    ElNotification({
      title: "Mots de passe différents",
      message:
        "Assurez vous que les deux mots de passe saisis soient identiques",
      type: "error",
      showClose: false,
    });
  } else if (
    !register.Password ||
    !register.Password2 ||
    !register.Lastname ||
    !register.Firstname ||
    !register.Login ||
    !register.Birthdate
  ) {
    ElNotification({
      title: "Merci de renseigner tous les champs",
      message:
        "Un ou plusieurs champs du formulaire n'ont pas été remplis. Assurez vosu de renseigner tous les champs",
      type: "error",
      showClose: false,
    });
  } else {
    var request = {
      login: register.Login,
      password: register.Password,
      birthdate: register.Birthdate,
      firstname: register.Firstname,
      lastname: register.Lastname,
      gender: +register.Gender,
    };
    HTTP.post("/users", request)
      .then(() => {
        ElNotification({
          title: "Merci de vous etre inscrit",
          message:
            "Un email vient de vous etre envoyé à l'adresse renseignée, vous y trouverez le lien pour activer votre compte",
          type: "info",
          showClose: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          ElNotification({
            title: "Une erreur est survenue",
            message:
              "Votre inscription a échouée. Si un compte existe déjà avec votre email, essayer de modifier votre mot de passe",
            type: "info",
            showClose: false,
          });
        }
      });
  }
  emit("register", register);
};

const loginError = () => {
  ElNotification({
    title: "Erreur de connexion",
    message:
      "Vos données de connexion sont erronées. Merci d'essayer de nouveau ou de créer un compte.",
    type: "error",
    showClose: false,
  });
};
</script>

<style scoped>
/* Ajoutez du style personnalisé si nécessaire */
</style>
