<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.size"
    :height="props.size"
    :fill="props.color"
    viewBox="0 0 24 24"
  >
    <path
      d="M3,6 C3,5.44771525 3.44771525,5 4,5 L5,5 C5.55228475,5 6,5.44771525 6,6 L6,7 C6,7.55228475 5.55228475,8 5,8 L4,8 C3.44771525,8 3,7.55228475 3,7 L3,6 Z M8.5,7 C8.22385763,7 8,6.77614237 8,6.5 C8,6.22385763 8.22385763,6 8.5,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L8.5,7 Z M3,11 C3,10.4477153 3.44771525,10 4,10 L5,10 C5.55228475,10 6,10.4477153 6,11 L6,12 C6,12.5522847 5.55228475,13 5,13 L4,13 C3.44771525,13 3,12.5522847 3,12 L3,11 Z M8.5,12 C8.22385763,12 8,11.7761424 8,11.5 C8,11.2238576 8.22385763,11 8.5,11 L20.5,11 C20.7761424,11 21,11.2238576 21,11.5 C21,11.7761424 20.7761424,12 20.5,12 L8.5,12 Z M3,16 C3,15.4477153 3.44771525,15 4,15 L5,15 C5.55228475,15 6,15.4477153 6,16 L6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,16 Z M4,6 L4,7 L5,7 L5,6 L4,6 Z M4,11 L4,12 L5,12 L5,11 L4,11 Z M4,16 L4,17 L5,17 L5,16 L4,16 Z M8.5,17 C8.22385763,17 8,16.7761424 8,16.5 C8,16.2238576 8.22385763,16 8.5,16 L20.5,16 C20.7761424,16 21,16.2238576 21,16.5 C21,16.7761424 20.7761424,17 20.5,17 L8.5,17 Z"
    />
  </svg>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  size: { type: Number, default: 24 },
  color: { type: String, default: "currentColor" },
});
</script>

<style scoped>
/* Styles spécifiques à votre icône SVG */
</style>
