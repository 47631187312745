import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "first-slide" }

import { onMounted } from "vue";
import { HTTP } from "@/http-common";

interface ActivationProps {
  activationKey: string | null; // Par exemple, l'id peut être une chaîne ou null
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ActivationAccount',
  props: {
    activationKey: {}
  },
  setup(__props: any) {

const props = __props;

onMounted(async () => {
  // Écoutez les événements de sélection de l'éditeur
  HTTP.put("users/activation/", {
    activationKey: props.activationKey,
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, " Merci d'avoir activé votre compte : " + _toDisplayString(props.activationKey), 1))
}
}

})