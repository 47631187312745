<template>
  <div ref="chart"></div>
  <d3-network
    :net-nodes="nodes"
    :net-links="links"
    :options="options"
    @node-click="handleNodeClick"
  >
  </d3-network>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue";
import D3Network from "vue3-d3-network";
const nodes = ref([
  { id: 1, name: "Description", _cssClass: "mainnode", _svgAttrs: { Id: 1 } },
  { id: 2, name: "test de l'envoi de mails", _svgAttrs: { Id: 2 } },
  { id: 3, name: "3 eme rang 1", _svgAttrs: { Id: 3 } },
  { id: 4, name: "3 eme rang etape 2", _svgAttrs: { Id: 4 } },
]);
const links = ref([
  { sid: 1, tid: 2, _color: "black" },
  { sid: 2, tid: 3, _color: "black" },
  { sid: 2, tid: 4, _color: "black" },
]);
const options = ref({
  force: 3000,
  nodeSize: 20,
  nodeLabels: true,
  linkWidth: 5,
});

function handleNodeClick(node) {
  console.log("Node clicked:", node);
  console.log("Node id : ", node.srcElement.attributes["Id"].value);
  // Ajoutez ici votre logique de gestion des clics
}
/* import * as d3 from "d3";

// Référence à l'élément DOM du graphique
const chart = ref(null);

const drawChart = () => {
  const data = [10, 20, 30, 40, 50];

  // Sélection de l'élément DOM et création d'un graphique
  const svg = d3
    .select(chart.value)
    .append("svg")
    .attr("width", 500)
    .attr("height", 500);

  svg
    .selectAll("circle")
    .data(data)
    .enter()
    .append("circle")
    .attr("cx", (d, i) => i * 100 + 50)
    .attr("cy", 250)
    .attr("r", (d) => d)
    .attr("fill", "blue");
};

onMounted(() => {
  drawChart();
});

onUnmounted(() => {
  // Nettoyage si nécessaire
  d3.select(chart.value).selectAll("*").remove();
}); */
</script>
<style lang="scss">
.node {
  -webkit-transition: fill 0.5s ease;
  fill: #dcfaf3;
  stroke: rgba(18, 120, 98, 0.7);
  stroke-width: 3px;
  transition: fill 0.5s ease;
}

.node:hover {
  stroke: red;
}

.mainnode {
  -webkit-transition: fill 0.5s ease;
  fill: orange;
  stroke: darkorange;
  stroke-width: 3px;
  transition: fill 0.5s ease;
}
</style>
