import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "chart" }

import { ref, onMounted, onUnmounted } from "vue";
import D3Network from "vue3-d3-network";

export default /*@__PURE__*/_defineComponent({
  __name: 'TestView',
  setup(__props) {

const nodes = ref([
  { id: 1, name: "Description", _cssClass: "mainnode", _svgAttrs: { Id: 1 } },
  { id: 2, name: "test de l'envoi de mails", _svgAttrs: { Id: 2 } },
  { id: 3, name: "3 eme rang 1", _svgAttrs: { Id: 3 } },
  { id: 4, name: "3 eme rang etape 2", _svgAttrs: { Id: 4 } },
]);
const links = ref([
  { sid: 1, tid: 2, _color: "black" },
  { sid: 2, tid: 3, _color: "black" },
  { sid: 2, tid: 4, _color: "black" },
]);
const options = ref({
  force: 3000,
  nodeSize: 20,
  nodeLabels: true,
  linkWidth: 5,
});

function handleNodeClick(node) {
  console.log("Node clicked:", node);
  console.log("Node id : ", node.srcElement.attributes["Id"].value);
  // Ajoutez ici votre logique de gestion des clics
}
/* import * as d3 from "d3";

// Référence à l'élément DOM du graphique
const chart = ref(null);

const drawChart = () => {
  const data = [10, 20, 30, 40, 50];

  // Sélection de l'élément DOM et création d'un graphique
  const svg = d3
    .select(chart.value)
    .append("svg")
    .attr("width", 500)
    .attr("height", 500);

  svg
    .selectAll("circle")
    .data(data)
    .enter()
    .append("circle")
    .attr("cx", (d, i) => i * 100 + 50)
    .attr("cy", 250)
    .attr("r", (d) => d)
    .attr("fill", "blue");
};

onMounted(() => {
  drawChart();
});

onUnmounted(() => {
  // Nettoyage si nécessaire
  d3.select(chart.value).selectAll("*").remove();
}); */

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createVNode(_unref(D3Network), {
      "net-nodes": nodes.value,
      "net-links": links.value,
      options: options.value,
      onNodeClick: handleNodeClick
    }, null, 8, ["net-nodes", "net-links", "options"])
  ], 64))
}
}

})