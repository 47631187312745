import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-right" }
const _hoisted_2 = { class: "text-left" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-justify" }
const _hoisted_5 = { class: "warn-message" }

import { reactive, ref, onMounted } from "vue";
import { HTTP } from "../../http-common";
import { ElNotification } from "element-plus";
import {
  Delete,
  Plus,
  Setting,
  Operation,
  Open,
  CirclePlus,
  PieChart,
} from "@element-plus/icons-vue";
import router from "../../router";
import { useI18n } from "vue-i18n";

interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}
interface SharedEmail {
  name: string;
  type: string | "success";
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StoryComponent',
  props: {
    storyId: {}
  },
  setup(__props: any) {

const createMode = ref(false);
const newTag = ref("");

const props = __props;

// do not use same name with ref
const form = reactive({
  name: "",
  region: "",
  date1: "",
  date2: "",
  delivery: false,
  type: [],
  resource: "",
  desc: "",
});

const activeTab = ref("0");
const loading = ref(false);

const tabs = reactive([
  {
    lang: "en",
    title: "",
    description: "",
    publish: "Draft",
    sharedEmails: [] as SharedEmail[],
  },
]);

const isInputTagValid = () => {
  if (newTag.value != "") {
    return true;
  }
  return false;
};

const addEmailToShare = (targetLang: string) => {
  const foundItem = tabs.find((item) => item.lang === targetLang);
  if (foundItem) {
    foundItem.sharedEmails.push({
      name: newTag.value,
      type: "success",
    });
  }
  newTag.value = "";
};

const addTab = () => {
  const newTabIndex = tabs.length;
  const newTab = {
    // lang: `Onglet ${newTabIndex + 1}`,
    lang: "en",
    title: "",
    description: "",
    publish: "Draft",
    sharedEmails: [],
  };
  tabs.push(newTab);
  activeTab.value = newTabIndex.toString();
};

const delTab = (index: number) => {
  if (tabs.length > 1) {
    tabs.splice(index, 1);
    // Sélectionnez le dernier onglet si l'onglet actif est supprimé
    activeTab.value = Math.min(index, tabs.length - 1).toString();
  }
};

function convertTabsToFormat() {
  return tabs.map((tab) => {
    return {
      langCode: tab.lang,
      title: tab.title,
      description: tab.description,
      state: tab.publish,
      sharedEmails: tab.sharedEmails.map(
        (email: { name: string }) => email.name
      ),
    };
  });
}

function convertResponseToTabs(response: { languages: string | any[] }) {
  tabs.splice(0, tabs.length);
  for (var i = 0; i < response.languages.length; i++) {
    var item = response.languages[i];
    tabs.push({
      lang: item.langCode,
      title: item.title,
      description: item.description,
      publish: item.state, // TODO à modifier
      sharedEmails: item.sharedEmails.map((name: string) => ({
        name: name,
        type: "success",
      })),
    });
  }
}

function handleSuccess(redirectRoute: string) {
  loading.value = false;
  router.push({ name: redirectRoute });
}
const { t } = useI18n();
function handleError(error: any, title: string, message: string) {
  loading.value = false;
  if (
    error.response &&
    (error.response.status == 401 || error.response.status == 403)
  ) {
    // Notification en cas d'erreur (droits insuffisants)
    ElNotification({
      title: title,
      message: message,
      type: "error",
      showClose: false,
    });
  } else if (error.response && error.response.status == 404) {
    // Notification en cas d'erreur (droits insuffisants)
    ElNotification({
      title: title,
      message: t("story.error.notfound"),
      type: "error",
      showClose: false,
    });
  }
}

function convertToQuery(id: string | null) {
  if (id != null) {
    return {
      id: id,
      name: form.name,
      languages: convertTabsToFormat(),
    };
  }
  return {
    name: form.name,
    languages: convertTabsToFormat(),
  };
}

function createStory() {
  const queryObject = convertToQuery(null);
  loading.value = true;
  HTTP.post("author/stories", queryObject)
    .then(() => {
      handleSuccess("authorStories");
    })
    .catch(function (error) {
      handleError(
        error,
        t("story.error.create.title"),
        t("story.error.create.message")
      );
    });
}

function updateStory() {
  loading.value = true;
  var queryObject = convertToQuery(props.storyId);
  HTTP.put("author/stories", queryObject)
    .then(() => {
      handleSuccess("authorStories");
    })
    .catch(function (error) {
      handleError(
        error,
        t("story.error.update.title"),
        t("story.error.update.message")
      );
    });
}

const onCancel = () => {
  router.push({ name: "authorStories" });
};

const onSubmit = () => {
  if (createMode.value) {
    createStory();
  } else {
    updateStory();
  }
};

onMounted(async () => {
  if (props.storyId) {
    loading.value = true;
    const response = await HTTP.get("author/stories/" + props.storyId);
    form.name = response.data.name;
    convertResponseToTabs(response.data);
    loading.value = false;
  } else {
    createMode.value = true;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_form, {
    model: form,
    "label-width": "180px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: _ctx.$t('story.name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.name) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: onSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(createMode.value ? _ctx.$t("story.create") : _ctx.$t("story.update")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { onClick: onCancel }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("global.action.cancel")), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_el_tabs, { type: "border-card" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, null, {
            label: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "title-icon" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Setting))
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("story.general.title")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('story.general.addlangage')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    onClick: addTab,
                    type: "primary",
                    icon: _unref(Plus),
                    circle: ""
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_tabs, {
                "tab-position": "left",
                style: {"min-height":"350px"},
                modelValue: activeTab.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((activeTab).value = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs, (tab, index) => {
                    return (_openBlock(), _createBlock(_component_el_tab_pane, {
                      label: tab.lang,
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_el_button, {
                            onClick: ($event: any) => (delTab(index)),
                            type: "danger",
                            icon: _unref(Delete),
                            round: "",
                            disabled: tabs.length === 1
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("story.general.deletelangage")), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick", "icon", "disabled"])
                        ]),
                        _createVNode(_component_el_form, {
                          model: tab,
                          "label-width": "120px"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: _ctx.$t('story.general.langage')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  modelValue: tab.lang,
                                  "onUpdate:modelValue": ($event: any) => ((tab.lang) = $event),
                                  maxlength: "2"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]),
                            _createVNode(_component_el_form_item, {
                              label: _ctx.$t('story.general.translated')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  modelValue: tab.title,
                                  "onUpdate:modelValue": ($event: any) => ((tab.title) = $event),
                                  maxlength: "100",
                                  "show-word-limit": ""
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]),
                            _createVNode(_component_el_form_item, {
                              label: _ctx.$t('story.general.description')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  modelValue: tab.description,
                                  "onUpdate:modelValue": ($event: any) => ((tab.description) = $event),
                                  rows: 4,
                                  type: "textarea",
                                  placeholder: "Please add a description"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]),
                            _createVNode(_component_el_form_item, {
                              label: _ctx.$t('story.publish.state.title')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_radio_group, {
                                  modelValue: tab.publish,
                                  "onUpdate:modelValue": ($event: any) => ((tab.publish) = $event)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_radio_button, { value: "Draft" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("story.publish.state.private")), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_radio_button, { value: "Restricted" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("story.publish.state.shared")), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_radio_button, { value: "Published" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("story.publish.state.public")), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]),
                            (tab.publish == 'Restricted')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("story.publish.share.title")), 1),
                                  _createVNode(_component_el_row, { class: "shared-users" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_col, { span: 8 }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_form_item, { "label-position": "top" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_input, {
                                                modelValue: newTag.value,
                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newTag).value = $event)),
                                                style: {"width":"95%","margin-left":"10px","margin-right":"10px"},
                                                placeholder: _ctx.$t('story.publish.share.input')
                                              }, {
                                                append: _withCtx(() => [
                                                  _createVNode(_component_el_button, {
                                                    icon: _unref(CirclePlus),
                                                    onClick: ($event: any) => (addEmailToShare(tab.lang)),
                                                    disabled: !isInputTagValid()
                                                  }, null, 8, ["icon", "onClick", "disabled"])
                                                ]),
                                                _: 2
                                              }, 1032, ["modelValue", "placeholder"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("story.publish.share.info")), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_el_col, {
                                        span: 16,
                                        class: "text-justify"
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tab.sharedEmails, (email) => {
                                            return (_openBlock(), _createBlock(_component_el_tag, {
                                              key: email.name,
                                              closable: "",
                                              type: email.type,
                                              round: "",
                                              onClose: _cache[2] || (_cache[2] = ($event: any) => (console.log('close tab')))
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(email.name), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["type"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["model"])
                      ]),
                      _: 2
                    }, 1032, ["label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, { disabled: "" }, {
            label: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "title-icon" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Operation))
                ]),
                _: 1
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("story.parameters.title")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                model: form,
                "label-width": "auto",
                style: {"max-width":"600px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { label: "Instant delivery" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_switch)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, { disabled: "" }, {
            label: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "title-icon" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(PieChart))
                ]),
                _: 1
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("story.dashboard.title")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"])), [
    [_directive_loading, loading.value]
  ])
}
}

})