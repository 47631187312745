<template>
  <div class="header">{{ title }}</div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ["title"],
});
</script>

<style scoped>
div {
  height: 25px;
  background: #494949;
  padding: 5px 5px;
  /* margin-bottom: 10px; */
}
</style>
