import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "first-slide" }
const _hoisted_2 = { class: "story-list" }
const _hoisted_3 = { class: "card-header text-left" }
const _hoisted_4 = { class: "text item" }
const _hoisted_5 = { class: "card-footer text-right" }

import { ref, onMounted } from "vue";
import { HTTP } from "@/http-common";
import router from "@/router";

interface reading {
  name: string;
  storyId: number;
  description: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SharedStoriesView',
  setup(__props) {

const loading = ref(false);
const readings = ref<reading[]>([]);

const startReading = (id: number) => {
  HTTP.post("readings", {
    userId: 0,
    storyId: id,
  }).then(() => {
    HTTP.post("read", {
      storyId: id,
    }).then(() => {
      router.push({ name: "reader", params: { storyId: id } });
    });
  });
};

onMounted(() => {
  loading.value = true;
  HTTP.get("/shared-stories").then((response) => {
    console.log(response);
    for (var i = 0; i < response.data.length; i++) {
      readings.value.push({
        name: response.data[i].languages[0].title,
        storyId: response.data[i].id,
        description: response.data[i].languages[0].description,
      });
    }
    loading.value = true;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_loaging = _resolveDirective("loaging")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t("shared.title.main")), 1),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      (readings.value.length == 0)
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 0,
            description: "Vous n'avez pas d'invitations en cours"
          }))
        : (_openBlock(), _createBlock(_component_el_row, {
            key: 1,
            gutter: 30
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(readings.value, (reading, index) => {
                return (_openBlock(), _createBlock(_component_el_col, {
                  span: 8,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_card, { class: "full-height" }, {
                      header: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("span", null, _toDisplayString(reading.name), 1)
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_4, _toDisplayString(reading.description), 1),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_el_button, {
                            onClick: ($event: any) => (startReading(reading.storyId))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("catalog.button.startReading")), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
    ])), [
      [_directive_loaging, loading.value]
    ])
  ]))
}
}

})