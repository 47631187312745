import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "editor-class" }
const _hoisted_2 = { class: "parent-body" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "event-body" }
const _hoisted_5 = {
  style: {"text-align":"right"},
  class: "button-bar"
}
const _hoisted_6 = { class: "my-header" }
const _hoisted_7 = ["id"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "dialog-footer" }

import { onMounted, ref } from "vue";
import TextEditor from "../components/TextEditor.vue";
import { HTTP } from "../http-common";
import router from "../router";
import { ElNotification } from "element-plus";
import { onBeforeRouteUpdate } from "vue-router";
import { QuestionFilled, Check, Close } from "@element-plus/icons-vue";

interface StepProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
  stepId: string | null;
}

type Option = {
  id: number;
  label: string;
};

interface parentNode {
  id: number;
  link: string;
  desc: string;
}

interface eventNode {
  id: number;
  description: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HandleStepView',
  props: {
    storyId: {},
    stepId: {}
  },
  setup(__props: any) {

const props = __props;
const previewDialog = ref(false);
const isHead = ref(false);
const description = ref("");
const preview = ref("");

const loading = ref(false);
const parentValue = ref<Option>();
const parentOptions = ref<Option[]>([]);
const parentNodes = ref<parentNode[]>([]);
const editor = ref<typeof TextEditor>();

const eventValue = ref<Option>();
const eventOptions = ref<Option[]>([]);
const eventNodes = ref<eventNode[]>([]);

function deleteParent(linkId: number) {
  const indexToRemove = parentNodes.value.findIndex(
    (element) => element.id === linkId
  );
  if (indexToRemove !== -1) {
    parentNodes.value.splice(indexToRemove, 1);
  }
}

function deleteEvent(linkId: number) {
  const indexToRemove = eventNodes.value.findIndex(
    (element) => element.id === linkId
  );
  if (indexToRemove !== -1) {
    eventNodes.value.splice(indexToRemove, 1);
  }
}

function validate() {
  var request = {
    id: 0,
    storyId: props.storyId,
    content: editor.value?.getText(),
    isHead: isHead.value,
    description: description.value,
    forceHeadValue: true,
    parentLinks: parentNodes.value.map((node) => {
      return {
        previousStepId: node.id,
        description: node.link,
      };
    }),
    linkEvents: eventNodes.value.map((node) => {
      return {
        eventId: node.id,
      };
    }),
  };
  if (props.stepId) {
    request.id = +props.stepId;
  }
  if (props.stepId) {
    HTTP.put("stories/" + props.storyId + "/steps", request)
      .then((response) => {
        router.push({ name: "steps", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          ElNotification({
            title: "Echec de la mise à jour de l'étape",
            message:
              "Une erreur est survenue lors de la mise à jour de votre étape",
            type: "error",
            showClose: false,
          });
        }
      });
  } else {
    HTTP.post("stories/" + props.storyId + "/steps", request)
      .then((response) => {
        router.push({ name: "steps", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          ElNotification({
            title: "Echec de la création de l'étape",
            message:
              "Une erreur est survenue lors de la création de votre étape",
            type: "error",
            showClose: false,
          });
        }
      });
  }
}

const previewText = () => {
  var request = {
    textToPreview: editor.value?.getText(),
  };
  HTTP.post("preview/step?storyId=" + props.storyId, request).then(
    (response) => {
      preview.value = response.data.renderedText;
    }
  );
  //preview.value = editor.value?.getText();
  //preview.value = preview.value.replace("{{opt1}}", "Paul");
  previewDialog.value = true;
};

const addParent = () => {
  const containsId5 = parentNodes.value.some(
    (node) => node.id === parentValue.value?.id
  );
  if (!containsId5) {
    parentNodes.value.push({
      id: parentValue.value?.id ?? 0,
      link: "",
      desc: parentValue.value?.label ?? "",
    });
  }
  parentValue.value = undefined;
};

const addEvent = () => {
  const containsId5 = eventNodes.value.some(
    (node) => node.id === eventValue.value?.id
  );
  if (!containsId5) {
    eventNodes.value.push({
      id: eventValue.value?.id ?? 0,
      description: eventValue.value?.label ?? "",
    });
  }
  eventValue.value = undefined;
};

onMounted(async () => {
  // Écoutez les événements de sélection de l'éditeur
  HTTP.get("stories/" + props.storyId + "/steps").then((response) => {
    for (var i = 0; i < response.data.length; i++) {
      var item = response.data[i];
      parentOptions.value.push({
        id: item.id,
        label: item.description,
      });
    }
  });
  HTTP.get("events/story/" + props.storyId).then((response) => {
    for (var i = 0; i < response.data.length; i++) {
      var item = response.data[i];
      eventOptions.value.push({
        id: item.id,
        label: item.description,
      });
    }
  });
  if (props.stepId) {
    loading.value = true;
    const response = await HTTP.get(
      "stories/" + props.storyId + "/steps/" + props.stepId
    );
    isHead.value = response.data.isHead;
    description.value = response.data.description;
    if (response.data.parentLinks) {
      for (var i = 0; i < response.data.parentLinks.length; i++) {
        parentNodes.value.push({
          id: response.data.parentLinks[i].previousStepId,
          link: response.data.parentLinks[i].description,
          desc: "",
        });
      }
      for (var j = 0; j < response.data.linkEvents.length; j++) {
        const indexToRead = eventOptions.value.findIndex(
          (element) => element.id === response.data.linkEvents[j].eventId
        );
        var element = eventOptions.value[indexToRead];
        eventNodes.value.push({
          id: response.data.linkEvents[j].eventId,
          description: element.label,
        });
      }
    }

    editor.value?.setHTML(response.data.content);
    loading.value = false;
  }
});

onBeforeRouteUpdate((to, from) => {
  // Vérifiez si le chemin de l'URL a changé pour /steps
  // console.log("Page change", to);
  // if (to.name === from.name) {
  //   window.location.reload(); // Actualisez la page
  // }
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("steps.title.create")), 1),
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("steps.subtitle.description")), 1),
    _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: description.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((description).value = $event)),
          rows: 2,
          type: "textarea",
          placeholder: _ctx.$t('steps.placeholder.description')
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_collapse, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_collapse_item, {
          name: "1",
          style: {"background-color":"red !important"}
        }, {
          title: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("steps.subtitle.settings")), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("steps.subtitle.parents")), 1),
                    _createVNode(_component_el_select, {
                      modelValue: parentValue.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((parentValue).value = $event)),
                      "value-key": "id",
                      placeholder: "Select",
                      style: {"width":"240px"},
                      onChange: _cache[2] || (_cache[2] = ($event: any) => (addParent())),
                      disabled: isHead.value
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parentOptions.value, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.id,
                            label: item.label,
                            value: item
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "disabled"]),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parentNodes.value, (item) => {
                          return (_openBlock(), _createBlock(_component_el_col, {
                            span: 6,
                            key: item.id,
                            class: "card"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, [
                                _createElementVNode("h5", null, [
                                  _createVNode(_component_el_tooltip, {
                                    content: item.desc,
                                    placement: "bottom",
                                    effect: "light"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.desc.length > 14
                          ? item.desc.slice(0, 14) + "..."
                          : item.desc), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["content"])
                                ]),
                                _createElementVNode("div", null, [
                                  _createVNode(_component_el_input, {
                                    modelValue: item.link,
                                    "onUpdate:modelValue": ($event: any) => ((item.link) = $event),
                                    placeholder: _ctx.$t('steps.parent.link')
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                                ]),
                                _createVNode(_component_el_button, {
                                  icon: _unref(Close),
                                  size: "small",
                                  circle: "",
                                  onClick: ($event: any) => (deleteParent(item.id))
                                }, null, 8, ["icon", "onClick"])
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("h4", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t("steps.subtitle.events")) + " ", 1),
                      _createVNode(_component_el_tooltip, {
                        placement: "top",
                        effect: "light"
                      }, {
                        content: _withCtx(() => [
                          _createElementVNode("span", {
                            innerHTML: _ctx.$t('steps.help.events')
                          }, null, 8, _hoisted_3)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(QuestionFilled))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_el_select, {
                      modelValue: eventValue.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((eventValue).value = $event)),
                      "value-key": "id",
                      placeholder: "Select",
                      style: {"width":"240px"},
                      onChange: _cache[4] || (_cache[4] = ($event: any) => (addEvent()))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(eventOptions.value, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.id,
                            label: item.label,
                            value: item
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(eventNodes.value, (item) => {
                          return (_openBlock(), _createBlock(_component_el_col, {
                            span: 6,
                            key: item.id,
                            class: "card"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("h5", null, _toDisplayString(item.description), 1),
                                _createElementVNode("div", null, [
                                  _createVNode(_component_el_input, { placeholder: "Futur trigger" })
                                ]),
                                _createVNode(_component_el_button, {
                                  icon: _unref(Close),
                                  size: "small",
                                  circle: "",
                                  onClick: ($event: any) => (deleteEvent(item.id))
                                }, null, 8, ["icon", "onClick"])
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("h4", null, [
              _cache[8] || (_cache[8] = _createTextVNode(" Est-ce l'élément de départ de votre histoire ? ")),
              _createVNode(_component_el_switch, {
                modelValue: isHead.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isHead).value = $event)),
                class: "mt-2",
                style: {"margin-left":"24px"},
                "inline-prompt": "",
                "active-icon": _unref(Check),
                "inactive-icon": _unref(Close)
              }, null, 8, ["modelValue", "active-icon", "inactive-icon"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("steps.subtitle.texteditor")), 1),
    _createVNode(TextEditor, {
      ref_key: "editor",
      ref: editor
    }, null, 512),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_el_button, { type: "cancel" }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("Annuler")
        ])),
        _: 1
      }),
      _createVNode(_component_el_tooltip, {
        content: "Will allow you to see result as reader",
        placement: "bottom",
        effect: "light"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            color: "#8a2be2",
            onClick: previewText
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("Previsualiser")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_button, {
        type: "success",
        onClick: validate
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.stepId ? "Mettre à jour" : "Créer"), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: previewDialog.value,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((previewDialog).value = $event)),
      width: "70%",
      "align-center": ""
    }, {
      header: _withCtx(({ titleId, titleClass }) => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h4", {
            id: titleId,
            class: _normalizeClass(titleClass)
          }, _toDisplayString(_ctx.$t("steps.title.preview")), 11, _hoisted_7)
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_el_button, {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (previewDialog.value = false))
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Femer")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", { innerHTML: preview.value }, null, 8, _hoisted_8)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ])), [
    [_directive_loading, loading.value]
  ])
}
}

})