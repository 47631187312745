<template>
  <div class="first-slide">
    <h2>{{ $t("readings.title.main") }}</h2>
    <div v-loaging="loading">
      <el-row :gutter="30">
        <el-col :span="8" v-for="(reading, index) in readings" :key="index">
          <el-card class="full-height">
            <template #header>
              <div class="card-header text-left">
                <span>{{ reading.name }}</span>
              </div>
            </template>
            <p class="text item">
              Lecture debutée : {{ reading.startReading.toLocaleDateString() }}
            </p>
            <div class="card-footer text-right">
              <router-link
                :to="{ name: 'reader', params: { storyId: reading.storyId } }"
              >
                <el-button type="primary">{{
                  $t("readings.followup")
                }}</el-button>
              </router-link>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { HTTP } from "@/http-common";

interface reading {
  name: string;
  storyId: number;
  startReading: Date;
}

const loading = ref(false);
const readings = ref<reading[]>([]);

onMounted(() => {
  loading.value = true;
  HTTP.get("readings").then((response) => {
    for (var i = 0; i < response.data.length; i++) {
      readings.value.push({
        name: response.data[i].story.languages[0].title,
        storyId: response.data[i].story.id,
        startReading: new Date(response.data[i].startReadingTime),
      });
    }
    console.log(readings.value);
    loading.value = true;
  });
});
</script>
