import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "first-slide" }
const _hoisted_2 = { class: "userData" }
const _hoisted_3 = { class: "text-right" }

import { onMounted, computed, reactive, ref } from "vue";
import { HTTP } from "@/http-common";
import { useUserStore } from "@/store/user";
import { UserFilled } from "@element-plus/icons-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileView',
  setup(__props) {

const loading = ref(true);
const userStore = useUserStore();
const user = computed(() => userStore.user);

const form = reactive({
  id: 0,
  email: "",
  firstname: "",
  lastname: "",
  birthdate: "",
  gender: "0",
});

const onSubmit = () => {
  loading.value = true;
  HTTP.put("own", {
    id: form.id,
    login: form.email,
    birthdate: form.birthdate,
    firstname: form.firstname,
    lastname: form.lastname,
    gender: +form.gender,
  }).then(() => {
    window.location.reload();
  });
};

// HTTP.get("users/"+user.)

onMounted(async () => {
  // Écoutez les événements de sélection de l'éditeur
  HTTP.get("own").then((response) => {
    form.id = response.data.id;
    form.firstname = response.data.firstname;
    form.lastname = response.data.lastname;
    form.birthdate = response.data.birthdate;
    form.gender = response.data.gender + "";
    form.email = response.data.login;
    loading.value = false;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("profile.title.main")), 1),
    _createElementVNode("div", null, [
      _createVNode(_component_el_avatar, { icon: _unref(UserFilled) }, null, 8, ["icon"]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_el_form, {
          model: form,
          "label-width": "auto",
          style: {"max-width":"600px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('profile.title.login')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: form.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.email) = $event)),
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('profile.title.firstname')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: form.firstname,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.firstname) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('profile.title.lastname')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: form.lastname,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.lastname) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('profile.title.birthdate')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 11 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      modelValue: form.birthdate,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.birthdate) = $event)),
                      type: "date",
                      style: {"width":"100%"},
                      disabled: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('profile.title.gender')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: form.gender,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.gender) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: _ctx.$t('profile.gender.male'),
                      value: "0"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_option, {
                      label: _ctx.$t('profile.gender.female'),
                      value: "1"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_option, {
                      label: _ctx.$t('profile.gender.neutral'),
                      value: "2"
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: onSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("profile.button.update")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ])), [
        [_directive_loading, loading.value]
      ])
    ])
  ]))
}
}

})