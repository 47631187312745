<template>
  <h1>Liste des étapes</h1>
  <div v-loading="loading">
    <el-empty :description="$t('steps.empty')" v-if="data.length == 0">
      <router-link :to="{ name: 'step', params: { storyId: props.storyId } }">
        <el-button type="primary">Ajouter votre premiere étape</el-button>
      </router-link>
    </el-empty>
    <div v-else>
      <el-table :data="data" style="width: 1400px; z-index: 0">
        <el-table-column
          fixed
          prop="description"
          label="Description"
          width="150"
        >
          <template #default="scope">
            <svg
              v-if="scope.row.isHead"
              width="16px"
              height="16px"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
            >
              <g>
                <path
                  fill="green"
                  d="M8 2.5a5.494 5.494 0 00-4.558 2.42.75.75 0 01-1.242-.84 7 7 0 110 7.841.75.75 0 111.242-.841A5.5 5.5 0 108 2.5z"
                />
                <path
                  fill="red"
                  d="M7.245 4.695a.75.75 0 00-.05 1.06l1.36 1.495H1.75a.75.75 0 000 1.5h6.805l-1.36 1.495a.75.75 0 001.11 1.01l2.5-2.75a.75.75 0 000-1.01l-2.5-2.75a.75.75 0 00-1.06-.05z"
                />
              </g>
            </svg>
            {{ scope.row.description }}
          </template>
        </el-table-column>
        <el-table-column label="Content" width="920">
          <template #default="scope">
            <div
              v-html="
                scope.row.content.length > 200
                  ? scope.row.content.slice(0, 200) + '...'
                  : scope.row.content
              "
            ></div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Operations" width="180">
          <template #default="scope">
            <router-link
              :to="{
                name: 'step',
                params: { storyId: props.storyId, stepId: scope.row.id },
              }"
            >
              <el-button type="primary" size="small">{{
                $t("steps.buttons.edit")
              }}</el-button>
            </router-link>
            <el-button
              type="danger"
              size="small"
              @click="handleDelete(scope.$index, scope.row.id)"
              >{{ $t("steps.buttons.delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { HTTP } from "@/http-common";
import { ref, onMounted, defineProps } from "vue";

interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}

const props = defineProps<StoryProps>();

interface Step {
  id: number;
  description: string;
  storyId: number;
  content: string;
  isHead: boolean;
}

const data = ref<Step[]>([]);
const loading = ref(true);

const handleDelete = (index: number, stepId: number) => {
  console.log(index, stepId);
  HTTP.delete("stories/" + props.storyId + "/steps/" + stepId).then(
    (deleteResponse) => {
      console.log(deleteResponse);
      loading.value = true;
      HTTP.get("stories/" + props.storyId + "/steps").then((response) => {
        data.value = response.data;
        loading.value = false;
      });
    }
  );
};

onMounted(async () => {
  if (props.storyId) {
    try {
      loading.value = true;
      const response = await HTTP.get("stories/" + props.storyId + "/steps");
      data.value = response.data;
      loading.value = false;
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  } else {
    console.log("No param in url");
  }
});
</script>
<style lang="scss"></style>
