import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import StoryComponent from "@/components/writer/StoryComponent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateStoryView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t("story.title.create")), 1),
    _createVNode(StoryComponent)
  ], 64))
}
}

})