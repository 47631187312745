import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

import { ref, onMounted } from "vue";
import { HTTP } from "@/http-common";
import router from "@/router";

interface EventProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
  eventId: string | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateVariablesView',
  props: {
    storyId: {},
    eventId: {}
  },
  setup(__props: any) {

const value = ref<number>();
const name = ref("");
const variableValue = ref("");
const listValues = ref([]);
const loading = ref(false);

const props = __props;

const options = [
  {
    value: 1,
    label: "Libre",
  },
  {
    value: 2,
    label: "Oui/Non",
  },
  {
    value: 3,
    label: "Liste de valeurs",
  },
];

const isFree = () => {
  if (value.value == 1) {
    return true;
  }
  return false;
};

const isList = () => {
  if (value.value == 3) {
    return true;
  }
  return false;
};

function validate() {
  var request = {
    id: 0,
    storyId: props.storyId,
  };
  if (props.eventId) {
    request.id = +props.eventId;
  }
  console.log("Request", request);
  if (props.eventId) {
    HTTP.put("events", request)
      .then((response) => {
        console.log(response);
        router.push({ name: "events", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          // ElNotification({
          //   title: "Echec de la mise à jour de l'étape",
          //   message:
          //     "Une erreur est survenue lors de la mise à jour de votre étape",
          //   type: "error",
          //   showClose: false,
          // });
        }
      });
  } else {
    HTTP.post("events", request)
      .then((response) => {
        console.log(response);
        router.push({ name: "events", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          // ElNotification({
          //   title: "Echec de la création de l'étape",
          //   message:
          //     "Une erreur est survenue lors de la création de votre étape",
          //   type: "error",
          //   showClose: false,
          // });
        }
      });
  }
}

onMounted(async () => {
  if (props.eventId) {
    loading.value = true;
    loading.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Création d'une nouvelle variable", -1)),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createVNode(_component_el_form, {
        "label-width": "auto",
        "label-position": "left"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("events.type")) + " : ", 1),
          _createVNode(_component_el_select, {
            modelValue: value.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
            placeholder: "Select",
            style: {"width":"240px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(options, (item) => {
                return _createVNode(_component_el_option, {
                  key: item.value,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"])
              }), 64))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_el_form_item, { label: "Nom" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: name.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((name).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_el_form_item, { label: "Value" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: variableValue.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((variableValue).value = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, isFree()]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_el_button, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Add value")
              ])),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listValues.value, (item) => {
              return (_openBlock(), _createBlock(_component_el_form_item, {
                label: "Expediteur",
                key: item
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_TextEditor, { ref: "editor" }, null, 512)
          ], 512), [
            [_vShow, isList()]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_button, {
        onClick: validate,
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.eventId ? "Mise à jour" : "Creation"), 1)
        ]),
        _: 1
      })
    ])), [
      [_directive_loading, loading.value]
    ])
  ], 64))
}
}

})