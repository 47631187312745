<template>
  <h2>Création d'une nouvelle variable</h2>
  <div v-loading="loading">
    <el-form label-width="auto" label-position="left">
      {{ $t("events.type") }} :
      <el-select v-model="value" placeholder="Select" style="width: 240px">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-form-item label="Nom">
        <el-input v-model="name" />
      </el-form-item>
      <div v-show="isFree()">
        <el-form-item label="Value">
          <el-input v-model="variableValue" />
        </el-form-item>
      </div>
      <div v-show="isList()">
        <el-button>Add value</el-button>
        <el-form-item label="Expediteur" v-for="item in listValues" :key="item">
          <el-input />
        </el-form-item>
        <TextEditor ref="editor" />
      </div>
    </el-form>
    <el-button @click="validate" type="primary">
      {{ props.eventId ? "Mise à jour" : "Creation" }}
    </el-button>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, defineProps } from "vue";
import { HTTP } from "@/http-common";
import router from "@/router";

const value = ref<number>();
const name = ref("");
const variableValue = ref("");
const listValues = ref([]);
const loading = ref(false);

interface EventProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
  eventId: string | null;
}

const props = defineProps<EventProps>();

const options = [
  {
    value: 1,
    label: "Libre",
  },
  {
    value: 2,
    label: "Oui/Non",
  },
  {
    value: 3,
    label: "Liste de valeurs",
  },
];

const isFree = () => {
  if (value.value == 1) {
    return true;
  }
  return false;
};

const isList = () => {
  if (value.value == 3) {
    return true;
  }
  return false;
};

function validate() {
  var request = {
    id: 0,
    storyId: props.storyId,
  };
  if (props.eventId) {
    request.id = +props.eventId;
  }
  console.log("Request", request);
  if (props.eventId) {
    HTTP.put("events", request)
      .then((response) => {
        console.log(response);
        router.push({ name: "events", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          // ElNotification({
          //   title: "Echec de la mise à jour de l'étape",
          //   message:
          //     "Une erreur est survenue lors de la mise à jour de votre étape",
          //   type: "error",
          //   showClose: false,
          // });
        }
      });
  } else {
    HTTP.post("events", request)
      .then((response) => {
        console.log(response);
        router.push({ name: "events", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          // ElNotification({
          //   title: "Echec de la création de l'étape",
          //   message:
          //     "Une erreur est survenue lors de la création de votre étape",
          //   type: "error",
          //   showClose: false,
          // });
        }
      });
  }
}

onMounted(async () => {
  if (props.eventId) {
    loading.value = true;
    loading.value = false;
  }
});
</script>
