import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

import { ref, computed, onMounted, onUnmounted, onBeforeMount } from "vue";
import PreregisterDialog from "@/components/dialog/PreregisterDialog.vue";
import AuthDialog from "@/components/dialog/AuthDialog.vue"; // Assurez-vous du bon chemin

import { reactive } from "vue";
import { ElNotification } from "element-plus";
import { HTTP } from "@/http-common";
import { useUserStore } from "@/store/user";
import debounce from "lodash.debounce";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainMenu',
  setup(__props) {

const userStore = useUserStore();
const user = computed(() => userStore.user);
const isTopOfPage = ref(true);
const loginLoading = ref(false);
const activeIndex = ref("1");
let labelPosition = "left";
let dialogSize = "40%";

const hasSharedNotif = ref(false);

const isAuthor = computed(() => user.value?.roles?.includes("Write"));
const dialogVisible = ref(false);
const registerOpen = ref(true);
const dialogLogoutVisible = ref(false);

const handleScroll = () => {
  // Modifie le statut basé sur la position de défilement.
  isTopOfPage.value = window.scrollY == 0;
};

function globalNotif() {
  return hasSharedNotif.value;
}

const handleResize = debounce(() => {
  labelPosition = window.innerWidth < 768 ? "top" : "left";
  dialogSize = window.innerWidth < 768 ? "70%" : "40%";
}, 100);

onBeforeMount(() => {
  userStore.onInit();
});
const preregister = reactive({
  Login: "",
});

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);
  handleResize();
  HTTP.get("/shared-stories").then((response) => {
    if (response.data.length > 0) {
      hasSharedNotif.value = true;
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  window.removeEventListener("resize", handleResize);
});

const onPreregister = async () => {
  loginLoading.value = true;
  var request = {
    email: preregister.Login,
  };
  HTTP.post("/subscribe", request)
    .then(() => {
      ElNotification({
        title: "Merci de vous etre inscrit",
        message:
          "Un email vous sera envoyé lorsque les inscriptions seront officiellement ouvertes",
        type: "success",
        showClose: false,
      });
    })
    .catch(function (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data == "Input is not an email"
      ) {
        ElNotification({
          title: "La valeur saisie est mal formatée",
          message:
            "Il semble que vous n'ayez pas saisi un email valide. Merci de vérifier vos données puis d'essayer de nouveau.",
          type: "warning",
          showClose: true,
        });
      } else {
        ElNotification({
          title: "Une erreur s'est produite",
          message:
            "Vérifier vos données en entrée puis réessayer. Si l'erreur persiste, merci de renouveller votre demande plus tard.",
          type: "error",
          showClose: true,
        });
      }
    });
  loginLoading.value = false;
  dialogVisible.value = false;
  preregister.Login = "";
};

const onLogin = async () => {
  // loginLoading.value = true;
  // const success = await userStore.login(logtosite.login, logtosite.password);
  // loginLoading.value = false;
  // if (!success) {
  //   // Gérer l'erreur de connexion
  //   loginError();
  // } else {
  //   dialogVisible.value = false;
  // }
};

const onRegister = async () => {
  // if (!register.Password || register.Password.trim() === "") {
  //   ElNotification({
  //     title: "Mot de passe vide",
  //     message: "Merci de remplir le mot de passe",
  //     type: "warning",
  //     showClose: false,
  //   });
  // } else if (!register.Password2 || register.Password2.trim() === "") {
  //   ElNotification({
  //     title: "Mot de passe vide",
  //     message: "Merci de saisir le second champ mot de passe",
  //     type: "warning",
  //     showClose: false,
  //   });
  // } else if (register.Password2 != register.Password) {
  //   ElNotification({
  //     title: "Mots de passe différents",
  //     message:
  //       "Assurez vous que les deux mots de passe saisis soient identiques",
  //     type: "error",
  //     showClose: false,
  //   });
  // } else if (
  //   !register.Password ||
  //   !register.Password2 ||
  //   !register.Lastname ||
  //   !register.Firstname ||
  //   !register.Login ||
  //   !register.Birthdate
  // ) {
  //   ElNotification({
  //     title: "Merci de renseigner tous les champs",
  //     message:
  //       "Un ou plusieurs champs du formulaire n'ont pas été remplis. Assurez vosu de renseigner tous les champs",
  //     type: "error",
  //     showClose: false,
  //   });
  // } else {
  //   var request = {
  //     login: register.Login,
  //     password: register.Password,
  //     birthdate: register.Birthdate,
  //     firstname: register.Firstname,
  //     lastname: register.Lastname,
  //   };
  //   HTTP.post("/users", request).then(() => {
  //     ElNotification({
  //       title: "Merci de vous etre inscrit",
  //       message:
  //         "Un email vient de vous etre envoyé à l'adresse renseignée, vous y trouverez le lien pour activer votre compte",
  //       type: "info",
  //       showClose: false,
  //     });
  //   });
  // }
};

const onLogout = () => {
  userStore.logout();
  dialogLogoutVisible.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_menu, {
      "default-active": activeIndex.value,
      class: _normalizeClass(["el-menu-demo", {
      transparent: isTopOfPage.value && _ctx.$route.name === 'home',
      'white-color': isTopOfPage.value,
    }]),
      mode: "horizontal",
      ellipsis: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_menu_item, {
          index: "0",
          class: _normalizeClass({
        'white-color': isTopOfPage.value && _ctx.$route.name === 'home',
        hide: isTopOfPage.value && _ctx.$route.name === 'home',
      })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: { name: 'home' },
              class: _normalizeClass({
          'white-color': isTopOfPage.value && _ctx.$route.name === 'home',
          hide: isTopOfPage.value && _ctx.$route.name === 'home',
        })
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("span", { class: "text-logo" }, "LIVRE ARBITRE", -1)
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        }, 8, ["class"]),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex-grow" }, null, -1)),
        (user.value)
          ? (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 0,
              index: "1"
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({
            'white-color': isTopOfPage.value && _ctx.$route.name === 'home',
          })
                }, [
                  _createVNode(_component_el_badge, {
                    hidden: !globalNotif(),
                    "is-dot": "",
                    class: "dot-item",
                    offset: [5, 15]
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("mainmenu.entry.reader")), 1)
                    ]),
                    _: 1
                  }, 8, ["hidden"])
                ], 2)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_router_link, { to: { name: 'catalog' } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_menu_item, { index: "2-1" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("mainmenu.entry.subRead.catalog")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: { name: 'readings' } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_menu_item, { index: "2-2" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("mainmenu.entry.subRead.reads")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: { name: 'shared-stories' } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_menu_item, { index: "2-3" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_badge, {
                          "is-dot": "",
                          class: "dot-item",
                          offset: [5, 10],
                          hidden: !hasSharedNotif.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("mainmenu.entry.subRead.shared")), 1)
                          ]),
                          _: 1
                        }, 8, ["hidden"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (user.value && isAuthor.value)
          ? (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 1,
              index: "2"
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({
            'white-color': isTopOfPage.value && _ctx.$route.name === 'home',
          })
                }, "Auteur", 2)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_menu_item, { index: "2-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: { name: 'authorStories' } }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Vos histoires ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, { index: "2-1" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: { name: 'create' } }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Ecrire une histoire")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!user.value)
          ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 2,
              index: "3",
              class: _normalizeClass({ 'white-color': isTopOfPage.value && _ctx.$route.name === 'home' }),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (dialogVisible.value = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("mainmenu.button.connect")), 1)
              ]),
              _: 1
            }, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 3,
              index: "3",
              class: _normalizeClass({ 'white-color': isTopOfPage.value && _ctx.$route.name === 'home' })
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass({
            'white-color': isTopOfPage.value && _ctx.$route.name === 'home',
          })
                }, _toDisplayString(user.value.userFirstName), 3)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_router_link, { to: { name: 'profile' } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_menu_item, { index: "3-1" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Profile")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "3-2",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (dialogLogoutVisible.value = true))
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Deconnection ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["class"]))
      ]),
      _: 1
    }, 8, ["default-active", "class"]),
    _createVNode(PreregisterDialog, {
      dialogVisible: dialogVisible.value,
      dialogSize: _unref(dialogSize),
      loginLoading: loginLoading.value,
      preregister: preregister,
      labelPosition: _unref(labelPosition),
      onClose: _cache[2] || (_cache[2] = ($event: any) => (dialogVisible.value = false)),
      onSubmit: onPreregister
    }, null, 8, ["dialogVisible", "dialogSize", "loginLoading", "preregister", "labelPosition"]),
    _createVNode(AuthDialog, {
      "dialog-visible": dialogVisible.value,
      "dialog-size": _unref(dialogSize),
      "register-open": registerOpen.value,
      "onUpdate:dialogVisible": _cache[3] || (_cache[3] = ($event: any) => (dialogVisible.value = $event)),
      onLogin: onLogin,
      onRegister: onRegister
    }, null, 8, ["dialog-visible", "dialog-size", "register-open"]),
    _createVNode(_component_el_dialog, {
      modelValue: dialogLogoutVisible.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((dialogLogoutVisible).value = $event)),
      title: "Logout",
      width: "30%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createVNode(_component_el_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (dialogLogoutVisible.value = false))
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode("Annuler")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: onLogout
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("Valider")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _cache[14] || (_cache[14] = _createElementVNode("span", null, "Voulez vous vous déconnecter ?", -1))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})