import { createI18n } from "vue-i18n";
import { App } from "vue"; // Importez App depuis vue pour utiliser app.use()

// Importez vos fichiers de traduction
import en from "./locales/en.json";
import fr from "./locales/fr.json";

// Créez une interface pour définir le type de vos fichiers de traduction
interface LocaleMessages {
  [key: string]: string | LocaleMessages;
}

// Définissez vos messages pour chaque langue
const messages = {
  en: en as LocaleMessages, // Castez en tant que LocaleMessages
  fr: fr as LocaleMessages, // Castez en tant que LocaleMessages
};

// Créez une instance de vue-i18n
const i18n = createI18n({
  legacy: false,
  locale: "fr", // Langue par défaut
  messages,
});

// Exportez la fonction d'installation pour pouvoir l'utiliser dans main.ts
export function setupI18n(app: App<Element>) {
  app.use(i18n);
}

export default i18n;
