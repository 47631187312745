<template>
  <el-container style="margin-top: 50px">
    <el-container class="container">
      <el-main>
        <div id="drawflow"></div>
      </el-main>
    </el-container>
  </el-container>
  <el-dialog v-model="dialogVisible" title="Export" width="50%">
    <span>Data:</span>
    <pre><code>{{dialogData}}</code></pre>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { Freeflow, INode } from "../library/freeflow";

// import style from "../assets/draw-style.css";
import {
  onMounted,
  shallowRef,
  h,
  getCurrentInstance,
  render,
  readonly,
  ref,
} from "vue";
import NodeOne from "../components/nodes/nodeOne.vue";
import NodeTwo from "../components/nodes/nodeTwo.vue";
import NodeThree from "../components/nodes/nodeThree.vue";
import NodeStep from "../components/nodes/nodeStep.vue";
import NodeFirst from "../components/nodes/nodeStepFirst.vue";
import NodeEvent from "../components/nodes/nodeEvent.vue";

export default {
  name: "drawFlow",
  setup() {
    const listNodes: INode[] = [
      {
        name: "New Step",
        color: "red",
        item: "NodeStep",
        input: [{ label: "step" }],
        output: [{ label: "event" }, { label: "step" }],
      },
      {
        name: "New Event",
        color: "green",
        item: "NodeEvent",
        input: [{ label: "event" }],
        output: [{ label: "step" }],
      },
      {
        name: "New beginning step",
        color: "blue",
        item: "NodeFirst",
        input: [],
        output: [{ label: "event" }, { label: "step" }],
      },
    ];

    const editor = shallowRef({});
    const dialogVisible = ref(false);
    const dialogData = ref({});
    const Vue = { version: 3, h, render };
    const internalInstance = getCurrentInstance();
    if (internalInstance != null) {
      internalInstance.appContext.app._context.config.globalProperties.$df =
        editor;
    }

    // function addLabelText(bgPath, labelText) {
    //   const newid = [bgPath.classList].join().replace(/\s/g, "");
    //   bgPath.childNodes[0].id = newid;
    //   let textElem = document.createElementNS(bgPath.namespaceURI, "text");
    //   let textElemPath = document.createElementNS(
    //     bgPath.namespaceURI,
    //     "textPath"
    //   );
    //   textElemPath.setAttribute("href", `#${newid}`);
    //   textElemPath.setAttribute("text-anchor", "middle");
    //   textElemPath.setAttribute("startOffset", "50%");
    //   textElemPath.classList.add("label-text");
    //   textElemPath.textContent = labelText;
    //   textElem.appendChild(textElemPath);
    //   bgPath.appendChild(textElem);
    // }

    onMounted(() => {
      if (internalInstance == null) {
        throw new Error("Instance not defined");
      }
      var freeflow = new Freeflow(
        document.getElementById("drawflow"),
        listNodes,
        Vue,
        internalInstance.appContext.app._context
      );

      // const id = document.getElementById("drawflow");
      // editor.value = new Drawflow(
      //   id,
      //   Vue,
      //   internalInstance.appContext.app._context
      // );
      // editor.value.reroute = true;
      // editor.value.zoom_min = 0.1;
      // editor.value.start();

      // // editor.value.on("nodeSelected", function (nodeId) {
      // //   var selectedNode = editor.value.getNodeFromId(nodeId);
      // //   console.log(selectedNode);
      // //   selectedNode.class = selectedNode.class + "se";
      // // });

      // // Écoute de l'événement de connexion
      // editor.value.on("connectionCreated", function (connection) {
      //   let label = document.querySelector(
      //     ".connection.node_in_node-" +
      //       connection.input_id +
      //       ".node_out_node-" +
      //       connection.output_id +
      //       "." +
      //       connection.output_class +
      //       "." +
      //       connection.input_class
      //   );
      //   addLabelText(label, "Something");

      //   // if (label) {
      //   //   const flabel = document.createElement("div");
      //   //   flabel.innerText = "text"; // Utilise les données pour afficher le label
      //   //   flabel.className = "connection-label"; // Ajoute une classe pour le style
      //   //   console.log(label);
      //   //   label.appendChild(label); // Ajoute le label à la ligne
      //   // }
      //   // const data = connection.data; // Récupère les données de la connexion
      //   // const line = document.querySelector(
      //   //   `.drawflow-connection[data-id="${connection.id}"]`
      //   // );
      //   // if (line) {
      //   //   const label = document.createElement("div");
      //   //   label.innerText = data.label; // Utilise les données pour afficher le label
      //   //   label.className = "connection-label"; // Ajoute une classe pour le style
      //   //   line.appendChild(label); // Ajoute le label à la ligne
      //   // }
      // });
      // // editor.value.on("connectionCreated", function (id) {
      // //   alert("Connection created");
      // // });
      // // editor.value.on("connectionStart", function (id) {
      // //   alert("Connection start");
      // // });

      // editor.value.registerNode("Node1", NodeOne, {}, {});
      // editor.value.registerNode("Node2", NodeTwo, {}, {});
      // editor.value.registerNode("Node3", NodeThree, {}, {});
      // editor.value.registerNode("NodeStep", NodeStep, {}, {});
      // editor.value.registerNode("NodeFirst", NodeFirst, {}, {});
      // editor.value.registerNode("NodeEvent", NodeEvent, {}, {});

      // editor.value.import({
      //   drawflow: {
      //     Home: {
      //       data: {
      //         5: {
      //           id: 5,
      //           name: "Node2",
      //           data: { script: "(req,res) => {\n console.log(req);\n}" },
      //           class: "Node2",
      //           html: "Node2",
      //           typenode: "vue",
      //           inputs: {
      //             input_1: { connections: [{ node: "6", input: "output_1" }] },
      //           },
      //           outputs: {
      //             output_1: { connections: [] },
      //             output_2: { connections: [] },
      //           },
      //           pos_x: 1000,
      //           pos_y: 117,
      //         },
      //         6: {
      //           id: 6,
      //           name: "Node1",
      //           data: { url: "localhost/add", method: "post" },
      //           class: "Node1",
      //           html: "Node1",
      //           typenode: "vue",
      //           inputs: {},
      //           outputs: {
      //             output_1: { connections: [{ node: "5", output: "input_1" }] },
      //           },
      //           pos_x: 137,
      //           pos_y: 89,
      //         },
      //       },
      //     },
      //   },
      // });
    });

    return {
      // exportEditor,
      listNodes,
      dialogVisible,
      dialogData,
    };
  },
};
</script>
<style>
.input[data-type="step"],
.output[data-type="step"] {
  /*background-color: #ffa8c4 !important;*/
  background-color: lightslategrey !important;
}
.input[data-type="event"],
.output[data-type="event"] {
  /*background-color: #9ac8fc !important;*/
  background-color: lightgrey !important;
}
</style>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #494949;
}
.container {
  min-height: calc(100vh - 100px);
}
.column {
  border-right: 1px solid #494949;
}
.column ul {
  padding-inline-start: 0px;
  padding: 10px 10px;
}
.column li {
  background: transparent;
}
/* .node {
  border-radius: var(--element-border-radius);
  border: 1px solid #494949;
  display: block;
  height: 60px;
  line-height: 40px;
  padding: 10px;
  margin: 10px 0px;
  cursor: move;
} */
</style>
