<template>
  <h2>Création d'un nouvel évènement</h2>
  <div v-loading="loading">
    <el-form label-width="auto" label-position="left">
      {{ $t("events.type") }} :
      <el-select v-model="value" placeholder="Select" style="width: 240px">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-form-item label="Nom">
        <el-input v-model="description" />
      </el-form-item>
      <div v-show="isMessage()">
        <el-form-item label="Expediteur">
          <el-input v-model="expediteur" />
        </el-form-item>
        <el-form-item label="Sujet du mail">
          <el-input v-model="subject" />
        </el-form-item>
        <TextEditor ref="editor" />
      </div>
    </el-form>
    <el-button @click="validate" type="primary">
      {{ props.eventId ? "Mise à jour" : "Creation" }}
    </el-button>
    <el-button @click="preview">Preview event</el-button>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, defineProps } from "vue";
import TextEditor from "@/components/TextEditor.vue";
import { HTTP } from "@/http-common";
import router from "@/router";

const value = ref<number>();
const description = ref("");
const expediteur = ref("");
const subject = ref("");
const editor = ref<typeof TextEditor>();
const loading = ref(false);

interface EventProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
  eventId: string | null;
}

const props = defineProps<EventProps>();

const options = [
  {
    value: 1,
    label: "Envoi d'email",
  },
  {
    value: 2,
    label: "SMS",
  },
  {
    value: 3,
    label: "DirectMessage",
  },
];

const isMessage = () => {
  if (value.value == 1) {
    return true;
  }
  return false;
};

const toEnumType = () => {
  if (value.value == 1) {
    return "Email";
  }
  return "";
};

const fromEnumType = (enumType: string) => {
  if (enumType == "Email") {
    return 1;
  }
  return 0;
};

function GetOptionalParameters() {
  if (toEnumType() == "Email") {
    return [
      {
        key: "sender",
        value: expediteur.value,
      },
      {
        key: "subject",
        value: subject.value,
      },
    ];
  }
  return {};
}

function preview() {
  var request = {
    content: editor.value?.getText(),
    eventType: 0,
    parameters: GetOptionalParameters(),
  };
  HTTP.post("preview/event?storyId=" + props.storyId, request)
    .then((response) => {
      console.log(response);
    })
    .catch(function (error) {
      if (
        error.response &&
        (error.response.status == 401 || error.response.status == 403)
      ) {
        console.log("Une erreur est survenue");
      }
    });
}

function validate() {
  var request = {
    id: 0,
    storyId: props.storyId,
    content: editor.value?.getText(),
    description: description.value,
    eventType: toEnumType(),
    parameters: GetOptionalParameters(),
  };
  if (props.eventId) {
    request.id = +props.eventId;
  }
  console.log("Request", request);
  if (props.eventId) {
    HTTP.put("events", request)
      .then((response) => {
        console.log(response);
        router.push({ name: "events", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          // ElNotification({
          //   title: "Echec de la mise à jour de l'étape",
          //   message:
          //     "Une erreur est survenue lors de la mise à jour de votre étape",
          //   type: "error",
          //   showClose: false,
          // });
        }
      });
  } else {
    HTTP.post("events", request)
      .then((response) => {
        console.log(response);
        router.push({ name: "events", params: { storyId: props.storyId } });
      })
      .catch(function (error) {
        if (
          error.response &&
          (error.response.status == 401 || error.response.status == 403)
        ) {
          // la requête a été faite et le code de réponse du serveur n’est pas dans
          // la plage 2xx
          // ElNotification({
          //   title: "Echec de la création de l'étape",
          //   message:
          //     "Une erreur est survenue lors de la création de votre étape",
          //   type: "error",
          //   showClose: false,
          // });
        }
      });
  }
}

onMounted(async () => {
  if (props.eventId) {
    loading.value = true;
    const response = await HTTP.get("events/" + props.eventId);
    description.value = response.data.description;
    value.value = fromEnumType(response.data.eventType);

    var objetAvecCleSender = response.data.parameters.find(
      (objet: { key: string; value: string }) => objet.key === "sender"
    );

    if (objetAvecCleSender) {
      console.log("Objet avec la clé 'sender' trouvé :", objetAvecCleSender);
      expediteur.value = objetAvecCleSender.value;
    } else {
      console.log("Aucun objet avec la clé 'sender' trouvé");
    }

    var objetAvecCleSubject = response.data.parameters.find(
      (objet: { key: string; value: string }) => objet.key === "subject"
    );

    if (objetAvecCleSubject) {
      console.log("Objet avec la clé 'subject' trouvé :", objetAvecCleSubject);
      subject.value = objetAvecCleSubject.value;
    } else {
      console.log("Aucun objet avec la clé 'subject' trouvé");
    }

    editor.value?.setHTML(response.data.content);
    loading.value = false;
  }
});
</script>
