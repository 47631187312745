import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 0,
  width: "16px",
  height: "16px",
  viewBox: "0 0 16 16",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none"
}
const _hoisted_3 = ["innerHTML"]

import { HTTP } from "@/http-common";
import { ref, onMounted } from "vue";

interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}

interface Step {
  id: number;
  description: string;
  storyId: number;
  content: string;
  isHead: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StepsView',
  props: {
    storyId: {}
  },
  setup(__props: any) {

const props = __props;

const data = ref<Step[]>([]);
const loading = ref(true);

const handleDelete = (index: number, stepId: number) => {
  console.log(index, stepId);
  HTTP.delete("stories/" + props.storyId + "/steps/" + stepId).then(
    (deleteResponse) => {
      console.log(deleteResponse);
      loading.value = true;
      HTTP.get("stories/" + props.storyId + "/steps").then((response) => {
        data.value = response.data;
        loading.value = false;
      });
    }
  );
};

onMounted(async () => {
  if (props.storyId) {
    try {
      loading.value = true;
      const response = await HTTP.get("stories/" + props.storyId + "/steps");
      data.value = response.data;
      loading.value = false;
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  } else {
    console.log("No param in url");
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Liste des étapes", -1)),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      (data.value.length == 0)
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 0,
            description: _ctx.$t('steps.empty')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'step', params: { storyId: props.storyId } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, { type: "primary" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Ajouter votre premiere étape")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }, 8, ["description"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_table, {
              data: data.value,
              style: {"width":"1400px","z-index":"0"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  fixed: "",
                  prop: "description",
                  label: "Description",
                  width: "150"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.isHead)
                      ? (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[1] || (_cache[1] = [
                          _createElementVNode("g", null, [
                            _createElementVNode("path", {
                              fill: "green",
                              d: "M8 2.5a5.494 5.494 0 00-4.558 2.42.75.75 0 01-1.242-.84 7 7 0 110 7.841.75.75 0 111.242-.841A5.5 5.5 0 108 2.5z"
                            }),
                            _createElementVNode("path", {
                              fill: "red",
                              d: "M7.245 4.695a.75.75 0 00-.05 1.06l1.36 1.495H1.75a.75.75 0 000 1.5h6.805l-1.36 1.495a.75.75 0 001.11 1.01l2.5-2.75a.75.75 0 000-1.01l-2.5-2.75a.75.75 0 00-1.06-.05z"
                            })
                          ], -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(scope.row.description), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "Content",
                  width: "920"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", {
                      innerHTML: 
                scope.row.content.length > 200
                  ? scope.row.content.slice(0, 200) + '...'
                  : scope.row.content
              
                    }, null, 8, _hoisted_3)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: "Operations",
                  width: "180"
                }, {
                  default: _withCtx((scope) => [
                    _createVNode(_component_router_link, {
                      to: {
                name: 'step',
                params: { storyId: props.storyId, stepId: scope.row.id },
              }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("steps.buttons.edit")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (handleDelete(scope.$index, scope.row.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("steps.buttons.delete")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"])
          ]))
    ])), [
      [_directive_loading, loading.value]
    ])
  ], 64))
}
}

})