import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

import { ref, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PreregisterDialog',
  props: {
  modelValue: Boolean,
  dialogSize: {
    type: String,
    default: "30%",
  },
  loginLoading: Boolean,
  preregister: Object,
  labelPosition: {
    type: String,
    default: "top",
  },
},
  emits: ["update:modelValue", "submit"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Crée une copie locale de l'objet preregister pour éviter de muter la prop
const { preregister } = toRefs(props);
const localPreregister = ref({ ...preregister.value });

const closeDialog = () => {
  emit("update:modelValue", false);
};

const onPreregister = () => {
  emit("submit", localPreregister.value); // Si tu veux passer les données
};

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": __props.modelValue,
    title: "Préinscriptions",
    width: __props.dialogSize,
    onClose: closeDialog
  }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("div", null, [
          _createTextVNode(" Livre arbitre ouvre bientôt ses portes !"),
          _createElementVNode("br"),
          _createTextVNode(" Si tu veux être parmi les premiers informés, inscris-toi ! ")
        ], -1)),
        _createVNode(_component_el_form, {
          model: localPreregister.value,
          "label-width": "50px",
          "label-position": __props.labelPosition,
          style: {"margin-top":"30px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Email" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: localPreregister.value.Login,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localPreregister.value.Login) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, { onClick: closeDialog }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Annuler")
                ])),
                _: 1
              }),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: onPreregister
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Se préinscrire")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model", "label-position"])
      ])), [
        [_directive_loading, __props.loginLoading]
      ])
    ]),
    _: 1
  }, 8, ["model-value", "width"]))
}
}

})