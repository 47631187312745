<template>
  <el-container style="margin-top: 50px">
    <el-header class="header">
      <h3>Drawflow Example vue3</h3>
      <el-button type="primary" @click="exportEditor">Export</el-button>
    </el-header>
    <el-container class="container">
      <el-aside width="250px" class="column">
        <ul>
          <li
            v-for="n in listNodes"
            :key="n"
            draggable="true"
            :data-node="n.item"
            @dragstart="drag($event)"
            class=" "
          >
            <div class="node" :style="`background: ${n.color}`">
              {{ n.name }}
            </div>
          </li>
        </ul>
      </el-aside>
      <el-main>
        <div id="drawflow"></div>
        <div
          id="drawflow"
          @drop="drop($event)"
          @dragover="allowDrop($event)"
        ></div>
      </el-main>
    </el-container>
  </el-container>
  <el-dialog v-model="dialogVisible" title="Export" width="50%">
    <span>Data:</span>
    <pre><code>{{dialogData}}</code></pre>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import Drawflow from "../library/drawflow.js";
// import style from "../assets/drawinitCopy.css";
import {
  onMounted,
  shallowRef,
  h,
  getCurrentInstance,
  render,
  readonly,
  ref,
} from "vue";
import NodeOne from "../components/nodes/nodeOne.vue";
import NodeTwo from "../components/nodes/nodeTwo.vue";
import NodeThree from "../components/nodes/nodeThree.vue";
import NodeStep from "../components/nodes/nodeStep.vue";
import NodeFirst from "../components/nodes/nodeStepFirst.vue";
import NodeEvent from "../components/nodes/nodeEvent.vue";

export default {
  name: "drawFlow",
  setup() {
    const listNodes = readonly([
      {
        name: "New Step",
        color: "red",
        item: "NodeStep",
        input: [{ label: "step" }],
        output: [{ label: "event" }, { label: "step" }],
      },
      {
        name: "New Event",
        color: "green",
        item: "NodeEvent",
        input: [{ label: "event" }],
        output: [{ label: "step" }],
      },
      {
        name: "New beginning step",
        color: "blue",
        item: "NodeFirst",
        input: [],
        output: [{ label: "event" }, { label: "step" }],
      },
    ]);

    const editor = shallowRef({});
    const dialogVisible = ref(false);
    const dialogData = ref({});
    const Vue = { version: 3, h, render };
    const internalInstance = getCurrentInstance();
    if (internalInstance != null) {
      internalInstance.appContext.app._context.config.globalProperties.$df =
        editor;
    }

    function exportEditor() {
      dialogData.value = editor.value.export();
      dialogVisible.value = true;
    }
    // drag from list
    const drag = (ev) => {
      if (ev.type === "touchstart") {
        mobile_item_selec = ev.target
          .closest(".drag-drawflow")
          .getAttribute("data-node");
      } else {
        ev.dataTransfer.setData("node", ev.target.getAttribute("data-node"));
      }
    };
    // drop from list
    const drop = (ev) => {
      if (ev.type === "touchend") {
        var parentdrawflow = document
          .elementFromPoint(
            mobile_last_move.touches[0].clientX,
            mobile_last_move.touches[0].clientY
          )
          .closest("#drawflow");
        if (parentdrawflow != null) {
          addNodeToDrawFlow(
            mobile_item_selec,
            mobile_last_move.touches[0].clientX,
            mobile_last_move.touches[0].clientY
          );
        }
        mobile_item_selec = "";
      } else {
        ev.preventDefault();
        console.log(ev.target);
        console.log(ev.target.classList);
        if (ev.target.classList.contains("drawflow_content_node")) {
          ev.target.classList.add("filled");
          console.log("Les choses sérieuses commencent");
          return;
        }
        // if (
        //   ele_last.classList[0] === "input" ||
        //   (this.force_first_input &&
        //     (ele_last.closest(".drawflow_content_node") != null ||
        //       ele_last.classList[0] === "drawflow-node"))
        // ) {
        // }
        var data = ev.dataTransfer.getData("node");
        addNodeToDrawFlow(data, ev.clientX, ev.clientY);
      }
    };

    const allowDrop = (ev) => {
      ev.preventDefault();
    };

    let mobile_item_selec = "";
    let mobile_last_move = null;
    function positionMobile(ev) {
      mobile_last_move = ev;
    }

    function addNodeToDrawFlow(name, pos_x, pos_y) {
      pos_x =
        pos_x *
          (editor.value.precanvas.clientWidth /
            (editor.value.precanvas.clientWidth * editor.value.zoom)) -
        editor.value.precanvas.getBoundingClientRect().x *
          (editor.value.precanvas.clientWidth /
            (editor.value.precanvas.clientWidth * editor.value.zoom));
      pos_y =
        pos_y *
          (editor.value.precanvas.clientHeight /
            (editor.value.precanvas.clientHeight * editor.value.zoom)) -
        editor.value.precanvas.getBoundingClientRect().y *
          (editor.value.precanvas.clientHeight /
            (editor.value.precanvas.clientHeight * editor.value.zoom));

      const nodeSelected = listNodes.find((ele) => ele.item == name);

      editor.value.addNode(
        name,
        nodeSelected.input,
        nodeSelected.output,
        pos_x,
        pos_y,
        name,
        { title: true },
        name,
        "vue"
      );
    }

    function addLabelText(bgPath, labelText) {
      const newid = [bgPath.classList].join().replace(/\s/g, "");
      bgPath.childNodes[0].id = newid;
      let textElem = document.createElementNS(bgPath.namespaceURI, "text");
      let textElemPath = document.createElementNS(
        bgPath.namespaceURI,
        "textPath"
      );
      textElemPath.setAttribute("href", `#${newid}`);
      textElemPath.setAttribute("text-anchor", "middle");
      textElemPath.setAttribute("startOffset", "50%");
      textElemPath.classList.add("label-text");
      textElemPath.textContent = labelText;
      textElem.appendChild(textElemPath);
      bgPath.appendChild(textElem);
    }

    onMounted(() => {
      if (internalInstance == null) {
        throw new Error("Instance not defined");
      }
      var drawflow = new Drawflow(
        document.getElementById("drawflow"),
        Vue,
        internalInstance.appContext.app._context
      );

      var elements = document.getElementsByClassName("drag-drawflow");
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener("touchend", drop, false);
        elements[i].addEventListener("touchmove", positionMobile, false);
        elements[i].addEventListener("touchstart", drag, false);
      }

      const id = document.getElementById("drawflow");
      editor.value = new Drawflow(
        id,
        Vue,
        internalInstance.appContext.app._context
      );
      editor.value.reroute = true;
      editor.value.zoom_min = 0.1;
      editor.value.start();

      editor.value.on("nodeSelected", function (nodeId) {
        var selectedNode = editor.value.getNodeFromId(nodeId);
        console.log(selectedNode);
        selectedNode.class = selectedNode.class + "se";
      });

      // Écoute de l'événement de connexion
      editor.value.on("connectionCreated", function (connection) {
        let label = document.querySelector(
          ".connection.node_in_node-" +
            connection.input_id +
            ".node_out_node-" +
            connection.output_id +
            "." +
            connection.output_class +
            "." +
            connection.input_class
        );
        addLabelText(label, "Something");

        if (label) {
          const flabel = document.createElement("div");
          flabel.innerText = "text"; // Utilise les données pour afficher le label
          flabel.className = "connection-label"; // Ajoute une classe pour le style
          console.log(label);
          label.appendChild(label); // Ajoute le label à la ligne
        }
        const data = connection.data; // Récupère les données de la connexion
        const line = document.querySelector(
          `.drawflow-connection[data-id="${connection.id}"]`
        );
        if (line) {
          const label = document.createElement("div");
          label.innerText = data.label; // Utilise les données pour afficher le label
          label.className = "connection-label"; // Ajoute une classe pour le style
          line.appendChild(label); // Ajoute le label à la ligne
        }
      });
      // editor.value.on("connectionCreated", function (id) {
      //   alert("Connection created");
      // });
      // editor.value.on("connectionStart", function (id) {
      //   alert("Connection start");
      // });

      editor.value.registerNode("Node1", NodeOne, {}, {});
      editor.value.registerNode("Node2", NodeTwo, {}, {});
      editor.value.registerNode("Node3", NodeThree, {}, {});
      editor.value.registerNode("NodeStep", NodeStep, {}, {});
      editor.value.registerNode("NodeFirst", NodeFirst, {}, {});
      editor.value.registerNode("NodeEvent", NodeEvent, {}, {});

      editor.value.import({
        drawflow: {
          Home: {
            data: {
              5: {
                id: 5,
                name: "Node2",
                data: { script: "(req,res) => {\n console.log(req);\n}" },
                class: "Node2",
                html: "Node2",
                typenode: "vue",
                inputs: {
                  input_1: { connections: [{ node: "6", input: "output_1" }] },
                },
                outputs: {
                  output_1: { connections: [] },
                  output_2: { connections: [] },
                },
                pos_x: 1000,
                pos_y: 117,
              },
              6: {
                id: 6,
                name: "Node1",
                data: { url: "localhost/add", method: "post" },
                class: "Node1",
                html: "Node1",
                typenode: "vue",
                inputs: {},
                outputs: {
                  output_1: { connections: [{ node: "5", output: "input_1" }] },
                },
                pos_x: 137,
                pos_y: 89,
              },
            },
          },
        },
      });
    });

    return {
      // exportEditor,
      listNodes,
      // drag,
      drop,
      allowDrop,
      dialogVisible,
      dialogData,
    };
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #494949;
}
.container {
  min-height: calc(100vh - 100px);
}
.column {
  border-right: 1px solid #494949;
}
.column ul {
  padding-inline-start: 0px;
  padding: 10px 10px;
}
.column li {
  background: transparent;
}
.node {
  border-radius: var(--element-border-radius);
  border: 1px solid #494949;
  display: block;
  height: 60px;
  line-height: 40px;
  padding: 10px;
  margin: 10px 0px;
  cursor: move;
}
#drawflow {
  width: 100%;
  height: 100%;
  text-align: initial;
  /* backgroung and grid*/
  /* background: #2b2c30;  */
  background: var(--draw-background-color);
  background-size: 20px 20px;
  background-image: radial-gradient(#494949 1px, transparent 1px);
}
.drawflow-node.selected {
  width: 311px;
}
</style>
