import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "button-bar" }
const _hoisted_2 = { class: "line" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"flex":"auto"} }
const _hoisted_5 = { class: "dialog-footer" }

import { ref, onMounted, reactive } from "vue";
import { Back, Right } from "@element-plus/icons-vue";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import TextAlign from "@tiptap/extension-text-align";
import StarterKit from "@tiptap/starter-kit";
import BulletIcon from "../icons/BulletIcon.vue";
import OrderedIcon from "../icons/OrderedListIcon.vue";
import { HTTP } from "../http-common";
import { useI18n } from "vue-i18n";
interface textVariable {
  label: string; // Par exemple, l'id peut être une chaîne ou null
  value: string; // Par exemple, l'id peut être une chaîne ou null
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextEditor',
  setup(__props, { expose: __expose }) {

const { t } = useI18n();

const drawer = ref(false);
const innerDrawer = ref(false);
const genderpopup = ref(false);
const buttonColor = ref("#FFFFFF");

const form = reactive({
  male: "",
  female: "",
  neutral: "",
});

const editor = useEditor({
  content: "",
  extensions: [
    StarterKit,
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
  ],
});

const items = ref<textVariable[]>([]);
//   { label: "Prénom utilisateur", value: "opt1" },
//   { label: "Nom utilisateur", value: "opt2" },
//   { label: "Age utilisateur", value: "opt3" },
// ];
const selectItem = (item) => {
  console.log("selection", item);
  selectedText.value = item;
};

const selectedText = ref({});
const variablesDialogVisible = ref(false);

function addVariableInText() {
  //editor.value?.commands.focus; //chain().focus().
  drawer.value = false;
  if (selectedText.value.value == "Gender") {
    genderpopup.value = true;
  } else {
    editor.value
      ?.chain()
      .focus()
      .deleteSelection()
      .insertContent("{{" + selectedText.value.value + "}}")
      .run();
  }
}

function insertGenderVar() {
  editor.value
    ?.chain()
    .focus()
    .deleteSelection()
    .insertContent(
      "{{Gender:" + form.male + "|" + form.female + "|" + form.neutral + "}}"
    )
    .run();
  genderpopup.value = false;
}

function getText() {
  return editor.value?.getHTML();
}

function setHTML(initValue: string) {
  editor.value?.view.pasteHTML(initValue);
}

const openVariableDialog = () => {
  variablesDialogVisible.value = true;
};

const updateSelectedText = () => {
  const selection = editor.value?.state.selection;
  if (selection && !selection.empty) {
    const { from, to } = selection;
    const selectedNode = editor.value?.state.doc.textBetween(from, to);
    selectedText.value = selectedNode ?? "";
  } else {
    selectedText.value = "";
  }
};

__expose({
  getText,
  setHTML,
});

onMounted(async () => {
  // Écoutez les événements de sélection de l'éditeur
  // editor.value?.on("selectionUpdate", updateSelectedText);
  HTTP.get("variables").then((response) => {
    for (var i = 0; i < response.data.length; i++) {
      var itemValue = response.data[i];
      items.value.push({
        label: t("texteditor.variables." + itemValue.code),
        value: itemValue.code,
      });
    }
  });
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_drawer = _resolveComponent("el-drawer")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().toggleBold().run())),
            class: _normalizeClass({ 'is-active': _unref(editor) && _unref(editor).isActive('bold') })
          }, {
            default: _withCtx(() => _cache[26] || (_cache[26] = [
              _createElementVNode("strong", null, "B", -1)
            ])),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().toggleItalic().run())),
            plain: _unref(editor) && _unref(editor).isActive('italic'),
            class: _normalizeClass({ 'is-active': _unref(editor) && _unref(editor).isActive('italic') })
          }, {
            default: _withCtx(() => _cache[27] || (_cache[27] = [
              _createElementVNode("em", null, "I", -1)
            ])),
            _: 1
          }, 8, ["color", "plain", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().toggleStrike().run())),
            plain: _unref(editor) && _unref(editor).isActive('strike'),
            class: _normalizeClass({ 'is-active': _unref(editor) && _unref(editor).isActive('strike') })
          }, {
            default: _withCtx(() => _cache[28] || (_cache[28] = [
              _createElementVNode("s", null, "S", -1)
            ])),
            _: 1
          }, 8, ["color", "plain", "class"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (
          _unref(editor) && _unref(editor).chain().focus().toggleHeading({ level: 1 }).run()
        )),
            class: _normalizeClass({
          'is-active': _unref(editor) && _unref(editor).isActive('heading', { level: 1 }),
        })
          }, {
            default: _withCtx(() => _cache[29] || (_cache[29] = [
              _createTextVNode(" H1 ")
            ])),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (
          _unref(editor) && _unref(editor).chain().focus().toggleHeading({ level: 2 }).run()
        )),
            class: _normalizeClass({
          'is-active': _unref(editor) && _unref(editor).isActive('heading', { level: 2 }),
        })
          }, {
            default: _withCtx(() => _cache[30] || (_cache[30] = [
              _createTextVNode(" H2 ")
            ])),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (
          _unref(editor) && _unref(editor).chain().focus().toggleHeading({ level: 3 }).run()
        )),
            class: _normalizeClass({
          'is-active': _unref(editor) && _unref(editor).isActive('heading', { level: 3 }),
        })
          }, {
            default: _withCtx(() => _cache[31] || (_cache[31] = [
              _createTextVNode(" H3 ")
            ])),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[6] || (_cache[6] = ($event: any) => (
          _unref(editor) && _unref(editor).chain().focus().toggleHeading({ level: 4 }).run()
        )),
            class: _normalizeClass({
          'is-active': _unref(editor) && _unref(editor).isActive('heading', { level: 4 }),
        })
          }, {
            default: _withCtx(() => _cache[32] || (_cache[32] = [
              _createTextVNode(" H4 ")
            ])),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().setParagraph().run())),
            class: _normalizeClass({ 'is-active': _unref(editor) && _unref(editor).isActive('paragraph') })
          }, {
            default: _withCtx(() => _cache[33] || (_cache[33] = [
              _createTextVNode(" p ")
            ])),
            _: 1
          }, 8, ["color", "class"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            style: {"color":"black"},
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().undo().run())),
            disabled: _unref(editor) && !_unref(editor).can().chain().focus().undo().run(),
            icon: _unref(Back)
          }, null, 8, ["color", "disabled", "icon"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            style: {"color":"black"},
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().redo().run())),
            disabled: _unref(editor) && !_unref(editor).can().chain().focus().redo().run(),
            icon: _unref(Right)
          }, null, 8, ["color", "disabled", "icon"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().toggleBulletList().run())),
            class: _normalizeClass({ 'is-active': _unref(editor) && _unref(editor).isActive('bulletList') })
          }, {
            default: _withCtx(() => [
              _createVNode(BulletIcon)
            ]),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().toggleOrderedList().run())),
            class: _normalizeClass({ 'is-active': _unref(editor) && _unref(editor).isActive('orderedList') })
          }, {
            default: _withCtx(() => [
              _createVNode(OrderedIcon, {
                size: 24,
                color: "black"
              })
            ]),
            _: 1
          }, 8, ["color", "class"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[12] || (_cache[12] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().setTextAlign('left').run())),
            class: _normalizeClass([{
          'is-active': _unref(editor) && _unref(editor).isActive({ textAlign: 'left' }),
        }, "align-button"])
          }, {
            default: _withCtx(() => _cache[34] || (_cache[34] = [
              _createElementVNode("svg", {
                width: "24",
                height: "24",
                focusable: "false"
              }, [
                _createElementVNode("path", {
                  fill: "black",
                  d: "M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 1 1 0-2Zm0 4h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 1 1 0-2Zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 0 1 0-2Zm0-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 0 1 0-2Z"
                })
              ], -1)
            ])),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[13] || (_cache[13] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().setTextAlign('center').run())),
            class: _normalizeClass([{
          'is-active': _unref(editor) && _unref(editor).isActive({ textAlign: 'center' }),
        }, "align-button"])
          }, {
            default: _withCtx(() => _cache[35] || (_cache[35] = [
              _createElementVNode("svg", {
                width: "24px",
                height: "24px",
                viewBox: "0 0 24 24"
              }, [
                _createElementVNode("path", {
                  d: "M3 5a1 1 0 0 0 0 2h18a1 1 0 1 0 0-2H3ZM6 9a1 1 0 0 0 0 2h12a1 1 0 1 0 0-2H6ZM2 14a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM6 17a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H6Z",
                  fill: "black"
                })
              ], -1)
            ])),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().setTextAlign('right').run())),
            class: _normalizeClass([{
          'is-active': _unref(editor) && _unref(editor).isActive({ textAlign: 'right' }),
        }, "align-button"])
          }, {
            default: _withCtx(() => _cache[36] || (_cache[36] = [
              _createElementVNode("svg", {
                width: "24px",
                height: "24px",
                viewBox: "0 0 24 24"
              }, [
                _createElementVNode("g", {
                  id: "Page-1",
                  stroke: "black",
                  "stroke-width": "1",
                  fill: "none"
                }, [
                  _createElementVNode("g", { id: "Align-Right" }, [
                    _createElementVNode("rect", {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "24",
                      height: "24",
                      stroke: "none"
                    }),
                    _createElementVNode("line", {
                      x1: "5",
                      y1: "6",
                      x2: "19",
                      y2: "6",
                      id: "Path",
                      "stroke-width": "2",
                      "stroke-linecap": "round"
                    }),
                    _createElementVNode("line", {
                      x1: "5",
                      y1: "14",
                      x2: "19",
                      y2: "14",
                      id: "Path",
                      "stroke-width": "2",
                      "stroke-linecap": "round"
                    }),
                    _createElementVNode("line", {
                      x1: "11",
                      y1: "10",
                      x2: "19",
                      y2: "10",
                      id: "Path",
                      "stroke-width": "2",
                      "stroke-linecap": "round"
                    }),
                    _createElementVNode("line", {
                      x1: "11",
                      y1: "18",
                      x2: "19",
                      y2: "18",
                      id: "Path",
                      "stroke-width": "2",
                      "stroke-linecap": "round"
                    })
                  ])
                ])
              ], -1)
            ])),
            _: 1
          }, 8, ["color", "class"]),
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[15] || (_cache[15] = ($event: any) => (_unref(editor) && _unref(editor).chain().focus().setTextAlign('justify').run())),
            class: _normalizeClass([{
          'is-active': _unref(editor) && _unref(editor).isActive({ textAlign: 'justify' }),
        }, "align-button"])
          }, {
            default: _withCtx(() => _cache[37] || (_cache[37] = [
              _createElementVNode("svg", {
                width: "24px",
                height: "24px",
                viewBox: "0 0 24 24"
              }, [
                _createElementVNode("path", {
                  d: "M5 6H19M5 10H19M5 14H19M5 18H19",
                  stroke: "black",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2"
                })
              ], -1)
            ])),
            _: 1
          }, 8, ["color", "class"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            color: buttonColor.value,
            onClick: _cache[16] || (_cache[16] = ($event: any) => (drawer.value = true))
          }, {
            default: _withCtx(() => _cache[38] || (_cache[38] = [
              _createElementVNode("svg", {
                width: "20px",
                height: "20px",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M19.9061 21C21.2464 18.2888 22 15.2329 22 12C22 8.76711 21.2464 5.71116 19.9061 3M4.09393 3C2.75363 5.71116 2 8.76711 2 12C2 15.2329 2.75363 18.2888 4.09393 21M16.5486 8.625H16.459C15.8056 8.625 15.1848 8.91202 14.7596 9.41072L9.38471 15.7143C8.95948 16.213 8.33871 16.5 7.6853 16.5H7.59563M8.71483 8.625H10.1089C10.6086 8.625 11.0477 8.95797 11.185 9.44094L12.9594 15.6841C13.0967 16.167 13.5358 16.5 14.0355 16.5H15.4296",
                  stroke: "#000000",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1)
            ])),
            _: 1
          }, 8, ["color"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_unref(EditorContent), {
      editor: _unref(editor),
      class: "editor"
    }, null, 8, ["editor"]),
    _createVNode(_component_el_drawer, {
      modelValue: drawer.value,
      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((drawer).value = $event)),
      direction: "rtl",
      size: "50%"
    }, {
      header: _withCtx(() => _cache[39] || (_cache[39] = [
        _createElementVNode("h4", null, "Handle variables in text", -1)
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.value,
                class: _normalizeClass({ active: selectedText.value.value === item.value }),
                onClick: ($event: any) => (selectItem(item))
              }, _toDisplayString(item.label), 11, _hoisted_3))
            }), 128))
          ]),
          _createVNode(_component_el_drawer, {
            modelValue: innerDrawer.value,
            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((innerDrawer).value = $event)),
            title: "I'm inner Drawer",
            "append-to-body": true
          }, {
            header: _withCtx(() => _cache[40] || (_cache[40] = [
              _createElementVNode("h4", null, "Aide sur la gestion des variables", -1)
            ])),
            default: _withCtx(() => _cache[41] || (_cache[41] = [
              _createTextVNode(" La variable de type genre est différente des autres variables, elle va permettre de choisir un mot parmi 3 en fonction du genre sélectionné par l'utilisateur. ")
            ])),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_button, {
            type: "info",
            onClick: _cache[18] || (_cache[18] = ($event: any) => (innerDrawer.value = true))
          }, {
            default: _withCtx(() => _cache[42] || (_cache[42] = [
              _createTextVNode("Help")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            onClick: _cache[19] || (_cache[19] = ($event: any) => (drawer.value = false))
          }, {
            default: _withCtx(() => _cache[43] || (_cache[43] = [
              _createTextVNode("Annuler")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "success",
            onClick: addVariableInText
          }, {
            default: _withCtx(() => _cache[44] || (_cache[44] = [
              _createTextVNode(" Valider ")
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: genderpopup.value,
      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((genderpopup).value = $event)),
      title: "Tips",
      width: "500"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            onClick: _cache[24] || (_cache[24] = ($event: any) => (genderpopup.value = false))
          }, {
            default: _withCtx(() => _cache[45] || (_cache[45] = [
              _createTextVNode("Cancel")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: insertGenderVar
          }, {
            default: _withCtx(() => _cache[46] || (_cache[46] = [
              _createTextVNode(" Confirm ")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: form,
          "label-width": "auto",
          style: {"max-width":"600px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Mot masculin" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: form.male,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((form.male) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Mot féminin" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: form.female,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((form.female) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Mot neutre" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: form.neutral,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((form.neutral) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})