<template>
  <div class="first-slide">
    <h2>{{ $t("catalog.title.main") }}</h2>
    <el-row :gutter="30" v-loading="loading" class="story-list">
      <el-col :span="8" v-for="(item, index) in stories" :key="index">
        <el-card class="full-height">
          <template #header>
            <div class="card-header">
              <span>{{ item.languages[0].title }}</span>
            </div>
          </template>
          <el-popover
            placement="top-start"
            :width="400"
            trigger="hover"
            :content="item.languages[0].description"
          >
            <template #reference>
              <div class="text-justify">
                {{ $truncateText(item.languages[0].description, 200) }}
              </div>
            </template>
          </el-popover>
          <div class="card-footer text-right">
            <el-button @click="startReading(item.id)">
              {{ $t("catalog.button.startReading") }}
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { HTTP } from "@/http-common";
import router from "@/router";

const stories = ref<any>();
const loading = ref(false);

const startReading = (id: number) => {
  HTTP.post("readings", {
    userId: 0,
    storyId: id,
  }).then(() => {
    HTTP.post("read", {
      storyId: id,
    }).then(() => {
      router.push({ name: "reader", params: { storyId: id } });
    });
  });
};

onMounted(async () => {
  loading.value = true;
  const response = await HTTP.get("stories");
  if (response.data.length > 0) {
    stories.value = response.data
      .map((item: { id: any; languages: any[] }) => ({
        id: item.id,
        languages: item.languages.filter((lang) => lang.langCode === "fr"),
      }))
      .filter(
        (item: { languages: string | any[] }) => item.languages.length > 0
      );
  }
  loading.value = false;
});
</script>
