<template>
  <div id="home">
    <div class="first-slide white-color">
      <div class="text-wrapper text-uppercase">
        <h2>Livre arbitre</h2>
      </div>
      <div class="text-wrapper centered">
        <h1>Soyez le héros de vos histoires</h1>
      </div>
    </div>
    <div class="second-slide slide">
      <div>
        <h2 class="text-wrapper text-uppercase">Les histoires</h2>
        <el-row :gutter="30" justify="center">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="8"
            v-for="(item, index) in slide2items"
            :key="index"
          >
            <el-card class="full-height">
              <template #header>
                <div class="card-header">
                  <span>{{ item.title }}</span>
                </div>
              </template>
              <div>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-avatar shape="square" :size="80" :src="item.image" />
                  </el-col>
                  <el-col :span="16" class="text-justify">{{
                    item.content
                  }}</el-col>
                </el-row>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="thrid-slide slide">
      <div class="text-wrapper white-color">
        <h2 class="black-shadow text-uppercase">Les outils</h2>
        <el-row
          :gutter="20"
          justify="center"
          style="padding-right: 10px; padding-left: 10px"
        >
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="6"
            :xl="6"
            v-for="(item, index) in slide3items"
            :key="index"
          >
            <el-card class="full-height tools-card">
              <div>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-avatar
                      shape="circle"
                      :size="90"
                      :src="item.image"
                      class="avatar"
                    />
                  </el-col>
                  <el-col :span="24">
                    {{ item.title }}
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col class="text-justify">
                    {{ item.content }}
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="fourth-slide slide">
      <div class="slide4-wrapper">
        <el-row :gutter="0">
          <el-col
            class="event_photo"
            :xs="0"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          ></el-col>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
            class="event_desc"
          >
            <h2 class="text-uppercase">Les evenements</h2>
            <div class="text-justify">
              <p>
                Livre Arbitre est une solution que vous pouvez aussi utiliser à
                des fins évènementielles.
              </p>
              <p>
                Vous souhaitez organiser un évènement dans votre ville, faire
                découvrir un lieu ou organiser un aventure immersive pour la
                sortie d'un livre, d'un film ou d'autre chose ?
              </p>
              <p>
                Vous souhaitez mettre en place un ice breaker pour un mariage,
                une conférence, ou n'importe quel évenement familial ou
                professionnel ?
              </p>
              <p>
                En utilisant Livre Arbitre vous pourrez proposer à toutes ces
                situations une expérience immersive, originale et ludique, qui
                sera avant tout personnalisée et répondant à vos besoins.
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

// @Options({
//   components: {
//     HelloWorld,
//   },
// })

interface Item {
  title: string;
  content: string;
  image: string;
}

export default class HomeView extends Vue {
  slide3items: Item[] = [
    {
      title: "Multilingue",
      content:
        "La gestion des différentes langues est prise en charge nativement. Ecrivez simplement vos histoires dans les langues dans lesquelles vous souhaitez les rendre disponibles.",
      image: require("@/assets/icons/trad.svg"),
    },
    {
      title: "Facile à prendre ne main",
      content:
        "Nul besoin de savoir coder, savoir écrire et utiliser une souris vous suffira. Nos outils sont pensés pour répondre à vos besoins de la plus simple des manières.",
      image: require("@/assets/icons/easy.svg"),
    },
    {
      title: "Audience globale ou restreinte",
      content:
        "Que vous souhaitiez diffuser vos histoires à toute la communauté, partager vos écrits à quelques invités ou encore créer une aventure de type JdR pour la partager avec vos amis, c'est vous qui choisissez.",
      image: require("@/assets/icons/private.svg"),
    },
    {
      title: "Evenements suivant la localisation",
      content:
        "Vous souhaitez déclencher un évènement près d'un lieu ou lorsque le lecture a parcouru une certaine distance ? Nos outils vous le permettent et vos histoires seront encore plus immersives.",
      image: require("@/assets/icons/localisation.svg"),
    },
  ];
  slide2items: Item[] = [
    {
      title: "Différents types d'histoires",
      content:
        "Plusieurs formats d'histoire vous seront proposés, de l'histoire horrifique à l'histoire policière en passant par du fantastique ou encore de la fantasy !",
      image: require("@/assets/icons/books.svg"),
    },
    {
      title: "Interactifs",
      content:
        "Vous avez le contrôle sur l'histoire que vous lisez et elle évoluera en fonction de vos décisions",
      image: require("@/assets/icons/interactive.svg"),
    },
    {
      title: "Echanges types SMS",
      content:
        "Pour certaines histoires vous pourrez etre amenés à échanger des messages avec les protagonistes et à attendre leurs réponses, ou pas...",
      image: require("@/assets/icons/sms.svg"),
    },
    {
      title: "Différentes branches narratives",
      content:
        "Vos choix ont des répercussions. Choisir, c'est renoncer et parfois vous ne pourrez explorer certains scnéarios qu'en renonçant à certains possibilités.",
      image: require("@/assets/icons/branch.svg"),
    },
    {
      title: "Résolvez des énigmes",
      content:
        "Venez résoudre des mystères, des casses tête, des énigmes en tout genre, avec pour seules limites celles des auteurs. Laissez vous surprendre et venez découvrir de nouveaux univers.",
      image: require("@/assets/icons/puzzle.svg"),
    },
    {
      title: "Le temps passe",
      content:
        "Dans certaines histoires vous découvrirez que le monde continue d'avancer même quand vous dormez. Laissez les protagonistes venir vous surprendre à n'importe quel moment de la journée.",
      image: require("@/assets/icons/time.svg"),
    },
    {
      title: "Lisez et jouez à plusieurs",
      content:
        "L'interactivité n'est pas la seule force des histoires que vous viendrez lire, car vous pourrez aussi les vivre à plusieurs, avec des amis ou de parfaits inconnus.",
      image: require("@/assets/icons/multi.svg"),
    },
    // { title: "", content: "" },
  ];
}
</script>
<style lang="scss" scoped>
// Style de votre composant
#home {
  min-height: calc(100vh - 60px);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide {
  padding-top: 30px;
}

.second-slide {
  background-color: white;
  min-height: 100vh; //calc(100vh - 60px);
  padding-left: 20px;
  padding-right: 20px;
}

.thrid-slide {
  min-height: 100vh; //calc(100vh - 60px);
  background-image: url("../assets/background/slide3.png");
  background-size: cover; // Couvre entièrement la zone du composant, peut-être ajusté selon besoin
  background-position: center; // Centrer l'image de fond
  //background-position: bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;

  // padding: 30px;
}

.fourth-slide {
  background-color: white;
  min-height: 100vh; //calc(100vh - 60px);
  padding-top: 0px !important;
}

.event_photo {
  background-image: url("../assets/background/event.jpg");
  background-size: cover; // Couvre entièrement la zone du composant, peut-être ajusté selon besoin
  background-position: center; // Centrer l'image de fond
}
.event_desc {
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.slide4-wrapper > .el-row {
  min-height: 100%;
}

.text-wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  // padding-left: 15px;
  // padding-right: 15px;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 65px;
}
h2 {
  font-size: 30px;
}

.el-row {
  margin-bottom: 40px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  // border-radius: 4px;
  margin-bottom: 40px;
}

.el-avatar {
  --el-avatar-bg-color: var(--el-color-white) !important;
}
.avatar {
  padding: 8px;
  box-shadow: 0 0 15px rgba(139, 69, 19, 0.5);
  background-color: rgba(139, 69, 19, 0.3);
}

.black-shadow {
  text-shadow: 1px 1px 2px black;
}

.tools-card {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.4) !important;
  border-radius: 15px !important;
}
</style>
