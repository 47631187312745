<template>
  <div class="first-slide">
    <h2>{{ $t("shared.title.main") }}</h2>
    <div v-loaging="loading" class="story-list">
      <el-empty
        description="Vous n'avez pas d'invitations en cours"
        v-if="readings.length == 0"
      />
      <el-row :gutter="30" v-else>
        <el-col :span="8" v-for="(reading, index) in readings" :key="index">
          <el-card class="full-height">
            <template #header>
              <div class="card-header text-left">
                <span>{{ reading.name }}</span>
              </div>
            </template>
            <p class="text item">
              {{ reading.description }}
            </p>
            <div class="card-footer text-right">
              <el-button @click="startReading(reading.storyId)">
                {{ $t("catalog.button.startReading") }}
              </el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { HTTP } from "@/http-common";
import router from "@/router";

interface reading {
  name: string;
  storyId: number;
  description: string;
}

const loading = ref(false);
const readings = ref<reading[]>([]);

const startReading = (id: number) => {
  HTTP.post("readings", {
    userId: 0,
    storyId: id,
  }).then(() => {
    HTTP.post("read", {
      storyId: id,
    }).then(() => {
      router.push({ name: "reader", params: { storyId: id } });
    });
  });
};

onMounted(() => {
  loading.value = true;
  HTTP.get("/shared-stories").then((response) => {
    console.log(response);
    for (var i = 0; i < response.data.length; i++) {
      readings.value.push({
        name: response.data[i].languages[0].title,
        storyId: response.data[i].id,
        description: response.data[i].languages[0].description,
      });
    }
    loading.value = true;
  });
});
</script>
