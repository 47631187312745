<template>
  <div class="first-slide">
    Merci d'avoir activé votre compte : {{ props.activationKey }}
  </div>
</template>
<script lang="ts" setup>
import { defineProps, onMounted } from "vue";
import { HTTP } from "@/http-common";

interface ActivationProps {
  activationKey: string | null; // Par exemple, l'id peut être une chaîne ou null
}

const props = defineProps<ActivationProps>();

onMounted(async () => {
  // Écoutez les événements de sélection de l'éditeur
  HTTP.put("users/activation/", {
    activationKey: props.activationKey,
  });
});
</script>
