export class Options {
  public zoom: number;
  public zoom_max: number;
  public zoom_min: number;
  public zoom_value = 0.05;
  public zoom_last_value: number;

  constructor(initialZoom = 1, zoomMax = 2, zoomMin = 0.01) {
    this.zoom = initialZoom;
    this.zoom_max = zoomMax;
    this.zoom_min = zoomMin;
    this.zoom_last_value = this.zoom;
  }

  zoom_in() {
    if (this.zoom < this.zoom_max) {
      this.zoom += this.zoom_value;
    }
  }
  zoom_out() {
    if (this.zoom > this.zoom_min) {
      this.zoom -= this.zoom_value;
    }
  }
}
