<template>
  <div
    class="first-slide"
    v-loading="loading"
    element-loading-background="rgba(122, 122, 122, 0.8)"
  >
    <h2 class="text-left">Liste de vos oeuvres</h2>
    <div class="stories-container">
      <el-row :gutter="30">
        <el-col
          :span="12"
          v-for="(item, index) in data"
          :key="index"
          style="margin-bottom: 30px"
        >
          <el-card class="story-card full-height">
            <el-row>
              <el-col :span="24">
                <div class="align-right">
                  <router-link
                    :to="{ name: 'update', params: { storyId: item.id } }"
                  >
                    <el-button type="primary" :icon="Edit" circle />
                  </router-link>
                  <router-link
                    :to="{ name: 'steps', params: { storyId: item.id } }"
                  >
                    <el-button
                      type="primary"
                      :icon="FolderOpened"
                      circle
                      style="margin-left: 12px"
                    />
                  </router-link>
                  <el-button
                    type="warning"
                    :icon="Hide"
                    circle
                    style="margin-left: 12px"
                  />
                  <el-button
                    type="danger"
                    :icon="Delete"
                    circle
                    @click="deleteStory(item.id)"
                  />
                </div>
              </el-col>
              <el-col :span="24">
                <h4>{{ item.name }}</h4>
              </el-col>
              <div class="text-justify">
                {{
                  reduceText(
                    item.languages[item.selectedLangIndex].description,
                    250
                  )
                }}
              </div>
              <el-col :span="24">
                <div>
                  <el-tag
                    v-for="(tag, index) in item.languages"
                    :key="tag.langCode"
                    :type="item.selectedLangIndex == index ? 'success' : 'info'"
                    round
                    @click="item.selectedLangIndex = index"
                    :checked="item.selectedLangIndex == index"
                    :class="item.selectedLangIndex == index ? '' : 'clickable'"
                  >
                    {{ tag.langCode }}
                  </el-tag>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
  <el-dialog v-model="dialogDeleteVisible" title="Suppression" width="30%">
    <span>Etes vous sûr de vouloir supprimer cette histoire ?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">Annuler</el-button>
        <el-button type="danger" @click="onDelete">Supprimer</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { HTTP } from "../http-common";
import { ref, onMounted } from "vue";
import { Hide, Delete, Edit, FolderOpened } from "@element-plus/icons-vue";

interface SubItem {
  title: string;
  description: string;
  langCode: string;
  state: string;
}

interface Item {
  name: string;
  id: number;
  languages: SubItem[];
  selectedLangIndex: number;
}

const data = ref<Item[]>([]);
const loading = ref(false);
const dialogDeleteVisible = ref(false);
const deleteId = ref(0);

const reduceText = (texte: string, longueurMax: number): string => {
  if (texte.length > longueurMax) {
    return texte.substring(0, longueurMax - 3) + "...";
  } else {
    return texte;
  }
};

const deleteStory = (storyId: number) => {
  deleteId.value = storyId;
  dialogDeleteVisible.value = true;
};

const onDelete = () => {
  if (deleteId.value != 0) {
    HTTP.delete("author/stories/" + deleteId.value).then(() => {
      dialogDeleteVisible.value = false;
      deleteId.value = 0;
      try {
        loading.value = true;
        HTTP.get("author/stories").then((response) => {
          data.value = response.data;
          for (var i = 0; i < data.value.length; i++) {
            data.value[i].selectedLangIndex = 0;
          }
          loading.value = false;
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
        loading.value = false;
      }
    });
  }
};

onMounted(async () => {
  try {
    loading.value = true;
    const response = await HTTP.get("author/stories");
    data.value = response.data;
    for (var i = 0; i < data.value.length; i++) {
      data.value[i].selectedLangIndex = 0;
    }
    loading.value = false;
  } catch (error) {
    console.error("Erreur lors de la récupération des données:", error);
    loading.value = false;
  }
});
</script>
<style lang="scss" scoped>
h4 {
  margin-top: 0;
}
.text-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.stories-container {
  padding-left: 30px;
  padding-right: 30px;
}
.story-card {
  //margin-bottom: 35px;
}
</style>
