<template>
  <el-menu default-active="2" class="el-menu-vertical-demo">
    <el-menu-item index="1" :disabled="isMenuDisable">
      <router-link :to="{ name: 'update', params: { storyId: props.storyId } }">
        <el-icon><Notebook /></el-icon>
        <span>{{ t("sidemenu.general") }}</span>
      </router-link>
    </el-menu-item>
    <el-menu-item-group title="Etapes">
      <el-menu-item index="1-1">
        <router-link
          :to="{ name: 'steps', params: { storyId: props.storyId } }"
        >
          <svg
            width="24px"
            height="18px"
            viewBox="0 0 24 24"
            fill="none"
            style="margin-right: 5px"
          >
            <path
              d="M13 17C13 15.1144 13 14.1716 13.5858 13.5858C14.1716 13 15.1144 13 17 13H18C19.8856 13 20.8284 13 21.4142 13.5858C22 14.1716 22 15.1144 22 17C22 18.8856 22 19.8284 21.4142 20.4142C20.8284 21 19.8856 21 18 21H17C15.1144 21 14.1716 21 13.5858 20.4142C13 19.8284 13 18.8856 13 17Z"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <path
              d="M11.5 11.5V8.5M11.5 11.5H8.5M11.5 11.5L7.5 7.5"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11 21H10C6.22876 21 4.34315 21 3.17157 19.8284C2 18.6569 2 16.7712 2 13V11M22 11C22 7.22876 22 5.34315 20.8284 4.17157C19.6569 3 17.7712 3 14 3H10C6.22876 3 4.34315 3 3.17157 4.17157C2.51839 4.82475 2.22937 5.69989 2.10149 7"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          Liste des étapes
        </router-link>
      </el-menu-item>
      <el-menu-item index="1-2">
        <router-link :to="{ name: 'step', params: { storyId: props.storyId } }">
          <el-icon><DocumentAdd /></el-icon>
          Nouvelle étape
        </router-link>
      </el-menu-item>
    </el-menu-item-group>
    <el-menu-item-group title="Evenements">
      <el-menu-item index="1-3">
        <router-link
          :to="{ name: 'events', params: { storyId: props.storyId } }"
        >
          <!-- <el-icon><List /></el-icon> -->
          <svg
            width="24px"
            height="18px"
            viewBox="0 0 24 24"
            fill="currentColor"
            style="margin-right: 5px"
          >
            <path
              fill-rule="evenodd"
              d="M20,8 L20,5 L18,5 L18,6 L16,6 L16,5 L8,5 L8,6 L6,6 L6,5 L4,5 L4,8 L20,8 Z M20,10 L4,10 L4,20 L20,20 L20,10 Z M18,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,5 C2,3.8954305 2.8954305,3 4,3 L6,3 L6,2 L8,2 L8,3 L16,3 L16,2 L18,2 L18,3 Z M11,15.5857864 L15.2928932,11.2928932 L16.7071068,12.7071068 L11,18.4142136 L7.29289322,14.7071068 L8.70710678,13.2928932 L11,15.5857864 Z"
            />
          </svg>
          Liste d'évènements
        </router-link>
      </el-menu-item>
      <el-menu-item index="1-4">
        <router-link
          :to="{ name: 'event', params: { storyId: props.storyId } }"
        >
          <el-icon><DocumentAdd /></el-icon>
          Nouvel évènement
        </router-link>
      </el-menu-item>
    </el-menu-item-group>
    <el-menu-item-group title="Déclencheurs">
      <el-menu-item index="3-1">
        <router-link
          :to="{ name: 'triggers', params: { storyId: props.storyId } }"
        >
          <!-- <el-icon><List /></el-icon> -->
          <svg
            width="24px"
            height="18px"
            viewBox="0 0 24 24"
            fill="currentColor"
            style="margin-right: 5px"
          >
            <path
              fill-rule="evenodd"
              d="M20,8 L20,5 L18,5 L18,6 L16,6 L16,5 L8,5 L8,6 L6,6 L6,5 L4,5 L4,8 L20,8 Z M20,10 L4,10 L4,20 L20,20 L20,10 Z M18,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,5 C2,3.8954305 2.8954305,3 4,3 L6,3 L6,2 L8,2 L8,3 L16,3 L16,2 L18,2 L18,3 Z M11,15.5857864 L15.2928932,11.2928932 L16.7071068,12.7071068 L11,18.4142136 L7.29289322,14.7071068 L8.70710678,13.2928932 L11,15.5857864 Z"
            />
          </svg>
          Liste de déclencheurs
        </router-link>
      </el-menu-item>
      <el-menu-item index="3-2">
        <router-link
          :to="{ name: 'trigger', params: { storyId: props.storyId } }"
        >
          <!-- <el-icon><List /></el-icon> -->
          <svg
            width="24px"
            height="18px"
            viewBox="0 0 24 24"
            fill="currentColor"
            style="margin-right: 5px"
          >
            <path
              fill-rule="evenodd"
              d="M20,8 L20,5 L18,5 L18,6 L16,6 L16,5 L8,5 L8,6 L6,6 L6,5 L4,5 L4,8 L20,8 Z M20,10 L4,10 L4,20 L20,20 L20,10 Z M18,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,5 C2,3.8954305 2.8954305,3 4,3 L6,3 L6,2 L8,2 L8,3 L16,3 L16,2 L18,2 L18,3 Z M11,15.5857864 L15.2928932,11.2928932 L16.7071068,12.7071068 L11,18.4142136 L7.29289322,14.7071068 L8.70710678,13.2928932 L11,15.5857864 Z"
            />
          </svg>
          Nouvelle variable
        </router-link>
      </el-menu-item>
    </el-menu-item-group>
    <el-menu-item index="1-4">
      <el-icon><DocumentAdd /></el-icon>
      Fiches de personnage
    </el-menu-item>
    <el-menu-item index="1-4">
      <el-icon><DocumentAdd /></el-icon>
      Inventaire
    </el-menu-item>
    <el-menu-item index="1-4">
      <el-icon><DocumentAdd /></el-icon>
      Règles
    </el-menu-item>
  </el-menu>
</template>
<script lang="ts" setup>
import { ref, defineProps } from "vue";
import { Notebook, List, DocumentAdd } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}

const props = defineProps<StoryProps>();

const isMenuDisable = ref(false);
</script>
<style lang="scss" scoped>
:root {
  --el-menu-hover-bg-color: black !important;
}
.el-menu {
  background-color: var(--menu-background-color);
}
.el-menu-item a {
  color: var(--menu-text-color) !important;
}
.el-menu-item * {
  vertical-align: middle;
}
.el-menu-item:hover {
  background-color: var(
    --menu-background-color-hover
  ); //var(--el-menu-hover-bg-color);
}
.el-menu-item a.router-link-active {
  //router-link-exact-active
  color: var(--menu-active-entry) !important;
}
.align-left {
  text-align: left !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: calc(100vh - 60px);
}

.el-menu-vertical-demo {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
}

.el-menu-vertical-demo::-webkit-scrollbar {
  display: none; /* Chrome, Safari et Edge */
}
</style>
