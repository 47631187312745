import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "first-slide" }
const _hoisted_2 = { class: "card-header text-left" }
const _hoisted_3 = { class: "text item" }
const _hoisted_4 = { class: "card-footer text-right" }

import { ref, onMounted } from "vue";
import { HTTP } from "@/http-common";

interface reading {
  name: string;
  storyId: number;
  startReading: Date;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadingsView',
  setup(__props) {

const loading = ref(false);
const readings = ref<reading[]>([]);

onMounted(() => {
  loading.value = true;
  HTTP.get("readings").then((response) => {
    for (var i = 0; i < response.data.length; i++) {
      readings.value.push({
        name: response.data[i].story.languages[0].title,
        storyId: response.data[i].story.id,
        startReading: new Date(response.data[i].startReadingTime),
      });
    }
    console.log(readings.value);
    loading.value = true;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_loaging = _resolveDirective("loaging")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t("readings.title.main")), 1),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createVNode(_component_el_row, { gutter: 30 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(readings.value, (reading, index) => {
            return (_openBlock(), _createBlock(_component_el_col, {
              span: 8,
              key: index
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_card, { class: "full-height" }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("span", null, _toDisplayString(reading.name), 1)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_3, " Lecture debutée : " + _toDisplayString(reading.startReading.toLocaleDateString()), 1),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_router_link, {
                        to: { name: 'reader', params: { storyId: reading.storyId } }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_button, { type: "primary" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("readings.followup")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ])), [
      [_directive_loaging, loading.value]
    ])
  ]))
}
}

})