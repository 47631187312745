<template>
  <el-dialog
    :model-value="modelValue"
    title="Préinscriptions"
    :width="dialogSize"
    @close="closeDialog"
  >
    <div v-loading="loginLoading">
      <div>
        Livre arbitre ouvre bientôt ses portes !<br />
        Si tu veux être parmi les premiers informés, inscris-toi !
      </div>
      <el-form
        :model="localPreregister"
        label-width="50px"
        :label-position="labelPosition"
        style="margin-top: 30px"
      >
        <el-form-item label="Email">
          <el-input v-model="localPreregister.Login" />
        </el-form-item>
        <div>
          <el-button @click="closeDialog">Annuler</el-button>
          <el-button type="primary" @click="onPreregister"
            >Se préinscrire</el-button
          >
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, toRefs } from "vue";

const props = defineProps({
  modelValue: Boolean,
  dialogSize: {
    type: String,
    default: "30%",
  },
  loginLoading: Boolean,
  preregister: Object,
  labelPosition: {
    type: String,
    default: "top",
  },
});

const emit = defineEmits(["update:modelValue", "submit"]);

// Crée une copie locale de l'objet preregister pour éviter de muter la prop
const { preregister } = toRefs(props);
const localPreregister = ref({ ...preregister.value });

const closeDialog = () => {
  emit("update:modelValue", false);
};

const onPreregister = () => {
  emit("submit", localPreregister.value); // Si tu veux passer les données
};
</script>

<style scoped>
/* Ajoutez du style personnalisé si nécessaire */
</style>
