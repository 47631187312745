import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AuthorRoot from "../views/AuthorSubLayout.vue";
import StoryView from "../views/StoryView.vue";
import CreateStoryView from "../views/write/CreateStoryView.vue";
import StoriesView from "../views/StoriesView.vue";

import StepsView from "../views/write/StepsView.vue";
import HandleStepView from "../views/HandleStepView.vue";
import CreateEventView from "../views/write/CreateEventView.vue";
import EventsView from "../views/EventsView.vue";
import CreateVariablesView from "../views/write/CreateVariablesView.vue";
import VariablesView from "../views/VariablesView.vue";
import ReadingsView from "../views/read/ReadingsView.vue";
import SharedView from "../views/read/SharedStoriesView.vue";
import CatalogView from "../views/CatalogView.vue";
import ReadView from "../views/ReadView.vue";

import ProfileView from "../views/user/ProfileView.vue";

import ActivationAccount from "../views/user/ActivationAccount.vue";
import TestView from "../views/TestView.vue";
import TestView2 from "../views/TestView2.vue";
import TestView3 from "../views/TestView3.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: "/activation/:activationKey",
    component: ActivationAccount,
    props: true,
  },
  {
    path: "/author",
    name: "authorStories",
    component: StoriesView,
  },
  {
    path: "/create",
    name: "create",
    component: CreateStoryView,
  },
  {
    path: "/story",
    component: AuthorRoot,
    props: true,
    children: [
      {
        path: ":storyId/step/:stepId?",
        name: "step",
        component: HandleStepView,
        props: true,
      },
      {
        path: ":storyId/steps",
        name: "steps",
        component: StepsView,
        props: true,
      },
      {
        path: ":storyId",
        name: "update",
        component: StoryView,
        props: true,
      },
      {
        path: ":storyId/events",
        name: "events",
        component: EventsView,
        props: true,
      },
      {
        path: ":storyId/event/:eventId?",
        name: "event",
        component: CreateEventView,
        props: true,
      },
      {
        path: ":storyId/triggers",
        name: "triggers",
        component: VariablesView,
        props: true,
      },
      {
        path: ":storyId/trigger/:eventId?",
        name: "trigger",
        component: CreateVariablesView,
        props: true,
      },
    ],
  },
  {
    path: "/readings",
    name: "readings",
    component: ReadingsView,
  },
  {
    path: "/shared",
    name: "shared-stories",
    component: SharedView,
  },
  {
    path: "/read/:storyId",
    name: "reader",
    component: ReadView,
    props: true,
  },
  {
    path: "/catalog",
    name: "catalog",
    component: CatalogView,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/test",
    component: TestView,
  },
  {
    path: "/test2",
    component: TestView2,
  },
  {
    path: "/test3",
    component: TestView3,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
