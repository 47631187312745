import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  width: "24px",
  height: "18px",
  viewBox: "0 0 24 24",
  fill: "none",
  style: {"margin-right":"5px"}
}
const _hoisted_2 = {
  width: "24px",
  height: "18px",
  viewBox: "0 0 24 24",
  fill: "currentColor",
  style: {"margin-right":"5px"}
}
const _hoisted_3 = {
  width: "24px",
  height: "18px",
  viewBox: "0 0 24 24",
  fill: "currentColor",
  style: {"margin-right":"5px"}
}
const _hoisted_4 = {
  width: "24px",
  height: "18px",
  viewBox: "0 0 24 24",
  fill: "currentColor",
  style: {"margin-right":"5px"}
}

import { ref } from "vue";
import { Notebook, List, DocumentAdd } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";

interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SideMenu',
  props: {
    storyId: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const isMenuDisable = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "default-active": "2",
    class: "el-menu-vertical-demo"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu_item, {
        index: "1",
        disabled: isMenuDisable.value
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: { name: 'update', params: { storyId: props.storyId } }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Notebook))
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_unref(t)("sidemenu.general")), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_el_menu_item_group, { title: "Etapes" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, { index: "1-1" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'steps', params: { storyId: props.storyId } }
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
                    _createElementVNode("path", {
                      d: "M13 17C13 15.1144 13 14.1716 13.5858 13.5858C14.1716 13 15.1144 13 17 13H18C19.8856 13 20.8284 13 21.4142 13.5858C22 14.1716 22 15.1144 22 17C22 18.8856 22 19.8284 21.4142 20.4142C20.8284 21 19.8856 21 18 21H17C15.1144 21 14.1716 21 13.5858 20.4142C13 19.8284 13 18.8856 13 17Z",
                      stroke: "currentColor",
                      "stroke-width": "1.5"
                    }, null, -1),
                    _createElementVNode("path", {
                      d: "M11.5 11.5V8.5M11.5 11.5H8.5M11.5 11.5L7.5 7.5",
                      stroke: "currentColor",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }, null, -1),
                    _createElementVNode("path", {
                      d: "M11 21H10C6.22876 21 4.34315 21 3.17157 19.8284C2 18.6569 2 16.7712 2 13V11M22 11C22 7.22876 22 5.34315 20.8284 4.17157C19.6569 3 17.7712 3 14 3H10C6.22876 3 4.34315 3 3.17157 4.17157C2.51839 4.82475 2.22937 5.69989 2.10149 7",
                      stroke: "currentColor",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round"
                    }, null, -1)
                  ]))),
                  _cache[1] || (_cache[1] = _createTextVNode(" Liste des étapes "))
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "1-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'step', params: { storyId: props.storyId } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DocumentAdd))
                    ]),
                    _: 1
                  }),
                  _cache[2] || (_cache[2] = _createTextVNode(" Nouvelle étape "))
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item_group, { title: "Evenements" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, { index: "1-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'events', params: { storyId: props.storyId } }
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[3] || (_cache[3] = [
                    _createElementVNode("path", {
                      "fill-rule": "evenodd",
                      d: "M20,8 L20,5 L18,5 L18,6 L16,6 L16,5 L8,5 L8,6 L6,6 L6,5 L4,5 L4,8 L20,8 Z M20,10 L4,10 L4,20 L20,20 L20,10 Z M18,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,5 C2,3.8954305 2.8954305,3 4,3 L6,3 L6,2 L8,2 L8,3 L16,3 L16,2 L18,2 L18,3 Z M11,15.5857864 L15.2928932,11.2928932 L16.7071068,12.7071068 L11,18.4142136 L7.29289322,14.7071068 L8.70710678,13.2928932 L11,15.5857864 Z"
                    }, null, -1)
                  ]))),
                  _cache[4] || (_cache[4] = _createTextVNode(" Liste d'évènements "))
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "1-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'event', params: { storyId: props.storyId } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DocumentAdd))
                    ]),
                    _: 1
                  }),
                  _cache[5] || (_cache[5] = _createTextVNode(" Nouvel évènement "))
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item_group, { title: "Déclencheurs" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, { index: "3-1" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'triggers', params: { storyId: props.storyId } }
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[6] || (_cache[6] = [
                    _createElementVNode("path", {
                      "fill-rule": "evenodd",
                      d: "M20,8 L20,5 L18,5 L18,6 L16,6 L16,5 L8,5 L8,6 L6,6 L6,5 L4,5 L4,8 L20,8 Z M20,10 L4,10 L4,20 L20,20 L20,10 Z M18,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,5 C2,3.8954305 2.8954305,3 4,3 L6,3 L6,2 L8,2 L8,3 L16,3 L16,2 L18,2 L18,3 Z M11,15.5857864 L15.2928932,11.2928932 L16.7071068,12.7071068 L11,18.4142136 L7.29289322,14.7071068 L8.70710678,13.2928932 L11,15.5857864 Z"
                    }, null, -1)
                  ]))),
                  _cache[7] || (_cache[7] = _createTextVNode(" Liste de déclencheurs "))
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "3-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'trigger', params: { storyId: props.storyId } }
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[8] || (_cache[8] = [
                    _createElementVNode("path", {
                      "fill-rule": "evenodd",
                      d: "M20,8 L20,5 L18,5 L18,6 L16,6 L16,5 L8,5 L8,6 L6,6 L6,5 L4,5 L4,8 L20,8 Z M20,10 L4,10 L4,20 L20,20 L20,10 Z M18,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,5 C2,3.8954305 2.8954305,3 4,3 L6,3 L6,2 L8,2 L8,3 L16,3 L16,2 L18,2 L18,3 Z M11,15.5857864 L15.2928932,11.2928932 L16.7071068,12.7071068 L11,18.4142136 L7.29289322,14.7071068 L8.70710678,13.2928932 L11,15.5857864 Z"
                    }, null, -1)
                  ]))),
                  _cache[9] || (_cache[9] = _createTextVNode(" Nouvelle variable "))
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item, { index: "1-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_unref(DocumentAdd))
            ]),
            _: 1
          }),
          _cache[10] || (_cache[10] = _createTextVNode(" Fiches de personnage "))
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item, { index: "1-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_unref(DocumentAdd))
            ]),
            _: 1
          }),
          _cache[11] || (_cache[11] = _createTextVNode(" Inventaire "))
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item, { index: "1-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_unref(DocumentAdd))
            ]),
            _: 1
          }),
          _cache[12] || (_cache[12] = _createTextVNode(" Règles "))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})