<template>
  <el-form :model="form" label-width="180px" v-loading="loading">
    <el-form-item :label="$t('story.name')">
      <el-input v-model="form.name" />
    </el-form-item>
    <div class="text-right">
      <el-button type="primary" @click="onSubmit">{{
        createMode ? $t("story.create") : $t("story.update")
      }}</el-button>
      <el-button @click="onCancel">{{ $t("global.action.cancel") }}</el-button>
    </div>
    <el-tabs type="border-card">
      <el-tab-pane>
        <template #label>
          <el-icon class="title-icon"><Setting /></el-icon>
          <span>{{ $t("story.general.title") }}</span>
        </template>
        <el-form-item :label="$t('story.general.addlangage')">
          <el-button @click="addTab" type="primary" :icon="Plus" circle />
        </el-form-item>
        <el-tabs
          tab-position="left"
          style="min-height: 350px"
          v-model="activeTab"
        >
          <el-tab-pane
            v-for="(tab, index) in tabs"
            :label="tab.lang"
            :key="index"
          >
            <div class="text-left">
              <el-button
                @click="delTab(index)"
                type="danger"
                :icon="Delete"
                round
                :disabled="tabs.length === 1"
              >
                {{ $t("story.general.deletelangage") }}
              </el-button>
            </div>
            <el-form :model="tab" label-width="120px">
              <el-form-item :label="$t('story.general.langage')">
                <el-input v-model="tab.lang" maxlength="2"></el-input>
              </el-form-item>
              <el-form-item :label="$t('story.general.translated')">
                <el-input
                  v-model="tab.title"
                  maxlength="100"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('story.general.description')">
                <el-input
                  v-model="tab.description"
                  :rows="4"
                  type="textarea"
                  placeholder="Please add a description"
                />
              </el-form-item>
              <el-form-item :label="$t('story.publish.state.title')">
                <el-radio-group v-model="tab.publish">
                  <el-radio-button value="Draft">
                    {{ $t("story.publish.state.private") }}
                  </el-radio-button>
                  <el-radio-button value="Restricted">
                    {{ $t("story.publish.state.shared") }}
                  </el-radio-button>
                  <el-radio-button value="Published">
                    {{ $t("story.publish.state.public") }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <div v-if="tab.publish == 'Restricted'">
                <div class="text-justify">
                  {{ $t("story.publish.share.title") }}
                </div>
                <el-row class="shared-users">
                  <el-col :span="8">
                    <el-form-item label-position="top">
                      <el-input
                        v-model="newTag"
                        style="
                          width: 95%;
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                        :placeholder="$t('story.publish.share.input')"
                      >
                        <template #append>
                          <el-button
                            :icon="CirclePlus"
                            @click="addEmailToShare(tab.lang)"
                            :disabled="!isInputTagValid()"
                          />
                        </template>
                      </el-input>
                    </el-form-item>
                    <div class="warn-message">
                      {{ $t("story.publish.share.info") }}
                    </div>
                  </el-col>
                  <el-col :span="16" class="text-justify">
                    <el-tag
                      v-for="email in tab.sharedEmails"
                      :key="email.name"
                      closable
                      :type="email.type"
                      round
                      @close="console.log('close tab')"
                    >
                      {{ email.name }}
                    </el-tag>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane disabled>
        <template #label>
          <el-icon class="title-icon"><Operation /></el-icon>
          {{ $t("story.parameters.title") }}
        </template>
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item label="Instant delivery">
            <el-switch />
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane disabled>
        <template #label>
          <el-icon class="title-icon"><PieChart /></el-icon>
          {{ $t("story.dashboard.title") }}
        </template>
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>

<script lang="ts" setup>
import { reactive, ref, defineProps, onMounted } from "vue";
import { HTTP } from "../../http-common";
import { ElNotification } from "element-plus";
import {
  Delete,
  Plus,
  Setting,
  Operation,
  Open,
  CirclePlus,
  PieChart,
} from "@element-plus/icons-vue";
import router from "../../router";
import { useI18n } from "vue-i18n";

const createMode = ref(false);
const newTag = ref("");

interface StoryProps {
  storyId: string | null; // Par exemple, l'id peut être une chaîne ou null
}
interface SharedEmail {
  name: string;
  type: string | "success";
}

const props = defineProps<StoryProps>();

// do not use same name with ref
const form = reactive({
  name: "",
  region: "",
  date1: "",
  date2: "",
  delivery: false,
  type: [],
  resource: "",
  desc: "",
});

const activeTab = ref("0");
const loading = ref(false);

const tabs = reactive([
  {
    lang: "en",
    title: "",
    description: "",
    publish: "Draft",
    sharedEmails: [] as SharedEmail[],
  },
]);

const isInputTagValid = () => {
  if (newTag.value != "") {
    return true;
  }
  return false;
};

const addEmailToShare = (targetLang: string) => {
  const foundItem = tabs.find((item) => item.lang === targetLang);
  if (foundItem) {
    foundItem.sharedEmails.push({
      name: newTag.value,
      type: "success",
    });
  }
  newTag.value = "";
};

const addTab = () => {
  const newTabIndex = tabs.length;
  const newTab = {
    // lang: `Onglet ${newTabIndex + 1}`,
    lang: "en",
    title: "",
    description: "",
    publish: "Draft",
    sharedEmails: [],
  };
  tabs.push(newTab);
  activeTab.value = newTabIndex.toString();
};

const delTab = (index: number) => {
  if (tabs.length > 1) {
    tabs.splice(index, 1);
    // Sélectionnez le dernier onglet si l'onglet actif est supprimé
    activeTab.value = Math.min(index, tabs.length - 1).toString();
  }
};

function convertTabsToFormat() {
  return tabs.map((tab) => {
    return {
      langCode: tab.lang,
      title: tab.title,
      description: tab.description,
      state: tab.publish,
      sharedEmails: tab.sharedEmails.map(
        (email: { name: string }) => email.name
      ),
    };
  });
}

function convertResponseToTabs(response: { languages: string | any[] }) {
  tabs.splice(0, tabs.length);
  for (var i = 0; i < response.languages.length; i++) {
    var item = response.languages[i];
    tabs.push({
      lang: item.langCode,
      title: item.title,
      description: item.description,
      publish: item.state, // TODO à modifier
      sharedEmails: item.sharedEmails.map((name: string) => ({
        name: name,
        type: "success",
      })),
    });
  }
}

function handleSuccess(redirectRoute: string) {
  loading.value = false;
  router.push({ name: redirectRoute });
}
const { t } = useI18n();
function handleError(error: any, title: string, message: string) {
  loading.value = false;
  if (
    error.response &&
    (error.response.status == 401 || error.response.status == 403)
  ) {
    // Notification en cas d'erreur (droits insuffisants)
    ElNotification({
      title: title,
      message: message,
      type: "error",
      showClose: false,
    });
  } else if (error.response && error.response.status == 404) {
    // Notification en cas d'erreur (droits insuffisants)
    ElNotification({
      title: title,
      message: t("story.error.notfound"),
      type: "error",
      showClose: false,
    });
  }
}

function convertToQuery(id: string | null) {
  if (id != null) {
    return {
      id: id,
      name: form.name,
      languages: convertTabsToFormat(),
    };
  }
  return {
    name: form.name,
    languages: convertTabsToFormat(),
  };
}

function createStory() {
  const queryObject = convertToQuery(null);
  loading.value = true;
  HTTP.post("author/stories", queryObject)
    .then(() => {
      handleSuccess("authorStories");
    })
    .catch(function (error) {
      handleError(
        error,
        t("story.error.create.title"),
        t("story.error.create.message")
      );
    });
}

function updateStory() {
  loading.value = true;
  var queryObject = convertToQuery(props.storyId);
  HTTP.put("author/stories", queryObject)
    .then(() => {
      handleSuccess("authorStories");
    })
    .catch(function (error) {
      handleError(
        error,
        t("story.error.update.title"),
        t("story.error.update.message")
      );
    });
}

const onCancel = () => {
  router.push({ name: "authorStories" });
};

const onSubmit = () => {
  if (createMode.value) {
    createStory();
  } else {
    updateStory();
  }
};

onMounted(async () => {
  if (props.storyId) {
    loading.value = true;
    const response = await HTTP.get("author/stories/" + props.storyId);
    form.name = response.data.name;
    convertResponseToTabs(response.data);
    loading.value = false;
  } else {
    createMode.value = true;
  }
});
</script>

<style>
.demo-tabs > .el-tabs__content {
  padding-top: 5px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}
.title-icon {
  margin-right: 5px;
}
.story-form {
  width: 100%;
}
.text-justify {
  text-align: justify;
}
.text-right {
  text-align: right;
}
.shared-users {
  margin-top: 25px;
}
.warn-message {
  border: 1px solid #fffed7;
  background-color: #fffeec;
  border-radius: 5px;
  font-style: italic;
  font-size: 80%;
  margin: 10px;
  padding: 5px;
}
</style>
