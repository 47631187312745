import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

import { ref, computed, reactive } from "vue";
import { HTTP } from "@/http-common";
import { useUserStore } from "@/store/user";
import { ElNotification } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthDialog',
  props: {
  dialogVisible: {
    type: Boolean,
    required: true,
  },
  dialogSize: {
    type: String,
    default: "50%",
  },
  registerOpen: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:dialogVisible", "login", "register"],
  setup(__props, { emit: __emit }) {

const userStore = useUserStore();
const user = computed(() => userStore.user);

const activeName = ref("connect");

// Props qui viennent du composant parent
const props = __props;
const loginLoading = ref(false);

// Événements à émettre vers le composant parent
const emit = __emit;

// Modèles locaux pour gérer l'état du formulaire
const logtosite = reactive({
  login: "",
  password: "",
});

const register = reactive({
  Login: "",
  Password: "",
  Password2: "",
  Firstname: "",
  Lastname: "",
  Birthdate: "",
  Gender: "0",
});

// Méthodes pour fermer la boîte de dialogue
const closeDialog = () => {
  emit("update:dialogVisible", false);
};

const onLogin = async () => {
  loginLoading.value = true;
  const success = await userStore.login(logtosite.login, logtosite.password);
  loginLoading.value = false;
  if (!success) {
    // Gérer l'erreur de connexion
    loginError();
  } else {
    emit("update:dialogVisible", false);
  }
  emit("login", logtosite);
};

const onRegister = async () => {
  if (!register.Password || register.Password.trim() === "") {
    ElNotification({
      title: "Mot de passe vide",
      message: "Merci de remplir le mot de passe",
      type: "warning",
      showClose: false,
    });
  } else if (!register.Password2 || register.Password2.trim() === "") {
    ElNotification({
      title: "Mot de passe vide",
      message: "Merci de saisir le second champ mot de passe",
      type: "warning",
      showClose: false,
    });
  } else if (register.Password2 != register.Password) {
    ElNotification({
      title: "Mots de passe différents",
      message:
        "Assurez vous que les deux mots de passe saisis soient identiques",
      type: "error",
      showClose: false,
    });
  } else if (
    !register.Password ||
    !register.Password2 ||
    !register.Lastname ||
    !register.Firstname ||
    !register.Login ||
    !register.Birthdate
  ) {
    ElNotification({
      title: "Merci de renseigner tous les champs",
      message:
        "Un ou plusieurs champs du formulaire n'ont pas été remplis. Assurez vosu de renseigner tous les champs",
      type: "error",
      showClose: false,
    });
  } else {
    var request = {
      login: register.Login,
      password: register.Password,
      birthdate: register.Birthdate,
      firstname: register.Firstname,
      lastname: register.Lastname,
      gender: +register.Gender,
    };
    HTTP.post("/users", request)
      .then(() => {
        ElNotification({
          title: "Merci de vous etre inscrit",
          message:
            "Un email vient de vous etre envoyé à l'adresse renseignée, vous y trouverez le lien pour activer votre compte",
          type: "info",
          showClose: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          ElNotification({
            title: "Une erreur est survenue",
            message:
              "Votre inscription a échouée. Si un compte existe déjà avec votre email, essayer de modifier votre mot de passe",
            type: "info",
            showClose: false,
          });
        }
      });
  }
  emit("register", register);
};

const loginError = () => {
  ElNotification({
    title: "Erreur de connexion",
    message:
      "Vos données de connexion sont erronées. Merci d'essayer de nouveau ou de créer un compte.",
    type: "error",
    showClose: false,
  });
};

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (__props.registerOpen)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        "model-value": __props.dialogVisible,
        title: "Connexion / Enregistrement",
        width: __props.dialogSize,
        onClose: closeDialog
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("mainmenu.popup.title.register")), 1),
          _withDirectives((_openBlock(), _createBlock(_component_el_tabs, {
            modelValue: activeName.value,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((activeName).value = $event)),
            class: "demo-tabs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: "Connexion",
                name: "connect"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form, {
                    model: logtosite,
                    "label-width": "120px",
                    "label-position": activeName.value
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, { label: "Login" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: logtosite.login,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((logtosite.login) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Mot de passe" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: logtosite.password,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((logtosite.password) = $event)),
                            type: "password",
                            placeholder: "Veuillez entrer le mot de passe",
                            "show-password": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", null, [
                        _createVNode(_component_el_button, { onClick: closeDialog }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode("Annuler")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: onLogin
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("Connexion")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["model", "label-position"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, {
                label: "S'enregistrer",
                name: "register"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form, {
                    model: register,
                    "label-width": "120px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, { label: "Login" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: register.Login,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((register.Login) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Mot de passe" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: register.Password,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((register.Password) = $event)),
                            type: "password",
                            placeholder: "Veuillez entrer le mot de passe",
                            "show-password": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Encore" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: register.Password2,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((register.Password2) = $event)),
                            type: "password",
                            placeholder: "Veuillez répéter le mot de passe",
                            "show-password": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Prénom" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: register.Firstname,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((register.Firstname) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Nom" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: register.Lastname,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((register.Lastname) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Date de naissance" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_date_picker, {
                            modelValue: register.Birthdate,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((register.Birthdate) = $event)),
                            type: "date",
                            placeholder: "Choisissez une date",
                            size: "default",
                            format: "DD/MM/YYYY"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Genre" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: register.Gender,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((register.Gender) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_option, {
                                label: "Homme",
                                value: "0",
                                default: ""
                              }),
                              _createVNode(_component_el_option, {
                                label: "Femme",
                                value: "1"
                              }),
                              _createVNode(_component_el_option, {
                                label: "Autre",
                                value: "2"
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", null, [
                        _createVNode(_component_el_button, { onClick: closeDialog }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode("Annuler")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: onRegister
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("S'enregistrer")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["model"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])), [
            [_directive_loading, loginLoading.value]
          ])
        ]),
        _: 1
      }, 8, ["model-value", "width"]))
    : _createCommentVNode("", true)
}
}

})