import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "first-slide",
  "element-loading-background": "rgba(122, 122, 122, 0.8)"
}
const _hoisted_2 = { class: "stories-container" }
const _hoisted_3 = { class: "align-right" }
const _hoisted_4 = { class: "text-justify" }
const _hoisted_5 = { class: "dialog-footer" }

import { HTTP } from "../http-common";
import { ref, onMounted } from "vue";
import { Hide, Delete, Edit, FolderOpened } from "@element-plus/icons-vue";

interface SubItem {
  title: string;
  description: string;
  langCode: string;
  state: string;
}

interface Item {
  name: string;
  id: number;
  languages: SubItem[];
  selectedLangIndex: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StoriesView',
  setup(__props) {

const data = ref<Item[]>([]);
const loading = ref(false);
const dialogDeleteVisible = ref(false);
const deleteId = ref(0);

const reduceText = (texte: string, longueurMax: number): string => {
  if (texte.length > longueurMax) {
    return texte.substring(0, longueurMax - 3) + "...";
  } else {
    return texte;
  }
};

const deleteStory = (storyId: number) => {
  deleteId.value = storyId;
  dialogDeleteVisible.value = true;
};

const onDelete = () => {
  if (deleteId.value != 0) {
    HTTP.delete("author/stories/" + deleteId.value).then(() => {
      dialogDeleteVisible.value = false;
      deleteId.value = 0;
      try {
        loading.value = true;
        HTTP.get("author/stories").then((response) => {
          data.value = response.data;
          for (var i = 0; i < data.value.length; i++) {
            data.value[i].selectedLangIndex = 0;
          }
          loading.value = false;
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
        loading.value = false;
      }
    });
  }
};

onMounted(async () => {
  try {
    loading.value = true;
    const response = await HTTP.get("author/stories");
    data.value = response.data;
    for (var i = 0; i < data.value.length; i++) {
      data.value[i].selectedLangIndex = 0;
    }
    loading.value = false;
  } catch (error) {
    console.error("Erreur lors de la récupération des données:", error);
    loading.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-left" }, "Liste de vos oeuvres", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_row, { gutter: 30 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_col, {
                span: 12,
                key: index,
                style: {"margin-bottom":"30px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_card, { class: "story-card full-height" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_router_link, {
                                  to: { name: 'update', params: { storyId: item.id } }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_button, {
                                      type: "primary",
                                      icon: _unref(Edit),
                                      circle: ""
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["to"]),
                                _createVNode(_component_router_link, {
                                  to: { name: 'steps', params: { storyId: item.id } }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_button, {
                                      type: "primary",
                                      icon: _unref(FolderOpened),
                                      circle: "",
                                      style: {"margin-left":"12px"}
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["to"]),
                                _createVNode(_component_el_button, {
                                  type: "warning",
                                  icon: _unref(Hide),
                                  circle: "",
                                  style: {"margin-left":"12px"}
                                }, null, 8, ["icon"]),
                                _createVNode(_component_el_button, {
                                  type: "danger",
                                  icon: _unref(Delete),
                                  circle: "",
                                  onClick: ($event: any) => (deleteStory(item.id))
                                }, null, 8, ["icon", "onClick"])
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createElementVNode("h4", null, _toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("div", _hoisted_4, _toDisplayString(reduceText(
                    item.languages[item.selectedLangIndex].description,
                    250
                  )), 1),
                          _createVNode(_component_el_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.languages, (tag, index) => {
                                  return (_openBlock(), _createBlock(_component_el_tag, {
                                    key: tag.langCode,
                                    type: item.selectedLangIndex == index ? 'success' : 'info',
                                    round: "",
                                    onClick: ($event: any) => (item.selectedLangIndex = index),
                                    checked: item.selectedLangIndex == index,
                                    class: _normalizeClass(item.selectedLangIndex == index ? '' : 'clickable')
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(tag.langCode), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["type", "onClick", "checked", "class"]))
                                }), 128))
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ])), [
      [_directive_loading, loading.value]
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: dialogDeleteVisible.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dialogDeleteVisible).value = $event)),
      title: "Suppression",
      width: "30%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (dialogDeleteVisible.value = false))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Annuler")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: onDelete
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Supprimer")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _cache[5] || (_cache[5] = _createElementVNode("span", null, "Etes vous sûr de vouloir supprimer cette histoire ?", -1))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})