<template>
  <el-container class="first-slide" v-loading="loading">
    <el-main class="reader">
      <div class="readheader text-left">Nom de l'histoire</div>
      <div class="readerBox">
        <div v-html="content"></div>
        <div v-if="links.length > 0">
          Links :
          <div v-for="item in links" :key="item.nextLinkId">
            <span @click="nextStep(item.nextLinkId)" class="clickable"
              >{{ item.displayText }} - Lien : {{ item.nextLinkId }}</span
            >
          </div>
        </div>
        <div></div>
      </div>
    </el-main>
  </el-container>
</template>
<script setup lang="ts">
import { onMounted, defineProps, ref } from "vue";
import { HTTP } from "../http-common";

interface StoryProps {
  storyId: string;
}
const props = defineProps<StoryProps>();

const content = ref("");
const links = ref<Array>([]);
const loading = ref(false);

const nextStep = (nextId: number) => {
  HTTP.put("read", {
    storyId: props.storyId,
    stepId: nextId,
  }).then(() => {
    window.location.reload();
  });
};

onMounted(async () => {
  loading.value = true;
  const response = await HTTP.get("read?storyId=" + props.storyId);
  content.value = response.data.content;
  for (var i = 0; i < response.data.nextLinks.length; i++) {
    links.value.push({
      nextLinkId: response.data.nextLinks[i].nextLinkId,
      displayText: response.data.nextLinks[i].displayText,
    });
  }
  loading.value = false;
});
</script>
<style lang="scss">
.reader {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.readheader {
  width: 880px;
  padding: 20px 5px 20px 5px;
  margin: auto;
  font-size: 0.7rem;
}

.readerBox {
  width: 880px;
  padding: 20px 30px 20px 30px;
  margin: auto;
  background-color: white;
  min-height: 70%;
}
</style>
